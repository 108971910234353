import { FloristDetails } from '~/types/responseTypes';

export const REQUESTING_FLORIST_DETAILS_BY_STOREFRONT =
  'zola/florist/REQUESTING_FLORIST_DETAILS_BY_STOREFRONT';
export const RECEIVED_FLORIST_DETAILS = 'zola/florist/RECEIVED_FLORIST_DETAILS';

export const UPDATING_FLORIST_DETAILS = 'zola/florist/UPDATING_FLORIST_DETAILS';
export const UPDATED_FLORIST_DETAILS = 'zola/florist/UPDATED_FLORIST_DETAILS';
export interface RequestingFloristDetailsByStorefrontAction {
  type: typeof REQUESTING_FLORIST_DETAILS_BY_STOREFRONT;
}
export interface ReceivedFloristDetailsAction {
  type: typeof RECEIVED_FLORIST_DETAILS;
  payload: FloristDetails;
}
export interface UpdatingFloristDetailsAction {
  type: typeof UPDATING_FLORIST_DETAILS;
}
export interface UpdatedFloristDetailsAction {
  type: typeof UPDATED_FLORIST_DETAILS;
  payload: FloristDetails;
}

export type FloristActionType =
  | RequestingFloristDetailsByStorefrontAction
  | ReceivedFloristDetailsAction
  | UpdatingFloristDetailsAction
  | UpdatedFloristDetailsAction;

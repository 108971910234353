/* eslint-disable camelcase */
import { PayloadAction } from '@reduxjs/toolkit';

export const IMAGE_UPLOADING = 'zola/images/IMAGE_UPLOADING';
export const IMAGE_UPLOADED = 'zola/images/IMAGE_UPLOADED';
export const IMAGE_MOVED = 'zola/images/IMAGE_MOVED';
export const IMAGE_TOTAL_CHANGED = 'zola/images/TOTAL_CHANGED';
export const IMAGE_COMPLETED_CHANGED = 'zola/images/IMAGE_COMPLETED_CHANGED';
export const IMAGE_ERRORED_CHANGED = 'zola/images/IMAGE_ERRORED_CHANGED';

export const TEMP_IMAGE_UPLOADING = 'zola/images/TEMP_IMAGE_UPLOADING';
export const TEMP_IMAGE_UPLOADED = 'zola/images/TEMP_IMAGE_UPLOADED';
export const TEMP_IMAGE_TOTAL_CHANGED = 'zola/images/TEMP_TOTAL_CHANGED';
export const TEMP_IMAGE_COMPLETED_CHANGED = 'zola/images/TEMP_IMAGE_COMPLETED_CHANGED';
export const TEMP_IMAGE_ERRORED_CHANGED = 'zola/images/TEMP_IMAGE_ERRORED_CHANGED';

// Candidate to move to response types, this comes back from the image api
export type UploadedImage = {
  content_type: string;
  s3_bucket: string;
  s3_key: string;
  url: string;
  width: number;
  height: number;
};

// Candidate to move to response types, this comes back from the image api
export type MovedImage = {
  uuid: string;
  url: string;
  content_type: string;
  width: number;
  height: number;
  cloudfront_url: string;
  uploadcare_url: string | null;
  uploadcare_original_url: string | null;
  original_datetime: unknown;
  original_filename: string | null;
};

// Adds a busy: false true to any type
type Finished<T> = T & {
  busy: false;
};

// All the different types of images we can upload
export type ImageCategories =
  | 'cover_management'
  | 'bio_photo'
  | 'bio_form'
  | 'space_form'
  | 'video-gallery'
  | 'showcases'
  | 'portfolio_management'
  | 'video_gallery'
  | 'spaces';

export type ImageUploadRequested = {
  busy: true;
};

export type MoveImageError = {
  error: string;
};

export type ImageUploaded = Finished<UploadedImage> | Finished<{ url: null }>;

export type ImageMoved<T = MovedImage> = Finished<T> | Finished<MoveImageError>;

export type ImageUploadActionPayload = {
  [sectionKey in ImageCategories]?: {
    [index: number]: ImageUploadRequested;
  };
};

export type ImageUploadingAction = PayloadAction<
  ImageUploadActionPayload,
  typeof TEMP_IMAGE_UPLOADING | typeof IMAGE_UPLOADING
>;

export type ImageUploadedActionPayload<T = ImageUploaded> = {
  [sectionKey in ImageCategories]?: {
    [index: number]: T;
  };
};

export type ImageUploadedAction = PayloadAction<
  ImageUploadedActionPayload,
  typeof TEMP_IMAGE_UPLOADED | typeof IMAGE_UPLOADED
>;

type ImageMovedActionPayload = {
  [sectionKey in ImageCategories]?: {
    [index: number]: ImageMoved;
  };
};
export type ImageMovedAction = PayloadAction<ImageMovedActionPayload, typeof IMAGE_MOVED>;

type ImageTotalChangedActionPayload = {
  [sectionKey in ImageCategories]?: {
    totalPhotos: number;
    completedCount: 0;
    erroredCount: 0;
  };
};

export type ImageTotalChangedAction = PayloadAction<
  ImageTotalChangedActionPayload,
  typeof TEMP_IMAGE_TOTAL_CHANGED | typeof IMAGE_TOTAL_CHANGED
>;

export type ImageCompletedAction = PayloadAction<
  ImageCategories,
  typeof TEMP_IMAGE_COMPLETED_CHANGED | typeof IMAGE_COMPLETED_CHANGED
>;

export type ImageErroredAction = PayloadAction<
  ImageCategories,
  typeof TEMP_IMAGE_ERRORED_CHANGED | typeof IMAGE_ERRORED_CHANGED
>;

export type ImageUploadActions =
  | ImageUploadingAction
  | ImageUploadedAction
  | ImageMovedAction
  | ImageTotalChangedAction
  | ImageCompletedAction
  | ImageErroredAction;

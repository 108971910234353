import { AddressView } from '@zola/svc-marketplace-ts-types';

import { PreferredVendorSummaryView, VendorCardView } from '~/types/responseTypes';

import {
  VENDOR_RECEIVED_PREFERRED_VENDORS,
  VENDOR_DELETED_PREFERRED_VENDOR,
  COUPLE_RECEIVED_PREFERRED_VENDORS,
  StorefrontPreferredVendorsActions,
} from '../actions/types/storefrontPreferredVendorActionTypes';

export interface StorefrontPreferredVendorEntry extends VendorCardView {
  preferredVendorId: number;
  required: boolean;
  inHouse: boolean;
  address: AddressView;
}

export interface StorefrontPreferredVendorsState {
  byId: Record<number, StorefrontPreferredVendorEntry>;
  allIds: number[];
}
export const initialState: StorefrontPreferredVendorsState = {
  // Look up a photographer by id
  byId: {},

  // all the IDs of all the photographers we've loaded
  allIds: [],
};

function mapVendorDataToStore(
  response: PreferredVendorSummaryView
): StorefrontPreferredVendorsState {
  const vendorData: StorefrontPreferredVendorsState = {
    // Look up a photographer by id
    byId: {},

    // all the IDs of all the photographers we've loaded
    allIds: [],
  };

  const vendors = [...response.requiredVendors, ...response.optionalVendors];

  vendors.forEach((vendor) => {
    const { preferredVendorId, vendorCard, required, inHouse, address } = vendor;

    vendorData.byId[preferredVendorId] = {
      ...vendorCard,
      required,
      inHouse,
      preferredVendorId,
      address: address as AddressView,
    };
    vendorData.allIds.push(preferredVendorId);
  });

  return vendorData;
}

const storefrontPreferredVendorsReducer = (
  state = initialState,
  action?: StorefrontPreferredVendorsActions
): StorefrontPreferredVendorsState => {
  switch (action?.type) {
    case COUPLE_RECEIVED_PREFERRED_VENDORS:
    case VENDOR_RECEIVED_PREFERRED_VENDORS: {
      const newVendors = mapVendorDataToStore(action.payload);
      return { ...state, ...newVendors };
    }
    case VENDOR_DELETED_PREFERRED_VENDOR: {
      const newVendors = { ...state };
      delete newVendors.byId[action.payload.id];
      newVendors.allIds = newVendors.allIds.filter((id) => id !== action.payload.id);
      return { ...state, ...newVendors };
    }
    default:
      return state;
  }
};

export default storefrontPreferredVendorsReducer;

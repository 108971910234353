import _get from 'lodash/get';

import {
  SAVE_TEMP_PHOTO_CREDITS,
  DELETE_TEMP_PHOTO_CREDITS,
  PhotoCreditsActionTypes,
} from '~/actions/ui/types/tempPhotoCreditsActionTypes';
import { PhotoCreditFormValues } from '~/pages/vendors/Storefront/components/PhotoTags/types';
import type { RootState } from '~/reducers';

interface TempPhotoCreditsState {
  tempPhotoCredits?: PhotoCreditFormValues;
}

export const initialState: TempPhotoCreditsState = {};
/**
 * This slice of redux stores photo credits that a user has entered
 * before they save the batch, to repopulate the form if they close
 * out of the modal accidentally
 */
const tempPhotoCreditsReducer = (
  state: TempPhotoCreditsState = initialState,
  action: PhotoCreditsActionTypes
): TempPhotoCreditsState => {
  switch (action.type) {
    case SAVE_TEMP_PHOTO_CREDITS: {
      return { ...state.tempPhotoCredits, ...action.payload };
    }
    case DELETE_TEMP_PHOTO_CREDITS: {
      return {};
    }

    default:
      return state;
  }
};

export const getTempPhotoCredits = (state: RootState): TempPhotoCreditsState =>
  _get(state, 'ui.tempPhotoCredits');

export default tempPhotoCreditsReducer;

import { PayloadAction } from '@reduxjs/toolkit';

import { BoardSourceEnum, MappedBoardView } from '~/types/responseTypes';
import { TrackingProductLocation } from '~/util/trackingHelper';

export const RECEIVED_FAVORITES = 'zola/favorites/RECEIVED_FAVORITES';
export const CREATING_FAVORITE_PHOTO = 'zola/favorites/CREATING_FAVORITE_PHOTO';
export const CREATED_FAVORITE_PHOTO = 'zola/favorites/CREATED_FAVORITE_PHOTO';
export const DELETING_FAVORITE_PHOTO = 'zola/favorites/DELETING_FAVORITE_PHOTO';
export const DELETED_FAVORITE_PHOTO = 'zola/favorites/DELETED_FAVORITE_PHOTO';
export const CREATING_FAVORITE_STOREFRONT = 'zola/favorites/CREATING_FAVORITE_STOREFRONT';
export const CREATED_FAVORITE_STOREFRONT = 'zola/favorites/CREATED_FAVORITE_STOREFRONT';
export const DELETING_FAVORITE_STOREFRONT = 'zola/favorites/DELETING_FAVORITE_STOREFRONT';
export const DELETED_FAVORITE_STOREFRONT = 'zola/favorites/DELETED_FAVORITE_STOREFRONT';

export type ReceivedFavoritesAction = PayloadAction<MappedBoardView, typeof RECEIVED_FAVORITES>;

interface TrackingProperties {
  tilePosition?: number;
  location?: TrackingProductLocation;
  shelfCustomized?: boolean;
}

/** This is the minimum amount of information we need to know if a photo is favorited or not */
export interface FavoritePhotoPayload {
  imageId: string;
}
// I used a type instead of an interface because we should never
export interface FavoritePhotoRequest extends FavoritePhotoPayload {
  boardUuid: string;
  source?: BoardSourceEnum; // defaults to WEB,
  location?: TrackingProductLocation;
  tilePosition?: number;
  shelfCustomized?: boolean;
}

export interface FavoriteStorefrontRequest extends TrackingProperties {
  boardUuid: string;
  storefrontId?: number;
  source?: BoardSourceEnum;
  storefrontUuid: string;
}

export interface UnfavoritePhotoRequest extends TrackingProperties {
  uuid: string;
  imageId: string;
}

export interface UnfavoriteStorefrontRequest extends TrackingProperties {
  uuid: string;
  storefrontUuid: string;
}

export type CreatingFavoritePhotoAction = PayloadAction<
  FavoritePhotoRequest,
  typeof CREATING_FAVORITE_PHOTO
>;
export type CreatedFavoritePhotoAction = PayloadAction<
  FavoritePhotoPayload,
  typeof CREATED_FAVORITE_PHOTO
>;

export type DeletingFavoritePhotoAction = PayloadAction<string, typeof DELETING_FAVORITE_PHOTO>;

export type DeletedFavoritePhotoAction = PayloadAction<
  FavoritePhotoPayload,
  typeof DELETED_FAVORITE_PHOTO
>;

/** The minimum amount of information needed to know if a storefront is favorited or not */
export interface FavoriteStorefrontPayload {
  storefrontUuid: string;
}
export type CreatingFavoriteStorefrontAction = PayloadAction<
  FavoriteStorefrontPayload,
  typeof CREATING_FAVORITE_STOREFRONT
>;
export type CreatedFavoriteStorefrontAction = PayloadAction<
  FavoriteStorefrontPayload,
  typeof CREATED_FAVORITE_STOREFRONT
>;

export type DeletingFavoriteStorefrontAction = PayloadAction<
  string,
  typeof DELETING_FAVORITE_STOREFRONT
>;
export type DeletedFavoriteStorefrontAction = PayloadAction<
  FavoriteStorefrontPayload,
  typeof DELETED_FAVORITE_STOREFRONT
>;

export type FavoritePhotoActionType =
  | ReceivedFavoritesAction
  | CreatingFavoritePhotoAction
  | CreatedFavoritePhotoAction
  | DeletingFavoritePhotoAction
  | DeletedFavoritePhotoAction;

export type FavoriteStorefrontActionType =
  | ReceivedFavoritesAction
  | CreatingFavoriteStorefrontAction
  | CreatedFavoriteStorefrontAction
  | DeletingFavoriteStorefrontAction
  | DeletedFavoriteStorefrontAction;

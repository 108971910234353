import { ManageWeddingCardView } from '@zola/svc-marketplace-ts-types';

import {
  RECEIVED_STOREFRONT_WEDDINGS,
  RECEIVED_STOREFRONT_WEDDINGS_TAGGED_IN,
  DELETED_STOREFRONT_WEDDING,
  ACCEPT_TERMS_BY_WEDDING_UUID,
  VendorWeddingActionTypes,
} from '~/actions/vendors/types/vendorWeddingActionTypes';

export type VendorWeddingsState = {
  ['PENDING']: ManageWeddingCardView[];
  ['SUBMITTED']: ManageWeddingCardView[];
  ['PUBLISHED']: ManageWeddingCardView[];
  TAGGED: ManageWeddingCardView[];
  loaded: boolean;
  termsAcceptedByWeddingUuid: Record<string, boolean>;
};

export const initialState: VendorWeddingsState = {
  ['PENDING']: [],
  ['SUBMITTED']: [],
  ['PUBLISHED']: [],
  TAGGED: [] as ManageWeddingCardView[],
  loaded: false,
  termsAcceptedByWeddingUuid: {},
};

const vendorWeddingsReducer = (
  state = initialState,
  action: VendorWeddingActionTypes
): VendorWeddingsState => {
  switch (action.type) {
    case RECEIVED_STOREFRONT_WEDDINGS: {
      return { ...state, ...state, ...action.payload, loaded: true };
    }
    case RECEIVED_STOREFRONT_WEDDINGS_TAGGED_IN: {
      return { ...state, TAGGED: action.payload };
    }
    case DELETED_STOREFRONT_WEDDING: {
      return {
        ...state,
        ['PENDING']: state.PENDING.filter(
          (wedding: ManageWeddingCardView) => wedding.uuid !== action.payload.uuid
        ),
      };
    }
    case ACCEPT_TERMS_BY_WEDDING_UUID: {
      const { weddingUuid } = action.payload;
      return {
        ...state,
        termsAcceptedByWeddingUuid: {
          [weddingUuid]: true,
        },
      };
    }
    default:
      return state;
  }
};

export default vendorWeddingsReducer;

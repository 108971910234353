import getEnvironment from '@zola-helpers/client/dist/es/util/environment';

export interface Config {
  imageServiceBaseUrl: string;
  iovationJsHost: string;
  stripePubKey: string;
}

const productionConfig: Config = {
  imageServiceBaseUrl: 'https://images.zola.com',
  iovationJsHost: 'https://www.zola.com',
  stripePubKey: 'pk_live_dBZkow17g2crlYlMLxfafwYt',
};

const stagingConfig: Config = {
  imageServiceBaseUrl: 'https://stage-images.zola.com',
  iovationJsHost: 'https://qa.zola.com',
  stripePubKey: 'pk_test_kLypsQNKVwzP5Jshbh2vMFCw',
};

const developmentConfig: Config = {
  imageServiceBaseUrl: '//localhost:9100/v2/image',
  iovationJsHost: 'http://localhost',
  stripePubKey: 'pk_test_kLypsQNKVwzP5Jshbh2vMFCw',
};

const getConfig = (): Config => {
  switch (getEnvironment()) {
    case 'production':
      return productionConfig;
    case 'staging':
      return stagingConfig;
    default:
      return process.env.NEXT_PUBLIC_ENV === 'devstaging' ? stagingConfig : developmentConfig;
  }
};

export default getConfig;

import { StorefrontVideoGalleryView } from '@zola/svc-marketplace-ts-types';

import { AnyAction } from '@reduxjs/toolkit';

import {
  REQUESTING_STOREFRONT_VIDEO_GALLERY,
  RECEIVED_STOREFRONT_VIDEO_GALLERY,
  REPLACED_STOREFRONT_VIDEO_GALLERY,
  EDITING_VIDEO,
  CANCEL_EDIT,
  REMOVED_VIDEO,
  CREATED_STOREFRONT_VIDEO,
  SWAP_VIDEO_ORDER,
} from '~/actions/vendors/types/vendorStorefrontVideoActionTypes';
import type { RootState } from '~/reducers';

export interface VideoGalleryState {
  gallery: StorefrontVideoGalleryView[];
  loaded: boolean;
  loading: boolean;
  editing: boolean | null;
  pristine: boolean | null;
  editIndex: number | null;
}

const initialState: VideoGalleryState = {
  gallery: [],
  loaded: false,
  loading: false,
  editing: null,
  pristine: null,
  editIndex: null,
};

const videoGalleryReducer = (state = initialState, action: AnyAction): VideoGalleryState => {
  switch (action.type) {
    case REQUESTING_STOREFRONT_VIDEO_GALLERY: {
      return { ...state, ...initialState, loading: true };
    }
    case REPLACED_STOREFRONT_VIDEO_GALLERY:
    case RECEIVED_STOREFRONT_VIDEO_GALLERY: {
      const gallery = action.payload;
      return {
        ...state,
        gallery,
        loaded: true,
        loading: false,
        editing: false,
        pristine: true,
        editIndex: null,
      };
    }
    case EDITING_VIDEO: {
      const index = action.payload;
      return { ...state, editing: true, editIndex: index };
    }
    case CANCEL_EDIT: {
      return { ...state, editing: false, editIndex: null };
    }
    case REMOVED_VIDEO: {
      const index = action.payload;
      let newGallery: (StorefrontVideoGalleryView | null)[] = [...state.gallery];
      newGallery[index] = null;
      newGallery = newGallery.filter((entry) => !!entry);
      return {
        ...state,
        pristine: false,
        gallery: newGallery as StorefrontVideoGalleryView[],
        editing: false,
        editIndex: null,
      };
    }
    case CREATED_STOREFRONT_VIDEO: {
      const { video, index } = action.payload;
      const newGallery = [...state.gallery];
      newGallery[index] = video;

      return { ...state, gallery: newGallery, pristine: false, editing: false, editIndex: null };
    }
    case SWAP_VIDEO_ORDER: {
      const { fromIndex, toIndex } = action.payload;
      const newGallery = [...state.gallery];
      const tmp = newGallery[fromIndex];
      newGallery[fromIndex] = newGallery[toIndex];
      newGallery[toIndex] = tmp;

      return { ...state, gallery: newGallery, pristine: false };
    }
    default:
      return state;
  }
};

export default videoGalleryReducer;

const getVideoGalleryState = (state: RootState) => state.entities.videoGallery;

export const isLoading = (state: RootState) => getVideoGalleryState(state).loading;
export const isEditing = (state: RootState) => getVideoGalleryState(state).editing;
export const isPristine = (state: RootState) => getVideoGalleryState(state).pristine;
export const getEditIndex = (state: RootState) => getVideoGalleryState(state).editIndex;
export const getVideoGallery = (state: RootState) => getVideoGalleryState(state).gallery;
export const isLoaded = (state: RootState) => getVideoGalleryState(state).loaded;

export const galleryState = {
  isLoading,
  isEditing,
  isPristine,
  getEditIndex,
  getVideoGallery,
};

import { MusicianDetails } from '~/types/responseTypes';
import { StorefrontEntityState, setStorefrontEntityObject } from '~/util/reducerUtils';

import {
  MusicianActionType,
  RECEIVED_MUSICIAN_DETAILS,
  UPDATED_MUSICIAN_DETAILS,
} from '../../actions/types/musicianActionTypes';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<MusicianDetails> = {
  // Look up a musician by id
  byId: {},

  // all the IDs of all the musicians we've loaded
  allIds: [],

  // Map storefront ids to musician ids
  storefrontToId: {},
};

const musicianReducer = (
  state = initialState,
  action: MusicianActionType
): StorefrontEntityState<MusicianDetails> => {
  switch (action.type) {
    case RECEIVED_MUSICIAN_DETAILS:
    case UPDATED_MUSICIAN_DETAILS: {
      return setStorefrontEntityObject(state, action.payload);
    }
    default:
      return state;
  }
};

export default musicianReducer;

import { PayloadAction } from '@reduxjs/toolkit';

import { WeddingColorCategoryType } from '~/types/weddings.d';

export const REQUEST_WEDDING_COLOR_CATEGORIES = 'zola/weddings/v2/REQUEST_WEDDING_COLOR_CATEGORIES';
export const RECEIVED_WEDDING_COLOR_CATEGORIES =
  'zola/weddings/v2/RECEIVED_WEDDING_COLOR_CATEGORIES';

export type RequestWeddingColorCategoriesAction = PayloadAction<
  Record<string, never>,
  typeof REQUEST_WEDDING_COLOR_CATEGORIES
>;
export type ReceivedWeddingColorCategoriesAction = PayloadAction<
  WeddingColorCategoryType[],
  typeof RECEIVED_WEDDING_COLOR_CATEGORIES
>;

export type WeddingColorCategoryActions =
  | RequestWeddingColorCategoriesAction
  | ReceivedWeddingColorCategoriesAction;

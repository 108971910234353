import { createReducer } from '@reduxjs/toolkit';
import _omit from 'lodash/omit';

import {
  InquiryWithActivityData,
  ReceivedInquiries,
  RECEIVED_INQUIRIES,
} from '~/actions/couples/types/dashboardActionTypes';
import type { RootState } from '~/reducers';

import {
  receivedDraft,
  deletedDraft,
  receivedLastInquiryToStorefront,
  markedDraftForDeletion,
  receivedBulkInquiryToStorefront,
} from '../actions/types/couplesInquiriesActionTypes';

export interface CouplesInquiriesState {
  lastInquiryToStorefront: {
    [storefrontUuid: string]: null | {
      uuid: string;
    };
  };
  draftsByStorefrontUuid: Record<string, string>;
  draftUuidToDelete: string | null;
}
export const initialState: CouplesInquiriesState = {
  lastInquiryToStorefront: {},
  draftsByStorefrontUuid: {},
  draftUuidToDelete: null,
};

const couplesInquiriesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase<typeof RECEIVED_INQUIRIES, ReceivedInquiries>(
      RECEIVED_INQUIRIES,
      (state, action: ReceivedInquiries) => {
        const inquiries: InquiryWithActivityData[] = action.payload;

        state.lastInquiryToStorefront = inquiries.reduce((result, inquiry) => {
          return {
            ...result,
            [inquiry.storefrontUuid]: inquiry,
          };
        }, state.lastInquiryToStorefront);
      }
    )
    .addCase(receivedLastInquiryToStorefront, (state, action) => {
      state.lastInquiryToStorefront = {
        ...state.lastInquiryToStorefront,
        [action.payload.storefrontUuid]: action.payload,
      };
    })
    .addCase(receivedDraft, (state, action) => {
      state.draftsByStorefrontUuid[action.payload.storefrontUuid] = action.payload.draftUuid;
      state.draftUuidToDelete = null;
    })
    .addCase(deletedDraft, (state, action) => {
      state.draftsByStorefrontUuid = _omit(state.draftsByStorefrontUuid, [
        action.payload.storefrontUuid,
      ]);
      state.draftUuidToDelete = null;
    })
    .addCase(markedDraftForDeletion, (state, action) => {
      state.draftUuidToDelete = action.payload;
    })
    .addCase(receivedBulkInquiryToStorefront, (state, action) => {
      state.lastInquiryToStorefront = Object.keys(action.payload).reduce(
        (result, storefrontUuid) => {
          return {
            ...result,
            [storefrontUuid]: {
              uuid: action.payload[storefrontUuid],
            },
          };
        },
        state.lastInquiryToStorefront
      );
    })
);

export const getDraftUuidByStorefrontUuid = (storefrontUuid: string) => (state: RootState) =>
  state.couplesInquiries?.draftsByStorefrontUuid[storefrontUuid];

export default couplesInquiriesReducer;

import { PhotographerDetails } from '~/types/responseTypes';

export const REQUESTING_PHOTOGRAPHER_DETAILS_BY_STOREFRONT =
  'zola/photographer/REQUESTING_PHOTOGRAPHER_DETAILS_BY_STOREFRONT';
export const RECEIVED_PHOTOGRAPHER_DETAILS = 'zola/photographer/RECEIVED_PHOTOGRAPHER_DETAILS';

export const UPDATING_PHOTOGRAPHER_DETAILS = 'zola/photographer/UPDATING_PHOTOGRAPHER_DETAILS';
export const UPDATED_PHOTOGRAPHER_DETAILS = 'zola/photographer/UPDATED_PHOTOGRAPHER_DETAILS';

export interface RequestingPhotographerDetailsByStorefrontAction {
  type: typeof REQUESTING_PHOTOGRAPHER_DETAILS_BY_STOREFRONT;
}
export interface ReceivedPhotographerDetailsAction {
  type: typeof RECEIVED_PHOTOGRAPHER_DETAILS;
  payload: PhotographerDetails;
}
export interface UpdatingPhotographerDetailsAction {
  type: typeof UPDATING_PHOTOGRAPHER_DETAILS;
}
export interface UpdatedPhotographerDetailsAction {
  type: typeof UPDATED_PHOTOGRAPHER_DETAILS;
  payload: PhotographerDetails;
}

export type PhotographerActionType =
  | RequestingPhotographerDetailsByStorefrontAction
  | ReceivedPhotographerDetailsAction
  | UpdatingPhotographerDetailsAction
  | UpdatedPhotographerDetailsAction;

import { Action } from 'redux';

import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import {
  missingStorefront,
  requestStorefront,
  receiveStorefront,
  workInProgressStorefront,
} from '../actions/types/storefrontActionTypes';

export type StorefrontState = {
  storefrontDetails: null | CouplesStorefrontDetails;
  busy: boolean;
  storefrontNotFound: boolean;
  storefrontWorkInProgress: boolean;
};

const initialState: StorefrontState = {
  storefrontDetails: null,
  busy: false,
  storefrontNotFound: false,
  storefrontWorkInProgress: false,
};

const storefrontReducer = (state = initialState, action?: Action): StorefrontState => {
  if (!action) {
    return state;
  }

  if (requestStorefront.match(action)) {
    return {
      ...state,
      storefrontDetails: null,
      busy: true,
      storefrontNotFound: false,
      storefrontWorkInProgress: false,
    };
  }

  if (receiveStorefront.match(action)) {
    return {
      ...state,
      storefrontDetails: action.payload,
      busy: false,
      storefrontNotFound: false,
      storefrontWorkInProgress: false,
    };
  }

  if (missingStorefront.match(action)) {
    return { ...state, storefrontDetails: null, busy: false, storefrontNotFound: true };
  }

  if (workInProgressStorefront.match(action)) {
    return { ...state, storefrontDetails: null, busy: false, storefrontWorkInProgress: true };
  }

  return state;
};

export default storefrontReducer;

import { FavoriteStorefrontSearchView } from '@zola/svc-marketplace-ts-types';

import { createReducer } from '@reduxjs/toolkit';

import { receivedSavedSearches } from '~/actions/couples/types/savedSearchActionTypes';
import type { RootState } from '~/reducers';

export interface SavedSearchesState {
  currentSavedSearches: FavoriteStorefrontSearchView[] | null;
}
export const initialState: SavedSearchesState = {
  currentSavedSearches: null,
};

const SavedSearchReducer = createReducer(initialState, (builder) =>
  builder.addCase(receivedSavedSearches, (state, action) => {
    state.currentSavedSearches = action.payload;
  })
);

export const getSavedSearches = (state: RootState) =>
  state.couples?.savedSearches?.currentSavedSearches || null;

export default SavedSearchReducer;

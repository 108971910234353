import { ImageUploadRequested, ImageCategories } from '~/actions/types/imageUploadActionTypes';
import { MappedGalleryPhotoView } from '~/types/responseTypes';

import {
  STOREFRONT_PHOTO_CREATING,
  STOREFRONT_PHOTO_CREATED,
  STOREFRONT_PHOTOS_CREATING,
  STOREFRONT_PHOTOS_CREATED,
  StorefrontPhotoCreatingAction,
  StorefrontPhotoCreatedAction,
  StorefrontPhotosCreatedAction,
  StorefrontPhotosCreatingAction,
} from '../actions/vendors/types/vendorStorefrontPhotoActionTypes';
import { updateState } from '../util/imageState';

interface VendorStorefrontImageReducerSlice {
  [index: number]: ImageUploadRequested | MappedGalleryPhotoView;
}

export type VendorStorefrontImageState = {
  [key in ImageCategories]?: VendorStorefrontImageReducerSlice; // This doesn't really accurately capture that only one key is ever used at a time.
};

type VendorStorefrontImageActions =
  | StorefrontPhotoCreatingAction
  | StorefrontPhotoCreatedAction
  | StorefrontPhotosCreatingAction
  | StorefrontPhotosCreatedAction;

const initialState: VendorStorefrontImageReducerSlice = {};

const vendorStorefrontImagesReducer = (
  state = initialState,
  action?: VendorStorefrontImageActions
): VendorStorefrontImageReducerSlice => {
  switch (action?.type) {
    case STOREFRONT_PHOTO_CREATING: {
      return updateState(state, action.payload);
    }
    case STOREFRONT_PHOTO_CREATED: {
      const { payload } = action;

      return updateState(state, payload);
    }
    case STOREFRONT_PHOTOS_CREATING: {
      return updateState(state, action.payload);
    }
    case STOREFRONT_PHOTOS_CREATED: {
      return updateState(state, action.payload);
    }

    default:
      return state;
  }
};

export default vendorStorefrontImagesReducer;

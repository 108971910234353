import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import {
  CLEAR_VENDOR_ONBOARDING_LOCK,
  RECEIVE_AUTOSUGGEST,
  RECEIVE_VENDOR_FACETS_FOR_TAXONOMY,
  RECEIVE_VENDOR_QUESTIONS,
  RESET_MARKET_VERIFICATION,
  RESET_WORKSPACE,
  SENT_MARKET_VERIFICATION,
  SENT_ONBOARD_ANSWERS,
  SET_VENDOR_ONBOARDING_LOCK,
  UPDATE_PROGRESS_COUNTER,
  UPDATE_STEP_KEY,
  UPDATE_VENDOR_TYPE,
  MarketVerificationResponse,
  VendorOnboardingActionType,
  VendorOnboardingQuestionnaire,
  RECEIVED_FACETS_FOR_VENDOR_TYPE,
} from '~/actions/vendors/types/vendorOnboardActionTypes';
import { OnboardType } from '~/pages/vendors/OnboardV2/meta/types';
import {
  MappedOptionFacetView,
  RawOptionFacetView,
  RawReferenceVendorView,
} from '~/types/responseTypes';

const flow: VendorOnboardingQuestionnaire = {
  version: null,
  businessUnit: null,
  component: null,
  name: null,
  type: null,
  initialKey: null,
  steps: [],
};

export interface VendorOnboardingState {
  flow: VendorOnboardingQuestionnaire;
  /** Results of an auto-suggest search for vendors */
  businessNames: RawReferenceVendorView[];
  /** @deprecated */
  businessUnit: null;

  isSubmitting: boolean;

  matchingMarkets: MarketVerificationResponse | null;

  stepKey: string;

  submitted: boolean;

  facetsForTaxonomy: Record<string, RawOptionFacetView[]>;

  facets: Record<string, MappedOptionFacetView[]>;

  progressNumber: number;
  /** @deprecated please try to use a taxonomyKey instead */
  vendorType: OnboardType | null;
  taxonomyKey: VendorTaxonomyKey | undefined;
}

const initialState: VendorOnboardingState = {
  flow,
  businessNames: [],
  businessUnit: null,
  isSubmitting: false,
  matchingMarkets: [],
  stepKey: '',
  submitted: false,
  facetsForTaxonomy: {}, // v1 onboarding
  facets: {}, // v2 onboarding
  progressNumber: 0,
  /** @deprecated please try to use a taxonomyKey instead */
  vendorType: null,
  taxonomyKey: undefined,
};

const vendorOnboardingReducer = (
  state: VendorOnboardingState = initialState,
  action: VendorOnboardingActionType
): VendorOnboardingState => {
  switch (action.type) {
    case RECEIVE_VENDOR_QUESTIONS: {
      return {
        ...state,
        flow: action.payload,
      };
    }
    case RECEIVE_VENDOR_FACETS_FOR_TAXONOMY: {
      return {
        ...state,
        facetsForTaxonomy: action.payload,
      };
    }
    case RECEIVE_AUTOSUGGEST: {
      return {
        ...state,
        businessNames: action.payload,
      };
    }
    case SENT_ONBOARD_ANSWERS: {
      return {
        ...state,
        submitted: true,
      };
    }
    case SENT_MARKET_VERIFICATION: {
      return {
        ...state,
        matchingMarkets: action.payload,
      };
    }
    case RESET_MARKET_VERIFICATION: {
      return {
        ...state,
        matchingMarkets: null,
      };
    }
    case UPDATE_STEP_KEY: {
      return {
        ...state,
        stepKey: action.payload,
      };
    }
    case UPDATE_PROGRESS_COUNTER: {
      return {
        ...state,
        progressNumber: action.payload,
      };
    }
    case UPDATE_VENDOR_TYPE: {
      return {
        ...state,
        /** @deprecated please try to use a taxonomyKey instead */
        vendorType: action.payload,
        taxonomyKey: getVendorTaxonomyKey(action.payload),
      };
    }
    case CLEAR_VENDOR_ONBOARDING_LOCK: {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    case SET_VENDOR_ONBOARDING_LOCK: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case RESET_WORKSPACE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case RECEIVED_FACETS_FOR_VENDOR_TYPE: {
      const { payload } = action;
      const facets: { [x: string]: MappedOptionFacetView[] } = {};
      const parents: MappedOptionFacetView[] = payload.filter((item) => item.id === item.parentId);

      parents.forEach((parent) => {
        facets[parent.key] = payload.filter((item) => parent.id === item.parentId);
      });
      return { ...state, facets };
    }
    default:
      return state;
  }
};

export default vendorOnboardingReducer;

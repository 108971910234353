import { BakerDetails } from '~/types/responseTypes';

export const REQUESTING_BAKER_DETAILS_BY_STOREFRONT =
  'zola/baker/REQUESTING_BAKER_DETAILS_BY_STOREFRONT';
export const RECEIVED_BAKER_DETAILS = 'zola/baker/RECEIVED_BAKER_DETAILS';

export const UPDATING_BAKER_DETAILS = 'zola/baker/UPDATING_BAKER_DETAILS';
export const UPDATED_BAKER_DETAILS = 'zola/baker/UPDATED_BAKER_DETAILS';

export interface RequestingBakerDetailsByStorefrontAction {
  type: typeof REQUESTING_BAKER_DETAILS_BY_STOREFRONT;
}
export interface ReceivedBakerDetailsAction {
  type: typeof RECEIVED_BAKER_DETAILS;
  payload: BakerDetails;
}
export interface UpdatingBakerDetailsAction {
  type: typeof UPDATING_BAKER_DETAILS;
}
export interface UpdatedBakerDetailsAction {
  type: typeof UPDATED_BAKER_DETAILS;
  payload: BakerDetails;
}

export type BakerActionType =
  | RequestingBakerDetailsByStorefrontAction
  | ReceivedBakerDetailsAction
  | UpdatingBakerDetailsAction
  | UpdatedBakerDetailsAction;

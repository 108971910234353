import { AccountPreferenceView } from '@zola/svc-marketplace-ts-types';

import { AnyAction } from '@reduxjs/toolkit';

import {
  receivedAccountPreferences,
  setAccountPreference,
} from '~/actions/couples/types/accountPreferencesActionTypes';

export type AccountPreferenceState = {
  enabled: boolean;
  rePromptEligible: boolean;
  madeSelection: boolean;
};

export type AccountPreferencesState = Record<
  AccountPreferenceView.KeyEnum,
  AccountPreferenceState
> & {
  loaded: boolean;
};

export const initialState: AccountPreferencesState = {
  FIRST_MOVES_SHARE_INQUIRIES: { enabled: false, rePromptEligible: true, madeSelection: false },
  FIRST_MOVES_SHARE_FAVORITES: { enabled: false, rePromptEligible: true, madeSelection: false },
  FIRST_MOVES_SHARE_WEDDING_DETAILS: {
    enabled: false,
    rePromptEligible: true,
    madeSelection: false,
  },
  loaded: false,
};

const accountPreferencesReducer = (
  state = initialState,
  action: AnyAction
): AccountPreferencesState => {
  if (receivedAccountPreferences.match(action)) {
    return {
      ...state,
      ...action.payload,
      loaded: true,
    };
  }
  if (setAccountPreference.match(action)) {
    const {
      payload: { key, enabled },
    } = action;

    return {
      ...state,
      [key]: {
        enabled,
        rePromptEligible: false,
        madeSelection: true,
      },
    };
  }
  return state;
};

export default accountPreferencesReducer;

import { ModelPackage, Subscription } from '@zola/svc-marketplace-ts-types';

import { Action } from 'redux';

import {
  deletedRenewalPlan,
  receivedRenewalPlan,
  updatedRenewalPlan,
} from '~/actions/vendors/types/vendorCreditRenewalActionTypes';

export type RenewalPlanState = {
  storefrontUuid: string | null;
  recentPackage: ModelPackage | null;
  upcomingPackage: ModelPackage | null;
  subscription: Subscription | null;
  loaded: boolean;
};

export interface RenewalPlanByStorefrontState {
  byStorefrontUuid: Record<string, RenewalPlanState>;
}

export const initialState: RenewalPlanByStorefrontState = {
  byStorefrontUuid: {},
};

const creditRenewalReducer = (
  state: RenewalPlanByStorefrontState = initialState,
  action?: Action
): RenewalPlanByStorefrontState => {
  if (!action) {
    return state;
  }
  if (receivedRenewalPlan.match(action)) {
    const { payload } = action;
    const { storefrontUuid } = payload;
    return {
      byStorefrontUuid: {
        ...state.byStorefrontUuid,
        [storefrontUuid as string]: {
          ...payload,
          loaded: true,
        },
      },
    };
  }
  if (updatedRenewalPlan.match(action)) {
    const { payload } = action;
    const { payload: response, storefrontUuid } = payload;
    return {
      byStorefrontUuid: {
        ...state.byStorefrontUuid,
        [storefrontUuid]: {
          ...state.byStorefrontUuid[storefrontUuid],
          subscription: {
            ...state.byStorefrontUuid[storefrontUuid].subscription,
            paymentMethod: response,
            paymentError: false,
          },
          loaded: true,
        },
      } as Record<string, RenewalPlanState>,
    };
  }
  if (deletedRenewalPlan.match(action)) {
    const { payload } = action;
    const { storefrontUuid } = payload;
    return {
      byStorefrontUuid: {
        ...state.byStorefrontUuid,
        [storefrontUuid as string]: {
          ...payload,
          loaded: true,
        },
      },
    };
  }

  return state;
};

export default creditRenewalReducer;

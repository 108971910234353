import {
  ReceivedAccountSettingsActionPayload,
  RECEIVED_ACCOUNT_SETTINGS,
  UPDATING_ACCOUNT_SETTINGS,
  UPDATED_ACCOUNT_SETTINGS,
  VendorAccountSettingsAction,
} from '../actions/vendors/types/vendorAccountSettingsActionTypes';

interface EmptyVendorAccountSettingsState {
  isSaving: boolean;
  loaded: false;
}
export interface LoadedVendorAccountSettingsState extends ReceivedAccountSettingsActionPayload {
  isSaving: boolean;
  loaded: true;
}

export type VendorAccountSettingsState =
  | EmptyVendorAccountSettingsState
  | LoadedVendorAccountSettingsState;

const initialState: VendorAccountSettingsState = {
  isSaving: false,
  loaded: false,
};

const vendorAccountSettingsReducer = (
  state: VendorAccountSettingsState = initialState,
  action: VendorAccountSettingsAction
): VendorAccountSettingsState => {
  switch (action.type) {
    case RECEIVED_ACCOUNT_SETTINGS:
      return { ...state, ...action.payload, isSaving: false, loaded: true };
    case UPDATING_ACCOUNT_SETTINGS:
      return { ...state, isSaving: true };
    case UPDATED_ACCOUNT_SETTINGS:
      return { ...state, ...action.payload, isSaving: false, loaded: true };
    default:
      return state;
  }
};

export default vendorAccountSettingsReducer;

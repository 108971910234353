import { IUserContext } from '~/types/userContext';

export const RECEIVED_VENDOR_USER_CONTEXT = 'zola/vendor/RECEIVED_VENDOR_USER_CONTEXT';

export interface ReceivedVendorUserContext {
  type: typeof RECEIVED_VENDOR_USER_CONTEXT;
  payload: IUserContext;
}

export type VendorUserContextActionTypes = ReceivedVendorUserContext;

import { StorefrontPackageView } from '@zola/svc-marketplace-ts-types';

import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { Action } from 'redux';

import { EntitySlice } from '~/util/reducerUtils';

import {
  receivedPackage,
  receivedPackages,
  updatedPackages,
  updatedPackage,
  createdPackage,
  deletedPackage,
} from '../actions/types/packagesActionTypes';

export interface PackageState extends EntitySlice<StorefrontPackageView, number> {
  storefrontToId: Record<number, number[]>;
}

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
export const initialState: PackageState = {
  // Look up a package by id
  byId: {},

  // all the IDs of all the packages we've loaded
  allIds: [],

  // Map storefront ids to packages ids
  storefrontToId: {},
};

const packagesReducer = (state: PackageState = initialState, action?: Action): PackageState => {
  if (!action) {
    return state;
  }

  if (receivedPackages.match(action) || updatedPackages.match(action)) {
    const packages = action.payload;

    const packageIds = packages.filter((pckg) => pckg.id).map((pckg) => pckg.id);

    const packagesById = packages.reduce(
      (accumulator: Record<number, StorefrontPackageView>, pckg) => {
        if (pckg.id) {
          accumulator[pckg.id] = pckg;
        }
        return accumulator;
      },
      {}
    );

    const packagesByStorefrontId = packages.reduce(
      (accumulator: Record<number, number[]>, pckg) => {
        if (pckg.storefrontId && pckg.id) {
          accumulator[pckg.storefrontId] = _uniq([
            ...(state.storefrontToId[pckg.storefrontId] || []),
            ...(accumulator[pckg.storefrontId] || []),
            pckg.id,
          ]);
        }
        return accumulator;
      },
      {}
    );

    return {
      ...state,
      byId: { ...state.byId, ...packagesById },
      storefrontToId: {
        ...state.storefrontToId,
        ...packagesByStorefrontId,
      },
      allIds: _uniq(state.allIds.concat(packageIds)),
    };
  }

  if (
    receivedPackage.match(action) ||
    updatedPackage.match(action) ||
    createdPackage.match(action)
  ) {
    const { id, storefrontId } = action.payload;

    if (id && storefrontId) {
      const existingStorefrontIds = state.storefrontToId[storefrontId] || [];

      return {
        ...state,
        byId: { ...state.byId, [id]: action.payload },
        storefrontToId: {
          ...state.storefrontToId,
          [storefrontId]: _uniq([...existingStorefrontIds, id]),
        },
        allIds: _uniq(state.allIds.concat(id)),
      };
    }
  }
  if (deletedPackage.match(action)) {
    const { id, storefrontId } = action.payload;

    if (id && storefrontId) {
      return {
        ...state,
        byId: _omit(state.byId, id),
        storefrontToId: {
          [storefrontId]: state.storefrontToId[storefrontId].filter(
            (packageId) => packageId !== id
          ),
        },
        allIds: state.allIds.filter((allId) => allId !== id),
      };
    }
  }
  return state;
};

export default packagesReducer;

import type { StorefrontFileView } from '~/types/responseTypes';

export const REQUESTING_VENDOR_FILES = 'zola/vendor/files/REQUESTING_VENDOR_FILES';
export const RECEIVED_VENDOR_FILES = 'zola/vendor/files/RECEIVED_VENDOR_FILES';

export interface RequestVendorFilesAction {
  type: typeof REQUESTING_VENDOR_FILES;
}
export interface ReceivedVendorFilesAction {
  type: typeof RECEIVED_VENDOR_FILES;
  payload: StorefrontFileView[];
}

export const UPLOADING_VENDOR_FILE = 'zola/vendor/files/UPLOADING_VENDOR_FILE';
export const UPLOADED_VENDOR_FILE = 'zola/vendor/files/UPLOADED_VENDOR_FILE';

export interface UploadingVendorFileAction {
  type: typeof UPLOADING_VENDOR_FILE;
}
export interface UploadedVendorFileAction {
  type: typeof UPLOADED_VENDOR_FILE;
  payload: StorefrontFileView;
}

export const UPLOADING_BATCH_VENDOR_FILES = 'zola/vendor/files/UPLOADING_BATCH_VENDOR_FILES';
export const UPLOADED_BATCH_VENDOR_FILES = 'zola/vendor/files/UPLOADED_BATCH_VENDOR_FILES';

export interface UploadingBatchVendorFilesAction {
  type: typeof UPLOADING_BATCH_VENDOR_FILES;
}
export interface UploadedBatchVendorFilesAction {
  type: typeof UPLOADED_BATCH_VENDOR_FILES;
  payload: StorefrontFileView[];
}

export const DELETING_VENDOR_FILE = 'zola/vendor/files/DELETING_VENDOR_FILE';
export const DELETED_VENDOR_FILE = 'zola/vendor/files/DELETED_VENDOR_FILE';

export interface DeletingVendorFileAction {
  type: typeof DELETING_VENDOR_FILE;
}

export interface DeletedVendorFilesAction {
  type: typeof DELETED_VENDOR_FILE;
  payload: StorefrontFileView; // At least according to the swagger
}

export type VendorFileActionTypes =
  | RequestVendorFilesAction
  | ReceivedVendorFilesAction
  | UploadingVendorFileAction
  | UploadedVendorFileAction
  | UploadingBatchVendorFilesAction
  | UploadedBatchVendorFilesAction
  | DeletingVendorFileAction
  | DeletedVendorFilesAction;

import { createAction } from '@reduxjs/toolkit';

import type { UserSettingView } from '~/types/responseTypes';

const REQUESTING_USER_SETTINGS = 'zola/userSettings/REQUESTING_USER_SETTINGS';
export const RECEIVED_USER_SETTINGS = 'zola/userSettings/RECEIVED_USER_SETTINGS';
export const SET_USER_SETTINGS = 'zola/userSettings/SET_USER_SETTINGS';
const SETTING_USER_SETTINGS = 'zola/userSettings/SETTING_USER_SETTINGS';

export type UserSettingsObjectType = {
  [key: string]: UserSettingView;
};

export const requestingUserSettings = createAction(REQUESTING_USER_SETTINGS);

export const receivedUserSettings = createAction<UserSettingsObjectType>(RECEIVED_USER_SETTINGS);

export const settingUserSettings = createAction(SETTING_USER_SETTINGS);

export const setUserSettings = createAction<UserSettingsObjectType>(SET_USER_SETTINGS);

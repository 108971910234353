import {
  toastsActions,
  ADD_TOAST,
  REMOVE_TOAST_BY_ID,
  Toast,
  ToastTypes,
} from '../../actions/types/toastsActionTypes';

export interface ToastsState {
  byId: {
    [index: string]: {
      props: Toast;
      toastType: ToastTypes;
    };
  };
  allIds: string[];
}

const initialState: Readonly<ToastsState> = {
  byId: {},
  allIds: [],
};

const toastsReducer = (state: ToastsState = initialState, action?: toastsActions): ToastsState => {
  switch (action?.type) {
    case ADD_TOAST: {
      const { byId, allIds } = state;
      const id = action?.payload.props.id;
      if (allIds.includes(id)) {
        return { ...state };
      }
      if (id) {
        const newById = { ...byId };
        newById[id] = { toastType: action.payload.toastType, props: action.payload.props };
        const newAllIds = [...allIds, id];
        return { ...state, byId: newById, allIds: newAllIds };
      }
      throw Error('Toast requires an ID');
    }

    case REMOVE_TOAST_BY_ID: {
      const id = action?.payload;
      const { byId, allIds } = state;
      const newById = { ...byId };
      delete newById[id];
      const newAllIds = allIds.filter((x: string) => id !== x);
      return { ...state, byId, allIds: newAllIds };
    }
    default:
      return { ...state };
  }
};

export default toastsReducer;

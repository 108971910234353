import { RECEIVED_REGION_CODES, RegionCodeActionTypes } from '~/actions/types/addressActionTypes';
import type { RegionCodesResponse } from '~/types/types';

export interface AddressState extends RegionCodesResponse {
  loaded: boolean;
}
export const initialState: AddressState = {
  countries: [],
  usStates: [],
  caProvinces: [],
  loaded: false,
};

const addressReducer = (
  state: AddressState = initialState,
  action: RegionCodeActionTypes
): AddressState => {
  switch (action.type) {
    case RECEIVED_REGION_CODES: {
      const { payload } = action;
      return { ...state, ...payload, loaded: true };
    }
    default:
      return state;
  }
};

export default addressReducer;

import { createAction } from '@reduxjs/toolkit';

import { StorefrontBooking } from '~/types/types';

export const receivedStorefrontBookingsAction = createAction<StorefrontBooking[]>(
  'zola/vendorStorefront/RECEIVED_STOREFRONT_BOOKINGS'
);
export const createdStorefrontBookingAction = createAction<StorefrontBooking>(
  'zola/vendorStorefront/CREATED_STOREFRONT_BOOKING'
);
export const createdStorefrontBookingRangeAction = createAction<StorefrontBooking[]>(
  'zola/vendorStorefront/CREATED_STOREFRONT_BOOKING_RANGE'
);
export const deletedStorefrontBookingAction = createAction<StorefrontBooking>(
  'zola/vendorStorefront/DELETED_STOREFRONT_BOOKING'
);

import { AccountWeddingPreferencesView } from '~/types/responseTypes';

import * as ActionTypes from '../actions/types/accountPreferenceActionTypes';
import { WeddingPreferenceActionType } from '../actions/types/accountPreferenceActionTypes';

interface EmptyWeddingPreferencesState {
  loaded: false;
}
export interface LoadedWeddingPreferences extends AccountWeddingPreferencesView {
  loaded: true;
}

export type WeddingPreferencesState = EmptyWeddingPreferencesState | LoadedWeddingPreferences;

const initialState: EmptyWeddingPreferencesState = {
  loaded: false,
};

const weddingPreferencesReducer = (
  state: WeddingPreferencesState = initialState,
  action: WeddingPreferenceActionType
): WeddingPreferencesState => {
  switch (action.type) {
    case ActionTypes.REQUESTING_WEDDING_PREFERENCES:
      return { ...state, loaded: false };
    case ActionTypes.RECEIVED_WEDDING_PREFERENCES:
    case ActionTypes.UPDATED_WEDDING_PREFERENCES:
      return { ...state, ...action.payload, loaded: true };
    default:
      return state;
  }
};

export default weddingPreferencesReducer;

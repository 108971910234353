import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { PreferredVendorSummaryView, StorefrontPreferredVendorView } from '~/types/responseTypes';

export const VENDOR_REQUESTING_PREFERRED_VENDORS =
  'zola/vendorStorefront/REQUESTING_PREFERRED_VENDORS';
export const VENDOR_RECEIVED_PREFERRED_VENDORS = 'zola/vendorStorefront/RECEIVED_PREFERRED_VENDORS';

export const VENDOR_UPDATING_PREFERRED_VENDOR = 'zola/vendorStorefront/UPDATING_PREFERRED_VENDOR';
export const VENDOR_UPDATED_PREFERRED_VENDOR = 'zola/vendorStorefront/UPDATED_PREFERRED_VENDOR';

export const VENDOR_CREATING_PREFERRED_VENDOR = 'zola/vendorStorefront/CREATING_PREFERRED_VENDOR';
export const VENDOR_CREATED_PREFERRED_VENDOR = 'zola/vendorStorefront/CREATED_PREFERRED_VENDOR';

export const VENDOR_DELETING_PREFERRED_VENDOR = 'zola/vendorStorefront/DELETING_PREFERRED_VENDOR';
export const VENDOR_DELETED_PREFERRED_VENDOR = 'zola/vendorStorefront/DELETED_PREFERREfD_VENDOR';

// separate from vendor version just for the redux dev tools (they call different endpoints)
export const COUPLE_REQUESTING_PREFERRED_VENDORS = 'zola/storefront/REQUESTING_PREFERRED_VENDORS';
export const COUPLE_RECEIVED_PREFERRED_VENDORS = 'zola/storefront/RECEIVED_PREFERRED_VENDORS';

export type VendorRequestingPreferredVendorsAction = PayloadAction<
  string,
  typeof VENDOR_REQUESTING_PREFERRED_VENDORS
>;
export type VendorReceivedPreferredVendorsAction = PayloadAction<
  PreferredVendorSummaryView,
  typeof VENDOR_RECEIVED_PREFERRED_VENDORS
>;
export type VendorUpdatingPreferredVendorAction = Action<typeof VENDOR_UPDATING_PREFERRED_VENDOR>;
export type VendorUpdatedPreferredVendorAction = PayloadAction<
  StorefrontPreferredVendorView,
  typeof VENDOR_UPDATED_PREFERRED_VENDOR
>;
export type VendorCreatingPreferredVendorAction = Action<typeof VENDOR_CREATING_PREFERRED_VENDOR>;
export type VendorCreatedPreferredVendorAction = PayloadAction<
  StorefrontPreferredVendorView,
  typeof VENDOR_CREATED_PREFERRED_VENDOR
>;
export type VendorDeletingPreferredVendorAction = Action<typeof VENDOR_DELETING_PREFERRED_VENDOR>;
export type VendorDeletedPreferredVendorAction = PayloadAction<
  StorefrontPreferredVendorView,
  typeof VENDOR_DELETED_PREFERRED_VENDOR
>;
export type CoupleRequestingPreferredVendorsAction = PayloadAction<
  string,
  typeof COUPLE_REQUESTING_PREFERRED_VENDORS
>;
export type CoupleReceivedPreferredVendorsAction = PayloadAction<
  PreferredVendorSummaryView,
  typeof COUPLE_RECEIVED_PREFERRED_VENDORS
>;

export type StorefrontPreferredVendorsActions =
  | VendorRequestingPreferredVendorsAction
  | VendorReceivedPreferredVendorsAction
  | VendorUpdatingPreferredVendorAction
  | VendorUpdatedPreferredVendorAction
  | VendorCreatingPreferredVendorAction
  | VendorCreatedPreferredVendorAction
  | VendorDeletingPreferredVendorAction
  | VendorDeletedPreferredVendorAction
  | CoupleRequestingPreferredVendorsAction
  | CoupleReceivedPreferredVendorsAction;

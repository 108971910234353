import { createAction } from '@reduxjs/toolkit';

import { CatererDetails } from '~/types/responseTypes';

const REQUESTING_CATERER_DETAILS = 'zola/caterer/REQUESTING_CATERER_DETAILS';
const RECEIVED_CATERER_DETAILS = 'zola/caterer/RECEIVED_CATERER_DETAILS';
const UPDATING_CATERER_DETAILS = 'zola/caterer/UPDATING_CATERER_DETAILS';
const UPDATED_CATERER_DETAILS = 'zola/caterer/UPDATED_CATERER_DETAILS';

export const requestingCatererDetails = createAction(REQUESTING_CATERER_DETAILS);
export const receivedCatererDetails = createAction<CatererDetails>(RECEIVED_CATERER_DETAILS);

export const updatingCatererDetails = createAction(UPDATING_CATERER_DETAILS);
export const updatedCatererDetails = createAction<CatererDetails>(UPDATED_CATERER_DETAILS);

import type { RegionCodesResponse } from '~/types/types';

export const REQUESTING_REGION_CODES = 'zola/address/REQUESTING_REGION_CODES';
export const RECEIVED_REGION_CODES = 'zola/address/RECEIVED_REGION_CODES';

interface RequestRegionCodesAction {
  type: typeof REQUESTING_REGION_CODES;
}

export interface ReceivedRegionCodesActions {
  type: typeof RECEIVED_REGION_CODES;
  payload: RegionCodesResponse;
}

export type RegionCodeActionTypes = RequestRegionCodesAction | ReceivedRegionCodesActions;

import {
  RECEIVED_BEAUTICIAN_DETAILS,
  UPDATED_BEAUTICIAN_DETAILS,
} from '~/actions/types/beauticianActionTypes';
import type { BeauticianActionType } from '~/actions/types/beauticianActionTypes';
import { BeauticianDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

const initialState: StorefrontEntityState<BeauticianDetails> = {
  // Look up a beautician by id
  byId: {},

  // all the IDs of all the beauticians we've loaded
  allIds: [],

  // Map storefront ids to beautician ids
  storefrontToId: {},
};

const beauticianReducer = (
  state = initialState,
  action: BeauticianActionType
): StorefrontEntityState<BeauticianDetails> => {
  switch (action.type) {
    case RECEIVED_BEAUTICIAN_DETAILS:
    case UPDATED_BEAUTICIAN_DETAILS: {
      return setStorefrontEntityObject(state, action.payload);
    }
    default:
      return state;
  }
};

export default beauticianReducer;

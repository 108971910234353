import { StorefrontPackageView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

/* Bulk Packages */
const REQUESTING_PACKAGES = 'zola/packages/REQUESTING_PACKAGES';
const RECEIVED_PACKAGES = 'zola/packages/RECEIVED_PACKAGES';
const UPDATING_PACKAGES = 'zola/packages/UPDATING_PACKAGES';
const UPDATED_PACKAGES = 'zola/packages/UPDATED_PACKAGES';

/* Singular Package */
const REQUESTING_PACKAGE = 'zola/packages/REQUESTING_PACKAGE';
const RECEIVED_PACKAGE = 'zola/packages/RECEIVED_PACKAGE';
const UPDATING_PACKAGE = 'zola/packages/UPDATING_PACKAGE';
const UPDATED_PACKAGE = 'zola/packages/UPDATED_PACKAGE';
const CREATING_PACKAGE = 'zola/packages/CREATING_PACKAGE';
const CREATED_PACKAGE = 'zola/packages/CREATED_PACKAGE';
const DELETING_PACKAGE = 'zola/packages/DELETING_PACKAGE';
const DELETED_PACKAGE = 'zola/packages/DELETED_PACKAGE';

export const requestingPackage = createAction(REQUESTING_PACKAGE);
export const receivedPackage = createAction<StorefrontPackageView>(RECEIVED_PACKAGE);

export const requestingPackages = createAction(REQUESTING_PACKAGES);
export const receivedPackages = createAction<StorefrontPackageView[]>(RECEIVED_PACKAGES);

export const updatingPackage = createAction(UPDATING_PACKAGE);
export const updatedPackage = createAction<StorefrontPackageView>(UPDATED_PACKAGE);

export const creatingPackage = createAction(CREATING_PACKAGE);
export const createdPackage = createAction<StorefrontPackageView>(CREATED_PACKAGE);

export const deletingPackage = createAction(DELETING_PACKAGE);
export const deletedPackage = createAction<StorefrontPackageView>(DELETED_PACKAGE);

export const updatingPackages = createAction(UPDATING_PACKAGES);
export const updatedPackages = createAction<StorefrontPackageView[]>(UPDATED_PACKAGES);

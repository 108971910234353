import { createAction } from '@reduxjs/toolkit';

import { MappedInquiryView } from '~/types/mappedResponseTypes';
import { SlimInquiryView } from '~/types/responseTypes';

const RECEIVED_LAST_INQUIRY_TO_STOREFRONT =
  'zola/couplesInquiries/RECEIVED_LAST_INQUIRY_TO_STOREFRONT';

const RECEIVED_DRAFT = 'zola/couplesInquiries/RECEIVED_DRAFT';
const DELETED_DRAFT = 'zola/couplesInquiries/DELETED_DRAFT';
const MARKED_DRAFT_FOR_DELETION = 'zola/couplesInquiries/MARKED_DRAFT_FOR_DELETION';
const RECEIVED_BULK_INQUIRY_TO_STOREFRONT =
  'zola/couplesInquiries/RECEIVED_BULK_INQUIRY_TO_STOREFRONT';

interface DraftPayload {
  storefrontUuid: string;
  draftUuid: string;
}

export const receivedLastInquiryToStorefront = createAction<MappedInquiryView>(
  RECEIVED_LAST_INQUIRY_TO_STOREFRONT
);

export const receivedDraft = createAction<DraftPayload>(RECEIVED_DRAFT);

export const deletedDraft = createAction<DraftPayload>(DELETED_DRAFT);

export const markedDraftForDeletion = createAction<string | null>(MARKED_DRAFT_FOR_DELETION);

export const receivedBulkInquiryToStorefront = createAction<Record<string, string>>(
  RECEIVED_BULK_INQUIRY_TO_STOREFRONT
);

export const receivedRecentlyDeclined = createAction<SlimInquiryView[]>(
  'zola/couplesInquiries/RECEIVED_RECENTLY_DECLINED'
);

import { PayloadAction } from '@reduxjs/toolkit';

import { WeddingColorType } from '~/types/weddings.d';

export const REQUEST_WEDDING_COLORS = 'zola/weddings/v2/REQUEST_WEDDING_COLORS';
export const RECEIVED_WEDDING_COLORS = 'zola/weddings/v2/RECEIVED_WEDDING_COLORS';

export type RequestWeddingColorsAction = PayloadAction<
  Record<string, never>,
  typeof REQUEST_WEDDING_COLORS
>;
export type ReceivedWeddingColorsAction = PayloadAction<
  WeddingColorType[],
  typeof RECEIVED_WEDDING_COLORS
>;

export type WeddingColorActions = RequestWeddingColorsAction | ReceivedWeddingColorsAction;

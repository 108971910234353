import { VideographerDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

import {
  RECEIVED_VIDEOGRAPHER_DETAILS,
  UPDATED_VIDEOGRAPHER_DETAILS,
  VideographerActionType,
} from '../../actions/types/videographerActionTypes';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<VideographerDetails> = {
  // Look up a videographer by id
  byId: {},

  // all the IDs of all the videographers we've loaded
  allIds: [],

  // Map storefront ids to videographer ids
  storefrontToId: {},
};

const videographerReducer = (
  state = initialState,
  action: VideographerActionType
): StorefrontEntityState<VideographerDetails> => {
  switch (action.type) {
    case RECEIVED_VIDEOGRAPHER_DETAILS:
    case UPDATED_VIDEOGRAPHER_DETAILS: {
      return setStorefrontEntityObject(state, action.payload);
    }
    default:
      return state;
  }
};

export default videographerReducer;

import {
  RECEIVED_WEDDING_COLOR_CATEGORIES,
  REQUEST_WEDDING_COLOR_CATEGORIES,
  WeddingColorCategoryActions,
} from '~/actions/weddings/types/weddingColorCategoryActionTypes';
import { WeddingColorCategoryType } from '~/types/weddings.d';

export interface WeddingColorCategoriesState {
  busy: boolean;
  loaded: boolean;
  bySlug: Record<string, WeddingColorCategoryType>;
  byId: Record<number, WeddingColorCategoryType>;
  byKey: Record<string, WeddingColorCategoryType>;
  allSlugs: string[];
  allIds: number[];
  allKeys: string[];
}

const initialState: WeddingColorCategoriesState = {
  busy: false,
  loaded: false,
  bySlug: {},
  byId: {},
  byKey: {},
  allSlugs: [],
  allIds: [],
  allKeys: [],
};

export const weddingColorCategoriesReducer = (
  state: WeddingColorCategoriesState = initialState,
  action: WeddingColorCategoryActions
): WeddingColorCategoriesState => {
  switch (action.type) {
    case REQUEST_WEDDING_COLOR_CATEGORIES: {
      return { ...state, busy: true };
    }
    case RECEIVED_WEDDING_COLOR_CATEGORIES: {
      const newState: WeddingColorCategoriesState = {
        busy: false,
        loaded: true,
        bySlug: {},
        byId: {},
        byKey: {},
        allSlugs: [],
        allIds: [],
        allKeys: [],
      };
      const categories = action.payload;

      categories.forEach((category) => {
        const { slug, id, key } = category;

        newState.bySlug[slug] = category;
        newState.byId[id] = category;
        newState.byKey[key] = category;
        newState.allSlugs.push(slug);
        newState.allIds.push(id);
        newState.allKeys.push(key);
      });
      return newState;
    }

    default:
      return state;
  }
};

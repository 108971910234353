import { combineReducers } from 'redux';

import autoSendHellosReducer from './autoSendHellosReducer';
import cartReducer from './cartReducer';
import creditRenewalReducer from './creditRenewalReducer';
import creditsReducer from './creditsReducer';
import filesReducer from './filesReducer';
import interestedCouplesReducer from './interestedCouplesReducer';
import menuReducer from './menuReducer';
import orderStatsReducer from './orderStatsReducer';
import promosReducer from './promosReducer';
import vendorCreditAccountDetails from './vendorCreditAccountDetailsReducer';
import vendorWeddingsReducer from './vendorWeddingsReducer';

export default combineReducers({
  credits: creditsReducer, // vendors.credits
  orders: orderStatsReducer, // vendors.orders
  cart: cartReducer, // vendors.cart
  menu: menuReducer, // vendors.menu
  files: filesReducer, // vendors.files
  weddings: vendorWeddingsReducer, // vendors.weddings
  promos: promosReducer, // vendors.promos
  renewalPlan: creditRenewalReducer, // vendors.renewalPlan
  interestedCouplesData: interestedCouplesReducer, //vendors.interestedCouplesData
  autoSendHellos: autoSendHellosReducer, // vendors.autoSendHellos
  creditAccountDetails: vendorCreditAccountDetails, // vendors.vendorCreditAccountDetails
});

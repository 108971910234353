import {
  VendorFileActionTypes,
  RECEIVED_VENDOR_FILES,
  UPLOADED_VENDOR_FILE,
  UPLOADED_BATCH_VENDOR_FILES,
  DELETED_VENDOR_FILE,
} from '~/actions/vendors/types/fileActionTypes';
import type { StorefrontFileView } from '~/types/responseTypes';

type FileState = {
  allFiles: StorefrontFileView[];
};
export const initialState: FileState = {
  allFiles: [] as StorefrontFileView[],
};

const fileReducer = (state = initialState, action: VendorFileActionTypes): FileState => {
  switch (action.type) {
    case RECEIVED_VENDOR_FILES: {
      return { ...state, allFiles: action.payload };
    }
    case UPLOADED_VENDOR_FILE: {
      return {
        ...state,
        allFiles: state.allFiles.concat(action.payload),
      };
    }
    case UPLOADED_BATCH_VENDOR_FILES: {
      return {
        ...state,
        allFiles: state.allFiles.concat(action.payload),
      };
    }
    case DELETED_VENDOR_FILE: {
      return {
        ...state,
        allFiles: state.allFiles.filter((f) => f.uuid !== action.payload.uuid),
      };
    }
    default:
      return state;
  }
};

export default fileReducer;

import { MarketplaceNotification } from '~/components/common/notifications/constants';

import {
  CLEAR_NOTIFICATIONS,
  HIDE_NOTIFICATION,
  NotificationActionType,
  SHOW_NOTIFICATION,
} from '../actions/types/notificationActionTypes';

export const initialState: MarketplaceNotification[] = [];

const notificationsReducer = (
  state = initialState,
  action?: NotificationActionType
): MarketplaceNotification[] => {
  switch (action?.type) {
    case SHOW_NOTIFICATION:
      return [...state, action.payload];
    case HIDE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);
    case CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default notificationsReducer;

import { createAction } from '@reduxjs/toolkit';

import { MappedStorefrontDetails } from '~/types/storefrontDetails';

const SET_PUBLISHED_CLICKED = 'zola/vendor/SET_PUBLISHED_CLICKED';
const REQUESTING_PUBLICATION = 'zola/vendor/REQUESTING_PUBLICATION';
const REQUESTING_PUBLICATION_SUCCESS = 'zola/vendor/REQUESTING_PUBLICATION_SUCCESS';

export const setPublishedClicked =
  createAction<{ storefrontUuid: string; clicked: boolean }>(SET_PUBLISHED_CLICKED);

export const requestingPublication =
  createAction<{ storefrontUuid: string }>(REQUESTING_PUBLICATION);

export const publicationSuccess = createAction<{
  storefront: MappedStorefrontDetails;
}>(REQUESTING_PUBLICATION_SUCCESS);

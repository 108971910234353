import { AccountQuizView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

import type { AppDispatch } from '~/reducers';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

export const receivedQuizzesAnswered = createAction<AccountQuizView[]>(
  'zola/couples/quizzes/RECEIVED_QUIZZES_ANSWERED'
);

export const loadQuizzesAnswered = () => {
  return (dispatch: AppDispatch) => {
    return ApiService.get<AccountQuizView[]>('/web-marketplace-api/v2/couple-preference/quizzes')
      .then((response) => {
        dispatch(receivedQuizzesAnswered(response));
      })
      .catch((error) => {
        StandardLogFn(error);
      });
  };
};

import { combineReducers } from 'redux';

import { quizletsReducer } from './weddings/quizletsReducer';
import { weddingColorCategoriesReducer as colorCategories } from './weddings/weddingColorCategoriesReducer';
import { weddingColorReducer as colors } from './weddings/weddingColorReducer';
import { weddingFacetReducer as facets } from './weddings/weddingFacetReducer';
import { weddingSearchFacetReducer as searchFacets } from './weddings/weddingSearchFacetReducer';
import { weddingSearchReducer as search } from './weddings/weddingSearchReducer';

const weddings = combineReducers({
  colors,
  facets,
  searchFacets,
  search,
  'color-categories': colorCategories,
  quizlets: quizletsReducer,
});

export default weddings;

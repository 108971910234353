import { AccountVendorDashboardView } from '@zola/svc-marketplace-ts-types';

import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

export const REQUESTING_VENDOR_TEAM = 'zola/weddingsV3/quizlets/REQUESTING_VENDOR_TEAM';
export const RECEIVED_VENDOR_TEAM = 'zola/weddingsV3/quizlets/RECEIVED_VENDOR_TEAM';

export type RequestingVendorTeamAction = Action<typeof REQUESTING_VENDOR_TEAM>;
export type ReceivedVendorTeamAction = PayloadAction<
  AccountVendorDashboardView[],
  typeof RECEIVED_VENDOR_TEAM
>;

export type VendorTeamActions = RequestingVendorTeamAction | ReceivedVendorTeamAction;

import { PayloadAction } from '@reduxjs/toolkit';

export const ADD_TOAST = 'toasts/ADD_TOAST';
export const REMOVE_TOAST_BY_ID = 'toasts/REMOVE_TOAST';

export enum ToastTypes {
  ASK_LOCATION = 'ASK_LOCATION',
  PHOTO_MATCH_LOCATION = 'PHOTO_MATCH_LOCATION',
  PHOTO_MATCH_DATE_LOCATION = 'PHOTO_MATCH_DATE_LOCATION',
  VENDOR_SAVED = 'VENDOR_SAVED',
  PHOTO_SAVED = 'PHOTO_SAVED',
  MAKE_ACCOUNT = 'MAKE_ACCOUNT',
  FIRST_VENDOR_FAVORITE_TOAST = 'FIRST_VENDOR_FAVORITE_TOAST',
  SECOND_VENDOR_FAVORITE_TOAST = 'SECOND_VENDOR_FAVORITE_TOAST',
}

export type Toast = any;

export interface AddToastProps {
  toastType: ToastTypes;
  props: Toast;
}

export type AddToastAction = PayloadAction<AddToastProps, typeof ADD_TOAST>;
type RemoveToastByIdAction = PayloadAction<string, typeof REMOVE_TOAST_BY_ID>;

export type toastsActions = AddToastAction | RemoveToastByIdAction;

import { AccountQuizView } from '@zola/svc-marketplace-ts-types';

import { AnyAction } from 'redux';

import { receivedQuizzesAnswered } from '~/actions/couples/quizActions';

import type { RootState } from '..';

export type QuizzesAnsweredState = AccountQuizView[];

const initialState: QuizzesAnsweredState = [];

const quizReducer = (state = initialState, action: AnyAction): QuizzesAnsweredState => {
  if (receivedQuizzesAnswered.match(action)) {
    const { payload } = action;
    return [...state, ...payload];
  }
  return state;
};

export const getQuizzesAnswered = (state: RootState) => state.couples.quizzes;

export default quizReducer;

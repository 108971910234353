import { BakerDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

import {
  BakerActionType,
  RECEIVED_BAKER_DETAILS,
  UPDATED_BAKER_DETAILS,
} from '../../actions/types/bakerActionTypes';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<BakerDetails> = {
  // Look up a baker by id
  byId: {},

  // all the IDs of all the bakers we've loaded
  allIds: [],

  // Map storefront ids to baker ids
  storefrontToId: {},
};

const bakerReducer = (
  state = initialState,
  action: BakerActionType
): StorefrontEntityState<BakerDetails> => {
  switch (action.type) {
    case RECEIVED_BAKER_DETAILS:
    case UPDATED_BAKER_DETAILS: {
      return setStorefrontEntityObject(state, action.payload);
    }
    default:
      return state;
  }
};

export default bakerReducer;

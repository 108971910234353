import { MailingListSubscriptionView } from '@zola/svc-marketplace-ts-types';

export const REQUESTING_MAILING_LIST_SUBSCRIPTIONS =
  'zola/vendorAccountSettings/REQUESTING_MAILING_LIST_SUBSCRIPTIONS';
export const RECEIVED_MAILING_LIST_SUBSCRIPTIONS =
  'zola/vendorAccountSettings/RECEIVED_MAILING_LIST_SUBSCRIPTIONS';
export const UPDATING_MAILING_LIST_SUBSCRIPTIONS =
  'zola/vendorAccountSettings/UPDATING_MAILING_LIST_SUBSCRIPTIONS';
export const UPDATED_MAILING_LIST_SUBSCRIPTIONS =
  'zola/vendorAccountSettings/UPDATED_MAILING_LIST_SUBSCRIPTIONS';

export interface RequestingMailingListSubscriptionsAction {
  type: typeof REQUESTING_MAILING_LIST_SUBSCRIPTIONS;
}

export interface ReceivedMailingListSubscriptionsAction {
  type: typeof RECEIVED_MAILING_LIST_SUBSCRIPTIONS;
  payload: MailingListSubscriptionView[] | null;
}

export interface UpdatingMailingListSubscriptionsAction {
  type: typeof UPDATING_MAILING_LIST_SUBSCRIPTIONS;
}

export interface UpdatedMailingListSubscriptionsAction {
  type: typeof UPDATED_MAILING_LIST_SUBSCRIPTIONS;
  payload: MailingListSubscriptionView[];
}

export type VendorMailingListSettingsAction =
  | RequestingMailingListSubscriptionsAction
  | ReceivedMailingListSubscriptionsAction
  | UpdatingMailingListSubscriptionsAction
  | UpdatedMailingListSubscriptionsAction;

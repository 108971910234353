/* eslint-disable @typescript-eslint/no-restricted-imports */
import { ExactDateView } from '@zola/svc-marketplace-ts-types';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isTodayExtension from 'dayjs/plugin/isToday';
import isYesterdayExtension from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isTodayExtension);
dayjs.extend(isYesterdayExtension);

export type DateValue = Date | number | string;

/**
 * Checks whether a date is valid
 *
 * @param date - The date to check
 * @returns True if the date is valid; false otherwise, such as "2021-03-00" (there is no March 0)
 */
export const isValidDate = (date?: DateValue | null): boolean => {
  return Boolean(date && dayjs(date).isValid());
};

/**
 * Checks whether a date is before another date
 *
 * @param date - The date to check
 * @param fixedPointInTime - The date to compare against
 *
 * @example
 * isBeforeDate( <yesterday>, <today> ) => true
 *
 * @example
 * isBeforeDate( <yesterday>, new Date() ) => true
 *
 * @example
 * isBeforeDate( new Date(), <yesterday> ) => false
 *
 * @returns True if date is earlier in time than beforeDate
 */
export const isBeforeDate = (date: DateValue, fixedPointInTime: DateValue): boolean => {
  return dayjs(date).isBefore(fixedPointInTime);
};

/**
 * Checks whether a date is after another date
 *
 * @param date - The date to check
 * @param fixedPointInTime - The date to compare against
 *
 * @returns True if the date is after the other date; false otherwise
 */
export const isAfterDate = (date: DateValue, fixedPointInTime: DateValue): boolean => {
  return dayjs(date).isAfter(fixedPointInTime);
};

/**
 * Parses the specified date based on a format string, represented as local time
 *
 * @param date - The date string to parse
 * @param format - An optional tokenized format string
 * @param strict - Strict parsing requires the date string to exactly match the format string, including delimiters
 * @returns A new date as local time
 */
export const parseDate = (date: DateValue, format?: string, strict = true): Date => {
  return dayjs(date, format, strict).toDate();
};

/**
 * Parses the specified date based on a format string, represented as UTC
 *
 * @param date - The date string to parse
 * @param format - An optional tokenized format string
 * @returns A new date as UTC
 */
export const parseDateUtc = (date: DateValue | undefined, format?: string): Date => {
  return dayjs.utc(date, format).toDate();
};

/**
 * Gets the current or specified date, represented as local time
 *
 * @param date - An optional date to convert to local time
 * @returns A new date as local time
 */
export const getDate = (date?: DateValue): Date => {
  return dayjs(date).toDate();
};

/**
 * Gets the current or specified date, represented as UTC
 *
 * @param date - An optional date to convert to UTC
 * @returns A new date as UTC
 */
export const getDateUtc = (date?: DateValue): Date => {
  return dayjs.utc(date).toDate();
};

/**
 * Gets the number of milliseconds since the Unix Epoch
 *
 * @param date - The date to get the value of
 * @returns The number of milliseconds since the Unix Epoch
 */
export const getDateMilliseconds = (date?: DateValue): number => {
  return dayjs(date).valueOf();
};

/**
 * Gets the number of milliseconds since the Unix Epoch
 *
 * @param date - The date to get the value of
 * @returns The number of milliseconds since the Unix Epoch
 */
export const getDateMillisecondsUtc = (date?: DateValue): number => {
  return dayjs.utc(date).valueOf();
};

/**
 * Gets the number of days in the specified month
 *
 * @param date - The month date
 * @returns The number of days in the month
 */
export const getDaysInMonth = (date: DateValue): number => {
  return dayjs(date).daysInMonth();
};

/**
 * Adds the specified number of minutes to a date
 *
 * @param date - The date to add days to
 * @param minutes - The number of minutes to add, which can also be negative
 * @returns A new date
 */
export const addMinutes = (date: DateValue, minutes: number): Date => {
  return dayjs(date).add(minutes, 'minutes').toDate();
};

/**
 * Adds the specified number of days to a date
 *
 * @param date - The date to add days to
 * @param days - The number of days to add, which can also be negative
 * @returns A new date
 */
export const addDays = (date: DateValue, days: number): Date => {
  return dayjs(date).add(days, 'days').toDate();
};

/**
 * Adds the specified number of months to a date
 *
 * @param date - The date to add months to
 * @param months - The number of months to add, which can also be negative
 * @returns A new date
 */
export const addMonths = (date: DateValue, months: number): Date => {
  return dayjs(date).add(months, 'months').toDate();
};

/**
 * Adds the specified number of years to a date
 *
 * @param date - The date to add years to
 * @param years - The number of years to add, which can also be negative
 * @returns A new date
 */
export const addYears = (date: DateValue, years: number): Date => {
  return dayjs(date).add(years, 'years').toDate();
};

/**
 * Formats a date based on a tokenized string, represented as local time
 *
 * @param date - The date to format
 * @param format - A tokenized format string
 * @returns A string in the specified format, such as "M/D/YYYY"
 */
export const formatDate = (date: DateValue, format: string): string => {
  return dayjs(date).format(format);
};

/**
 * Formats a date based on a tokenized string, represented as UTC
 *
 * @param date - The date to format
 * @param format - A tokenized format string
 * @returns A string in the specified format, such as "M/D/YYYY"
 */
export const formatDateUtc = (date: DateValue, format: string): string => {
  return dayjs.utc(date).format(format);
};

/**
 * Formats a date as a simplified extended ISO string
 *
 * @param date - The date to format
 * @returns A string in simplified extended ISO format, such as "2021-03-20T00:00:00.000Z"
 */
export const formatDateIso = (date: DateValue): string => {
  return dayjs(date).toISOString();
};

/**
 * Formats a date as a string representing the time in relation to another date
 *
 * @param date - The date to format
 * @param fromDate - The date to use as a reference
 * @param noSuffix - If true, the value is returned without the suffix, i.e.
 * "ago" or "in". For example, "a day" vs "a day ago" or "4 days" vs "in 4 days"
 * @returns A string representing a relative time, such as "a day ago" or "in 6 months"
 */
export const formatDateFrom = (date: DateValue, fromDate: DateValue, noSuffix = false): string => {
  return dayjs(date).from(fromDate, noSuffix);
};

/**
 * Formats a date in a less granular way than formatDateFrom
 *
 * @param date - The date to format
 * @returns A string representing a relative time, such as "today" or "last week"
 */
export const formatCalendarFrom = (date: DateValue): string => {
  return dayjs(date).calendar(new Date(), {
    sameDay: '[Today]', // The same day ( Today at 2:30 AM )
    nextDay: '[Tomorrow]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Yesterday]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Last] dddd', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'M/D/YYYY', // Everything else ( 7/10/2011 )
  });
};

/**
 * Determines if date is in the future
 *
 * @param date - The date to check
 * @returns True if the date is in the future; false otherwise
 */
export const isInTheFuture = (date: DateValue): boolean => {
  return dayjs(date).isAfter(new Date());
};

export const isInThePast = (date: DateValue): boolean => {
  return dayjs(date).isBefore(new Date());
};

export const getDifferenceInDays = (targetDate: DateValue | undefined): number => {
  return dayjs().diff(dayjs(targetDate), 'days');
};

/**
 * Get the date of the start of today (00:00)
 *
 * @returns A new date as local time
 */
export const getStartOfToday = (): Date => {
  return dayjs().startOf('day').toDate();
};

/**
 * Gets the upcoming date of a specified hour (either today or tomorrow)
 *
 * @param number - the specified hour to use
 * @returns The number of milliseconds since the Unix Epoch
 */
const getNextSpecifiedHour = (hour: number): number => {
  const todaysReset = dayjs().utc(true).startOf('day').add(hour, 'hours');
  if (todaysReset > dayjs()) return todaysReset.valueOf();
  return todaysReset.add(1, 'day').valueOf();
};

// Real Wedding Landing Page resets at 09:00 UTC daily
export const getNextRWReset = (): number => getNextSpecifiedHour(9);

/**
 * Formats a past time relative to now using date JS relative time formatting.
 *
 *
 * |Range|Sample Output|
 * | -- | -- |
 * |0 to 44 seconds|a few seconds ago|
 * |45 to 89 seconds|a minute ago|
 * |90 seconds to 44 minutes|2 minutes ago ... 44 minutes ago|
 * |45 to 89 minutes|an hour ago|
 * |90 minutes to 21 hours|2 hours ago ... 21 hours ago|
 * |22 to 35 hours|a day ago|
 * |36 hours to 25 days|2 days ago ... 25 days ago|
 * |26 to 45 days|a month ago|
 * |46 days to 10 months|2 months ago ... 10 months ago|
 * |11 months to 17months|a year ago|
 * |18 months+|2 years ago ... 20 years ago|
 *
 */
export const formatTimeAgo = (date: DateValue): string => {
  return dayjs(date).fromNow();
};

export const isToday = (date: DateValue): boolean => {
  return dayjs(date).isToday();
};

export const isYesterday = (date: DateValue): boolean => {
  return dayjs(date).isYesterday();
};

export const getDateFromExactDate = (date: ExactDateView): Date => {
  return parseDate(`${date.year}-${date.month}-${date.day}`, 'YYYY-M-D');
};

export const getEndOfMonth = (date: DateValue) => {
  return dayjs(date).endOf('month').toDate();
};

export const CREATING_STOREFRONT_VIDEO = 'zola/vendorStorefrontVideos/CREATING_STOREFRONT_VIDEO';
export const CREATED_STOREFRONT_VIDEO = 'zola/vendorStorefrontVideos/CREATED_STOREFRONT_VIDEO';

export const REQUESTING_STOREFRONT_VIDEO_GALLERY =
  'zola/vendorStorefrontVideos/REQUESTING_STOREFRONT_VIDEO_GALLERY';
export const RECEIVED_STOREFRONT_VIDEO_GALLERY =
  'zola/vendorStorefrontVideos/RECEIVED_STOREFRONT_VIDEO_GALLERY';

export const REPLACING_STOREFRONT_VIDEO_GALLERY =
  'zola/vendorStorefrontVideos/REPLACING_STOREFRONT_VIDEO_GALLERY';
export const REPLACED_STOREFRONT_VIDEO_GALLERY =
  'zola/vendorStorefrontVideos/REPLACED_STOREFRONT_VIDEO_GALLERY';

export const EDITING_VIDEO = 'zola/vendorStorefrontVideos/EDITING_VIDEO';
export const CANCEL_EDIT = 'zola/vendorStorefrontVideos/CANCEL_EDIT';
export const REMOVED_VIDEO = 'zola/vendorStorefrontVideos/REMOVED_VIDEO';
export const SWAP_VIDEO_ORDER = 'zola/vendorStorefrontVideos/SWAP_VIDEO_ORDER';

/* eslint-disable no-param-reassign */

import {
  RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE,
  RECEIVED_UNREAD_INQUIRIES,
  RECEIVED_UNRESPONDED_INQUIRIES,
  InquiryJewelActionTypes,
} from '~/actions/types/inquiryJewelActionTypes';
import type { ActionableInquiryView } from '~/types/responseTypes';

export interface InquiryJewelState {
  inquiryPaperCrossSellEligible: boolean | null;
  unreadInquiries: ActionableInquiryView[];
  unreadInquiriesLoaded: boolean;
  unrespondedInquiries: ActionableInquiryView[];
}

export const initialState: InquiryJewelState = {
  inquiryPaperCrossSellEligible: null,
  unreadInquiries: [],
  unreadInquiriesLoaded: false,
  unrespondedInquiries: [],
};

const inquiryJewelReducer = (
  state: InquiryJewelState = initialState,
  action: InquiryJewelActionTypes
): InquiryJewelState => {
  switch (action.type) {
    case RECEIVED_UNREAD_INQUIRIES: {
      const unreadInquiries = action.payload;
      return { ...state, unreadInquiries, unreadInquiriesLoaded: true };
    }
    case RECEIVED_UNRESPONDED_INQUIRIES: {
      const unrespondedInquiries = action.payload;
      return { ...state, unrespondedInquiries };
    }
    case RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE: {
      return {
        ...state,
        inquiryPaperCrossSellEligible: action.payload.inquiryPaperCrossSellEligible,
      };
    }
    default:
      return state;
  }
};

export default inquiryJewelReducer;

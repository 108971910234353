import { CreditAccountDetailsView } from '@zola/svc-marketplace-ts-types';

export type VendorCreditAccountState = {
  storefrontUuid: string;
  creditAccountDetails: CreditAccountDetailsView;
};
export const REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS = 'REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS';
export const RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS = 'RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS';

export interface RequestingVendorCreditAccountDetailsAction {
  type: typeof REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS;
}

export interface ReceivingVendorCreditAccountDetailsAction {
  type: typeof RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS;
  payload: VendorCreditAccountState;
}

export type VendorCreditAccountDetailsActionTypes =
  | RequestingVendorCreditAccountDetailsAction
  | ReceivingVendorCreditAccountDetailsAction;

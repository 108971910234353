import type { WModuleGroupView } from '@zola/svc-web-api-ts-client';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Action } from 'redux';
import type { CamelCasedPropertiesDeep } from 'type-fest/index.d';

export type ModuleGroupView = CamelCasedPropertiesDeep<WModuleGroupView>;

export const REQUESTING_PROMO_MODULE = 'zola/promoModules/REQUESTING_PROMO_MODULE';
export const RECEIVED_PROMO_MODULE = 'zola/promoModules/RECEIVED_PROMO_MODULE';

export type RequestingPromoModuleAction = Action<typeof REQUESTING_PROMO_MODULE>;
export type ReceivedPromoModuleAction = PayloadAction<
  ModuleGroupView,
  typeof RECEIVED_PROMO_MODULE
>;

export type PromoActions = RequestingPromoModuleAction | ReceivedPromoModuleAction;

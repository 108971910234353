import { CatererStoryGalleryView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

import { CatererStoryView, EntityFacetView } from '~/types/responseTypes';

export const requestingStoryCovers = createAction('zola/caterers/stories/REQUESTING_STORY_COVERS');

export const receivedStoryCovers = createAction<{
  storyUuid: string;
  covers: CatererStoryGalleryView[];
}>('zola/caterers/stories/RECEIVED_STORY_COVERS');

export const requestingStoryOptions = createAction(
  'zola/caterers/stories/REQUESTING_STORY_OPTIONS'
);

export const receivedStoryOptions = createAction<{
  storyUuid: string;
  options: EntityFacetView[];
}>('zola/caterers/stories/RECEIVED_STORY_OPTIONS');

export const requestingCatererStories = createAction('zola/caterers/REQUESTING_CATERER_STORIES');
export const receivedCatererStories = createAction<{
  catererUuid: string;
  stories: CatererStoryView[];
}>('zola/caterers/RECEIVED_CATERER_STORIES');

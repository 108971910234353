import { CoupleSearchListView } from '@zola/svc-marketplace-ts-types';

export const RECEIVED_INTERESTED_COUPLES = 'zola/vendorStorefront/RECEIVED_INTERESTED_COUPLES';
export interface ReceivedInquiryPaperCrossSellEligible {
  type: typeof RECEIVED_INTERESTED_COUPLES;
  payload: {
    interestedCouplesData: CoupleSearchListView;
  };
}
export type InterestedCoupleActionTypes = ReceivedInquiryPaperCrossSellEligible;

import { FacetParentKey } from '~/types/facets';
import { MappedOptionFacetView, TaxonomyNodeView } from '~/types/responseTypes';

import {
  REQUESTING_TAXONOMY,
  RECEIVED_TAXONOMY,
  RECEIVED_FACETS,
  TaxonomyActions,
} from '../actions/types/taxonomyActionTypes';

export interface TaxonomyState {
  busy: boolean;
  types: TaxonomyNodeView[];
  facets: { [key in FacetParentKey]: MappedOptionFacetView[] };
}
export const initialState: TaxonomyState = {
  types: [],
  // @ts-expect-error most of the code in the app assumes this will always be complete
  facets: {},
  busy: false,
};

const taxonomyReducer = (state = initialState, action?: TaxonomyActions): TaxonomyState => {
  switch (action?.type) {
    case REQUESTING_TAXONOMY: {
      return { ...state, busy: true };
    }
    case RECEIVED_TAXONOMY: {
      return { ...state, types: action.payload, busy: false };
    }
    case RECEIVED_FACETS: {
      const key = action.key || 'all';
      const newFacets = { [key]: action.payload };
      const combinedFacets = {
        ...state.facets,
        ...newFacets,
      };
      return { ...state, facets: combinedFacets };
    }
    default:
      return state;
  }
};

export default taxonomyReducer;

import { MusicianDetails } from '~/types/responseTypes';

export const REQUESTING_MUSICIAN_DETAILS_BY_STOREFRONT =
  'zola/musician/REQUESTING_MUSICIAN_DETAILS_BY_STOREFRONT';
export const RECEIVED_MUSICIAN_DETAILS = 'zola/musician/RECEIVED_MUSICIAN_DETAILS';

export const UPDATING_MUSICIAN_DETAILS = 'zola/musician/UPDATING_MUSICIAN_DETAILS';
export const UPDATED_MUSICIAN_DETAILS = 'zola/musician/UPDATED_MUSICIAN_DETAILS';

export interface RequestingMusicianDetailsByStorefrontAction {
  type: typeof REQUESTING_MUSICIAN_DETAILS_BY_STOREFRONT;
}
export interface ReceivedMusicianDetailsAction {
  type: typeof RECEIVED_MUSICIAN_DETAILS;
  payload: MusicianDetails;
}
export interface UpdatingMusicianDetailsAction {
  type: typeof UPDATING_MUSICIAN_DETAILS;
}
export interface UpdatedMusicianDetailsAction {
  type: typeof UPDATED_MUSICIAN_DETAILS;
  payload: MusicianDetails;
}

export type MusicianActionType =
  | RequestingMusicianDetailsByStorefrontAction
  | ReceivedMusicianDetailsAction
  | UpdatingMusicianDetailsAction
  | UpdatedMusicianDetailsAction;

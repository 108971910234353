/* eslint-disable no-param-reassign */

import { CoupleSearchListView } from '@zola/svc-marketplace-ts-types';

import {
  InterestedCoupleActionTypes,
  RECEIVED_INTERESTED_COUPLES,
} from '~/actions/types/interestedCoupleActionTypes';

export interface InterestedCouplesState {
  interestedCouples: CoupleSearchListView | null;
  loaded: boolean;
}

export const initialState: InterestedCouplesState = {
  interestedCouples: null,
  loaded: false,
};

const interestedCouplesReducer = (
  state: InterestedCouplesState = initialState,
  action: InterestedCoupleActionTypes
): InterestedCouplesState => {
  switch (action.type) {
    case RECEIVED_INTERESTED_COUPLES: {
      return { ...state, interestedCouples: action.payload.interestedCouplesData, loaded: true };
    }
    default:
      return state;
  }
};

export default interestedCouplesReducer;

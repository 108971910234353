import { Action } from 'redux';

import { CatererDetails } from '~/types/responseTypes';
import { StorefrontEntityState, setStorefrontEntityObject } from '~/util/reducerUtils';

import {
  receivedCatererDetails,
  updatedCatererDetails,
} from '../../actions/types/catererActionTypes';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
export const initialState: StorefrontEntityState<CatererDetails> = {
  // Look up a caterer by id
  byId: {},

  // all the IDs of all the caterers we've loaded
  allIds: [],

  // Map storefront ids to caterer ids
  storefrontToId: {},
};

const catererReducer = (
  state = initialState,
  action?: Action
): StorefrontEntityState<CatererDetails> => {
  if (!action) {
    return state;
  }

  if (receivedCatererDetails.match(action) || updatedCatererDetails.match(action)) {
    return setStorefrontEntityObject(state, action.payload);
  }

  return state;
};

export default catererReducer;

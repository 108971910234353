import { PhotographerDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

import {
  PhotographerActionType,
  RECEIVED_PHOTOGRAPHER_DETAILS,
  UPDATED_PHOTOGRAPHER_DETAILS,
} from '../../actions/types/photographerActionTypes';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<PhotographerDetails> = {
  // Look up a photographer by id
  byId: {},

  // all the IDs of all the photographers we've loaded
  allIds: [],

  // Map storefront ids to photographer ids
  storefrontToId: {},
};

const photographerReducer = (
  state = initialState,
  action: PhotographerActionType
): StorefrontEntityState<PhotographerDetails> => {
  switch (action.type) {
    case RECEIVED_PHOTOGRAPHER_DETAILS:
    case UPDATED_PHOTOGRAPHER_DETAILS: {
      return setStorefrontEntityObject(state, action.payload);
    }
    default:
      return state;
  }
};

export default photographerReducer;

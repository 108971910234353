import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { AccountVendorPreferencesView, AccountWeddingPreferencesView } from '~/types/responseTypes';

export const UPDATED_VENDOR_PREFERENCES =
  'marketplace/account-preferences/UPDATED_VENDOR_PREFERENCES';

export const UPDATED_WEDDING_PREFERENCES =
  'marketplace/account-preferences/UPDATED_WEDDING_PREFERENCES';

export const UPDATING_WEDDING_LOCATION =
  'marketplace/account-preferences/UPDATING_WEDDING_LOCATION';
export const UPDATED_WEDDING_LOCATION = 'marketplace/account-preferences/UPDATED_WEDDING_LOCATION';

export const REQUESTING_VENDOR_PREFERENCES =
  'marketplace/account-preferences/REQUESTING_VENDOR_PREFERENCES';
export const REQUESTING_WEDDING_PREFERENCES =
  'marketplace/account-preferences/REQUESTING_WEDDING_PREFERENCES';
export const RECEIVED_VENDOR_PREFERENCES =
  'marketplace/account-preferences/RECEIVED_VENDOR_PREFERENCES';
export const RECEIVED_WEDDING_PREFERENCES =
  'marketplace/account-preferences/RECEIVED_WEDDING_PREFERENCES';

export type RequestingWeddingPreferencesAction = Action<typeof REQUESTING_WEDDING_PREFERENCES>;
export type ReceivedWeddingPreferencesAction = PayloadAction<
  AccountWeddingPreferencesView,
  typeof RECEIVED_WEDDING_PREFERENCES
>;

export type RequestingVendorPreferencesAction = Action<typeof REQUESTING_VENDOR_PREFERENCES>;
export type ReceivedVendorPreferencesAction = PayloadAction<
  AccountVendorPreferencesView[],
  typeof RECEIVED_VENDOR_PREFERENCES
>;

export type UpdatedVendorPreferencesAction = PayloadAction<
  AccountVendorPreferencesView,
  typeof UPDATED_VENDOR_PREFERENCES
>;

export type UpdatedWeddingPreferencesAction = PayloadAction<
  AccountWeddingPreferencesView,
  typeof UPDATED_WEDDING_PREFERENCES
>;

export type UpdatingWeddingLocationAction = Action<typeof UPDATING_WEDDING_LOCATION>;
export type UpdatedWeddingLocationAction = PayloadAction<
  AccountWeddingPreferencesView,
  typeof UPDATED_WEDDING_LOCATION
>;

export type VendorPreferenceActionType =
  | RequestingVendorPreferencesAction
  | ReceivedVendorPreferencesAction
  | UpdatedVendorPreferencesAction;

export type WeddingPreferenceActionType =
  | RequestingWeddingPreferencesAction
  | ReceivedWeddingPreferencesAction
  | UpdatedWeddingPreferencesAction
  | UpdatingWeddingLocationAction
  | UpdatedWeddingLocationAction;

import {
  RECEIVED_WEDDING_COLORS,
  REQUEST_WEDDING_COLORS,
  WeddingColorActions,
} from '~/actions/weddings/types/weddingColorActionTypes';
import { WeddingColorType } from '~/types/weddings.d';

export interface WeddingColorState {
  busy: boolean;
  loaded: boolean;
  bySlug: Record<string, WeddingColorType>;
  byId: Record<number, WeddingColorType>;
  byKey: Record<string, WeddingColorType>;
  allSlugs: string[];
  allIds: number[];
  allKeys: string[];
}
const initialState: WeddingColorState = {
  busy: false,
  loaded: false,
  bySlug: {},
  byId: {},
  byKey: {},
  allSlugs: [],
  allIds: [],
  allKeys: [],
};

export const weddingColorReducer = (
  state = initialState,
  action: WeddingColorActions
): WeddingColorState => {
  switch (action.type) {
    case REQUEST_WEDDING_COLORS: {
      return { ...state, busy: true };
    }
    case RECEIVED_WEDDING_COLORS: {
      const newState: WeddingColorState = {
        busy: false,
        loaded: true,
        bySlug: {},
        byId: {},
        byKey: {},
        allSlugs: [],
        allIds: [],
        allKeys: [],
      };
      const colors = action.payload;

      colors.forEach((color: WeddingColorType) => {
        const { slug, id, key } = color;

        newState.bySlug[slug] = color;
        newState.byId[id] = color;
        newState.byKey[key] = color;
        newState.allSlugs.push(slug);
        newState.allIds.push(id);
        newState.allKeys.push(key);
      });
      return newState;
    }

    default:
      return state;
  }
};

import type { AccountVendorView } from '~/types/responseTypes';

import {
  RECEIVED_BOOKED_VENDORS,
  RECEIVED_CREATE_BOOKED_VENDOR,
  SENT_REMOVE_VENDOR_FEEDBACK,
  SENT_UNBOOK_VENDOR_CATEGORY,
  BookedVendorActions,
} from '../actions/types/bookedVendorsActionTypes';

export interface BookableVendors {
  byId: Record<number, AccountVendorView>;
  allIds: number[];
}

export interface BookedVendorState {
  isLoaded: boolean;
  bookableVendors: BookableVendors;
}
// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: BookedVendorState = {
  isLoaded: false,
  bookableVendors: {
    byId: {},
    allIds: [],
  },
};

const bookedVendorsReducer = (
  state = initialState,
  action?: BookedVendorActions
): BookedVendorState => {
  switch (action?.type) {
    case RECEIVED_BOOKED_VENDORS: {
      const byId = action.payload.reduce((obj, item) => {
        return {
          ...obj,
          [item.id]: item,
        };
      }, {} as Record<string, AccountVendorView>);

      const allIds = action.payload.map((item) => item.id);
      return {
        ...state,
        isLoaded: true,
        bookableVendors: {
          byId,
          allIds,
        },
      };
    }
    case RECEIVED_CREATE_BOOKED_VENDOR: {
      const { id } = action.payload;
      const { bookableVendors } = state;
      const { byId, allIds } = bookableVendors;
      byId[id] = action.payload;
      const newList = [...allIds, id] as number[];

      return {
        ...state,
        bookableVendors: {
          byId,
          allIds: newList,
        },
      };
    }
    /* unbook vendor requires updating a single record */
    case SENT_REMOVE_VENDOR_FEEDBACK: {
      const { id } = action.payload;
      const newBookableVendors = { ...state.bookableVendors };

      newBookableVendors.byId[id] = action.payload;

      return {
        ...state,
        bookableVendors: newBookableVendors,
      };
    }
    case SENT_UNBOOK_VENDOR_CATEGORY: {
      const { id } = action.payload;
      const newBookableVendors = { ...state.bookableVendors };

      newBookableVendors.byId[id].booked = false;

      return {
        ...state,
        bookableVendors: newBookableVendors,
      };
    }

    default:
      return state;
  }
};

export default bookedVendorsReducer;

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Action } from 'redux';

import type { VendorAccountState } from '~/reducers/vendorAccountReducer';

export const REQUESTING_VENDOR_SIGNUP = 'zola/vendorAccount/REQUESTING_VENDOR_SIGNUP';
export const RECEIVED_VENDOR_SIGNUP = 'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP';
export const RECEIVED_VENDOR_SIGNUP_ERROR = 'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP_ERROR';
export const REQUESTING_VENDOR_SIGNUP_CONFIRMATION =
  'zola/vendorAccount/REQUESTING_VENDOR_SIGNUP_CONFIRMATION';
export const RECEIVED_VENDOR_SIGNUP_CONFIRMATION =
  'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP_CONFIRMATION';
export const RECEIVED_VENDOR_SIGNUP_CONFIRMATION_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP_CONFIRMATION_ERROR';
export const REQUESTING_VENDOR_SIGNUP_CODE = 'zola/vendorAccount/REQUESTING_VENDOR_SIGNUP_CODE';
export const RECEIVED_VENDOR_SIGNUP_CODE = 'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP_CODE';
export const RECEIVED_VENDOR_SIGNUP_CODE_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_SIGNUP_CODE_ERROR';
export const REQUESTING_VENDOR_LOGIN = 'zola/vendorAccount/REQUESTING_VENDOR_LOGIN';
export const RECEIVED_VENDOR_LOGIN = 'zola/vendorAccount/RECEIVED_VENDOR_LOGIN';
export const RECEIVED_VENDOR_LOGIN_ERROR = 'zola/vendorAccount/RECEIVED_VENDOR_LOGIN_ERROR';
export const REQUESTING_VENDOR_PASSWORD_CHANGE =
  'zola/vendorAccount/REQUESTING_VENDOR_PASSWORD_CHANGE';
export const RECEIVED_VENDOR_PASSWORD_CHANGE = 'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_CHANGE';
export const RECEIVED_VENDOR_PASSWORD_CHANGE_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_CHANGE_ERROR';
export const REQUESTING_VENDOR_PASSWORD_RESET_CODE =
  'zola/vendorAccount/REQUESTING_VENDOR_PASSWORD_RESET_CODE';
export const RECEIVED_VENDOR_PASSWORD_RESET_CODE =
  'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_RESET_CODE';
export const RECEIVED_VENDOR_PASSWORD_RESET_CODE_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_RESET_CODE_ERROR';
export const REQUESTING_VENDOR_PASSWORD_RESET =
  'zola/vendorAccount/REQUESTING_VENDOR_PASSWORD_RESET';
export const RECEIVED_VENDOR_PASSWORD_RESET = 'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_RESET';
export const RECEIVED_VENDOR_PASSWORD_RESET_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_PASSWORD_RESET_ERROR';
export const REQUESTING_VENDOR_CURRENT_USER = 'zola/vendorAccount/REQUESTING_VENDOR_CURRENT_USER';
export const RECEIVED_VENDOR_CURRENT_USER = 'zola/vendorAccount/RECEIVED_VENDOR_CURRENT_USER';
export const RECEIVED_VENDOR_CURRENT_USER_ERROR =
  'zola/vendorAccount/RECEIVED_VENDOR_CURRENT_USER_ERROR';
export const REQUESTING_VENDOR_LOGOUT = 'zola/vendorAccount/REQUESTING_VENDOR_LOGOUT';
export const CLEARING_VENDOR_ACCOUNT_ERROR = 'zola/vendorAccount/CLEARING_VENDOR_ACCOUNT_ERROR';
export const SETTING_VENDOR_CURRENT_STOREFRONT =
  'zola/vendorAccount/SETTING_VENDOR_CURRENT_STOREFRONT';

export type VendorAccountActionPayload = Omit<VendorAccountState, 'isUserContextLoaded' | 'error'>;

export type RequestingVendorSignupAction = Action<typeof REQUESTING_VENDOR_SIGNUP>;
export type ReceivedVendorSignupAction = PayloadAction<
  VendorAccountActionPayload,
  typeof RECEIVED_VENDOR_SIGNUP
>;

export type ReceivedVendorSignupErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_SIGNUP_ERROR
>;

export type RequestingVendorSignupConfirmationAction = Action<
  typeof REQUESTING_VENDOR_SIGNUP_CONFIRMATION
>;
export type ReceivedVendorSignupConfirmationAction = Action<
  typeof RECEIVED_VENDOR_SIGNUP_CONFIRMATION
>;
export type ReceivedVendorSignupConfirmationErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_SIGNUP_CONFIRMATION_ERROR
>;

export type RequestingVendorSignupCodeAction = Action<typeof REQUESTING_VENDOR_SIGNUP_CODE>;
export type ReceivedVendorSignupCodeAction = Action<typeof RECEIVED_VENDOR_SIGNUP_CODE>;
export type ReceivedVendorSignupCodeErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_SIGNUP_CODE_ERROR
>;

export type RequestingVendorLoginAction = Action<typeof REQUESTING_VENDOR_LOGIN>;
export type ReceivedVendorLoginAction = PayloadAction<
  VendorAccountActionPayload,
  typeof RECEIVED_VENDOR_LOGIN
>;
export type ReceivedVendorLoginErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_LOGIN_ERROR
>;

export type RequestingVendorPasswordChangeAction = Action<typeof REQUESTING_VENDOR_PASSWORD_CHANGE>;
export type ReceivedVendorPasswordChangeAction = Action<typeof RECEIVED_VENDOR_PASSWORD_CHANGE>;
export type ReceivedVendorPasswordChangeErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_PASSWORD_CHANGE_ERROR
>;

export type RequestingVendorPasswordResetCodeAction = Action<
  typeof REQUESTING_VENDOR_PASSWORD_RESET_CODE
>;
export type ReceivedVendorPasswordResetCodeAction = Action<
  typeof RECEIVED_VENDOR_PASSWORD_RESET_CODE
>;
export type ReceivedVendorPasswordResetCodeErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_PASSWORD_RESET_CODE_ERROR
>;

type RequestingVendorPasswordResetAction = Action<typeof REQUESTING_VENDOR_PASSWORD_RESET>;
type ReceivedVendorPasswordResetAction = Action<typeof RECEIVED_VENDOR_PASSWORD_RESET>;
type ReceivedVendorPasswordResetErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_PASSWORD_RESET_ERROR
>;

export type RequestingVendorCurrentUserAction = Action<typeof REQUESTING_VENDOR_CURRENT_USER>;
export type ReceivedVendorCurrentUserAction = PayloadAction<
  VendorAccountActionPayload,
  typeof RECEIVED_VENDOR_CURRENT_USER
>;
export type ReceivedVendorCurrentUserErrorAction = PayloadAction<
  { error: Error },
  typeof RECEIVED_VENDOR_CURRENT_USER_ERROR
>;

export type RequestingVendorLogoutAction = Action<typeof REQUESTING_VENDOR_LOGOUT>;

export type ClearingVendorAccountErrorAction = Action<typeof CLEARING_VENDOR_ACCOUNT_ERROR>;

export type SettingVendorCurrentStorefrontAction = PayloadAction<
  { currentStorefrontUuid: string },
  typeof SETTING_VENDOR_CURRENT_STOREFRONT
>;

export type VendorAccountActions =
  | RequestingVendorSignupAction
  | ReceivedVendorSignupAction
  | ReceivedVendorSignupErrorAction
  | RequestingVendorSignupConfirmationAction
  | ReceivedVendorSignupConfirmationAction
  | ReceivedVendorSignupConfirmationErrorAction
  | RequestingVendorSignupCodeAction
  | ReceivedVendorSignupCodeAction
  | ReceivedVendorSignupCodeErrorAction
  | RequestingVendorLoginAction
  | ReceivedVendorLoginAction
  | ReceivedVendorLoginErrorAction
  | RequestingVendorPasswordChangeAction
  | ReceivedVendorPasswordChangeAction
  | ReceivedVendorPasswordChangeErrorAction
  | RequestingVendorPasswordResetCodeAction
  | ReceivedVendorPasswordResetCodeAction
  | ReceivedVendorPasswordResetCodeErrorAction
  | RequestingVendorPasswordResetAction
  | ReceivedVendorPasswordResetAction
  | ReceivedVendorPasswordResetErrorAction
  | RequestingVendorCurrentUserAction
  | ReceivedVendorCurrentUserAction
  | ReceivedVendorCurrentUserErrorAction
  | RequestingVendorLogoutAction
  | ClearingVendorAccountErrorAction
  | SettingVendorCurrentStorefrontAction;

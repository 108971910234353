import { StorefrontActivePromoView } from '@zola/svc-marketplace-ts-types';

import { Action } from 'redux';

import { receivedStorefrontPromoCodes } from '~/actions/vendors/types/vendorCreditActionTypes';

export interface PromoState {
  byStorefrontUuid: Record<string, StorefrontActivePromoView[]>;
}

export const initialState: PromoState = {
  byStorefrontUuid: {},
};

const promosReducer = (state = initialState, action?: Action): PromoState => {
  if (!action) {
    return state;
  }

  if (receivedStorefrontPromoCodes.match(action)) {
    const { storefrontUuid, promos } = action.payload;
    return {
      ...state,
      byStorefrontUuid: {
        ...state.byStorefrontUuid,
        [storefrontUuid]: promos,
      },
    };
  }

  return state;
};

export default promosReducer;

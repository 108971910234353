import { PayloadAction } from '@reduxjs/toolkit';

import { WeddingFacetType } from '~/types/weddings.d';

export const REQUEST_WEDDING_FACETS = 'zola/weddings/v2/REQUEST_WEDDING_FACETS';
export const RECEIVED_WEDDING_FACETS = 'zola/weddings/v2/RECEIVED_WEDDING_FACETS';

export type RequestWeddingFacetsAction = PayloadAction<
  Record<string, never>,
  typeof REQUEST_WEDDING_FACETS
>;
export type ReceivedWeddingFacetsAction = PayloadAction<
  WeddingFacetType[],
  typeof RECEIVED_WEDDING_FACETS
>;

export type WeddingFacetsActions = RequestWeddingFacetsAction | ReceivedWeddingFacetsAction;

import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import type { AccountVendorView } from '~/types/responseTypes';

export const REQUESTING_BOOKED_VENDORS = 'zola/bookedVendors/REQUESTING_BOOKED_VENDORS';
export const RECEIVED_BOOKED_VENDORS = 'zola/bookedVendors/RECEIVED_BOOKED_VENDORS';
export const REQUESTING_CREATE_BOOKED_VENDOR = 'zola/bookedVendors/REQUESTING_CREATE_BOOKED_VENDOR';
export const RECEIVED_CREATE_BOOKED_VENDOR = 'zola/bookedVendors/RECEIVED_CREATE_BOOKED_VENDOR';
export const SENDING_REMOVE_VENDOR_FEEDBACK = 'zola/bookedVendors/SENDING_REMOVE_VENDOR_FEEDBACK';
export const SENT_REMOVE_VENDOR_FEEDBACK = 'zola/bookedVendors/SENT_REMOVE_VENDOR_FEEDBACK';
export const SENDING_UNBOOK_VENDOR_CATEGORY = 'zola/bookedVendors/SENDING_UNBOOK_VENDOR_CATEGORY';
export const SENT_UNBOOK_VENDOR_CATEGORY = 'zola/bookedVendors/SENT_UNBOOK_VENDOR_CATEGORY';
const UPDATING_BOOKING = 'zola/bookedVendors/UPDATING_BOOKING';
export const UPDATING_BOOKED_VENDORS = 'zola/bookedVendors/UPDATING_BOOKED_VENDORS';

export type RequestingCreateBookedVendorAction = Action<typeof REQUESTING_CREATE_BOOKED_VENDOR>;

export type ReceivedCreateBookedVendorAction = PayloadAction<
  AccountVendorView,
  typeof RECEIVED_CREATE_BOOKED_VENDOR
>;

export type RequestingBookedVendorsAction = Action<typeof REQUESTING_BOOKED_VENDORS>;

export type ReceivedBookedVendorsAction = PayloadAction<
  AccountVendorView[],
  typeof RECEIVED_BOOKED_VENDORS
>;

export type SendingRemoveVendorFeedbackAction = Action<typeof SENDING_REMOVE_VENDOR_FEEDBACK>;

export type SentRemoveVendorFeedbackAction = PayloadAction<
  AccountVendorView,
  typeof SENT_REMOVE_VENDOR_FEEDBACK
>;

export type SendingUnbookVendorCategoryAction = Action<typeof SENDING_UNBOOK_VENDOR_CATEGORY>;
export type SentUnbookVendorCategoryAction = PayloadAction<
  AccountVendorView,
  typeof SENT_UNBOOK_VENDOR_CATEGORY
>;

type UpdatingBookingAction = Action<typeof UPDATING_BOOKING>;

type UpdatingBookedVendorsAction = Action<typeof UPDATING_BOOKED_VENDORS>;

export type BookedVendorActions =
  | RequestingCreateBookedVendorAction
  | ReceivedCreateBookedVendorAction
  | RequestingBookedVendorsAction
  | ReceivedBookedVendorsAction
  | SendingRemoveVendorFeedbackAction
  | SentRemoveVendorFeedbackAction
  | SendingUnbookVendorCategoryAction
  | SentUnbookVendorCategoryAction
  | UpdatingBookingAction
  | UpdatingBookedVendorsAction;

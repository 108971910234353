export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  CONFIRMATION = 'confirmation',
}

export interface MarketplaceNotification {
  id: number;
  message: string | null;
  type: NotificationType | null;
  autoDismiss: number;
  showCheck?: boolean;
  link?: {
    label: string;
    path: string;
  };
}

export const NULL_NOTIFICATION: Omit<MarketplaceNotification, 'id'> = {
  message: null,
  type: null,
  autoDismiss: 5, // seconds
};

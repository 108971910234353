import { AccountVendorPreferencesView } from '~/types/responseTypes';
import { createEntity, setEntityObject, setEntityObjects, EntitySlice } from '~/util/reducerUtils';

import {
  REQUESTING_VENDOR_PREFERENCES,
  RECEIVED_VENDOR_PREFERENCES,
  UPDATED_VENDOR_PREFERENCES,
  VendorPreferenceActionType,
} from '../../actions/types/accountPreferenceActionTypes';

export interface VendorPreferencesState extends EntitySlice<AccountVendorPreferencesView, number> {
  loaded: boolean;
}

export const initialState: VendorPreferencesState = {
  ...createEntity(),
  loaded: false,
};

const vendorPreferences = (
  state = initialState,
  action: VendorPreferenceActionType
): VendorPreferencesState => {
  switch (action.type) {
    case REQUESTING_VENDOR_PREFERENCES:
      return { ...state, loaded: false };
    case RECEIVED_VENDOR_PREFERENCES: {
      const { payload } = action;
      return {
        ...setEntityObjects<AccountVendorPreferencesView, number>(state, payload, 'taxonomyNodeId'),
        loaded: true,
      };
    }
    case UPDATED_VENDOR_PREFERENCES: {
      const { payload } = action;
      return {
        ...setEntityObject<AccountVendorPreferencesView, number>(state, payload, 'taxonomyNodeId'),
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export default vendorPreferences;

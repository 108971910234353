import { Action, PayloadAction } from '@reduxjs/toolkit';

import { MappedOptionFacetView, TaxonomyNodeView } from '~/types/responseTypes';

export const REQUESTING_TAXONOMY = 'zola/taxonomy/REQUESTING_TAXONOMY';
export const RECEIVED_TAXONOMY = 'zola/taxonomy/RECEIVED_TAXONOMY';

export const REQUESTING_FACETS = 'zola/taxonomy/REQUESTING_FACETS';
export const RECEIVED_FACETS = 'zola/taxonomy/RECEIVED_FACETS';

export type RequestingTaxonomyAction = Action<typeof REQUESTING_TAXONOMY>;

export type ReceivedTaxonomyAction = PayloadAction<TaxonomyNodeView[], typeof RECEIVED_TAXONOMY>;
export interface RequestingFacetsAction extends Action<typeof REQUESTING_FACETS> {
  key?: string;
}
export interface ReceivedFacetsAction
  extends PayloadAction<MappedOptionFacetView[], typeof RECEIVED_FACETS> {
  key?: string;
}

export type TaxonomyActions =
  | RequestingTaxonomyAction
  | ReceivedTaxonomyAction
  | RequestingFacetsAction
  | ReceivedFacetsAction;

import type { ImageCategories } from '~/actions/types/imageUploadActionTypes';
import type { ImageReducerSlice, ImageReducerState } from '~/reducers/imagesReducer';
import type { VendorStorefrontImageState } from '~/reducers/vendorStorefrontImagesReducer';

export function updateState(
  state: VendorStorefrontImageState,
  payload: Partial<VendorStorefrontImageState>
): VendorStorefrontImageState;
export function updateState(
  state: ImageReducerState,
  payload: Partial<ImageReducerState>
): ImageReducerState;

/* Update section state
 * tempImages: { sectionKey: { 0: {...}, totalPhotos: 1 } }
 */
export function updateState(
  state: VendorStorefrontImageState | ImageReducerState,
  payload: Partial<VendorStorefrontImageState> | Partial<ImageReducerState>
): VendorStorefrontImageState | ImageReducerState {
  return (Object.keys(payload) as ImageCategories[]).map((sectionKey) => {
    if (state[sectionKey]) {
      // Merges together an existing section
      return {
        [sectionKey]: { ...state[sectionKey], ...payload[sectionKey] },
      };
    }
    // Returns the new section
    return payload;
  })[0];
}

/* Initialize section counts
 * tempImages: { sectionKey: { totalPhotos: 2, completedCount: 0, erroredCount: 0 } }
 */
export const replaceState = (
  state: ImageReducerState,
  payload: Partial<ImageReducerState>
): ImageReducerState =>
  (Object.keys(payload) as ImageCategories[]).map((sectionKey) => {
    if (state[sectionKey]) {
      return {
        [sectionKey]: {
          completedCount: 0,
          erroredCount: 0,
          ...payload[sectionKey],
        },
      };
    }
    return payload;
  })[0];

/* Update completed and errored counts
 * tempImages: { sectionKey: { 0: {...}, totalPhotos: 2, completedCount: 1, erroredCount: 0 } }
 */
export const updateCount = (
  state: ImageReducerState,
  sectionKey: ImageCategories,
  key: 'completedCount' | 'erroredCount'
): ImageReducerState => {
  let currentCount = 0;
  const currentSlice: ImageReducerSlice | undefined = state[sectionKey];
  if (currentSlice) {
    currentCount = currentSlice[key] || 0;
  }

  return {
    [sectionKey]: {
      [key]: currentCount + 1,
    },
  };
};

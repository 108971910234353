import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';

import { VendorTypeCountKey, BoardStorefrontView } from '~/types/responseTypes';
import type { PartialRecord } from '~/types/utils';
import {
  createEntity,
  setEntityObjects,
  setEntityObject,
  deleteEntityObject,
  EntitySlice,
} from '~/util/reducerUtils';

import {
  CREATED_FAVORITE_PHOTO,
  CREATED_FAVORITE_STOREFRONT,
  DELETED_FAVORITE_PHOTO,
  DELETED_FAVORITE_STOREFRONT,
  FavoritePhotoActionType,
  FavoritePhotoPayload,
  FavoriteStorefrontActionType,
  FavoriteStorefrontPayload,
  RECEIVED_FAVORITES,
} from '../../actions/types/favoriteActionTypes';

export interface FavoriteState<T> extends EntitySlice<T> {
  boards: string[];
  /** Lookup of boardId => favorites in that board */
  byBoard: Record<string, string[]>;
  loaded: boolean;
}
export type FavoritePhotosState = FavoriteState<FavoritePhotoPayload & { uuid: string }>;

const favoritePhotosInitialState: FavoritePhotosState = {
  ...createEntity<FavoritePhotoPayload & { uuid: string }>(),
  boards: [
    // Array of Board UUID
    // First version just has one board, the favorites board
    // Later versions might let them arrange favorites on
    // different boards and create their own boards
  ],
  byBoard: {
    // boardUuid => Array of favorite UUID
  },
  loaded: false,
};

export interface FavoriteStorefrontsState
  extends FavoriteState<FavoriteStorefrontPayload & { uuid: string }> {
  vendorTypeCounts: PartialRecord<VendorTypeCountKey, number>;
  favoriteStorefronts: BoardStorefrontView[];
}

const favoriteStorefrontsInitialState: FavoriteStorefrontsState = {
  ...createEntity<FavoriteStorefrontPayload & { uuid: string }>(),
  boards: [
    // Array of Board UUID
    // First version just has one board, the favorites board
    // Later versions might let them arrange favorites on
    // different boards and create their own boards
  ],
  byBoard: {
    // boardUuid => Array of favorite UUID
  },
  loaded: false,
  vendorTypeCounts: {
    // count of favorites by vendor type
  },
  favoriteStorefronts: [],
};

const favoritePhotosReducer = (
  state = favoritePhotosInitialState,
  action: FavoritePhotoActionType
): FavoritePhotosState => {
  switch (action.type) {
    case RECEIVED_FAVORITES: {
      const { payload } = action;
      const { favoritePhotos = [], uuid: boardUuid } = payload;

      const newState: FavoritePhotosState = {
        ...state,
        boards: _uniq(state.boards.concat([boardUuid])),
        byBoard: {
          ...state.byBoard,
          [boardUuid]: favoritePhotos.map((f) => f.imageId),
        },
        loaded: true,
      };

      return setEntityObjects<FavoritePhotoPayload, string, FavoritePhotosState>(
        newState,
        favoritePhotos,
        'imageId'
      );
    }
    case CREATED_FAVORITE_PHOTO:
      return setEntityObject(state, action.payload, 'imageId');
    case DELETED_FAVORITE_PHOTO:
      return deleteEntityObject(state, action.payload, 'imageId');
    default:
      return state;
  }
};

const favoriteStorefrontsReducer = (
  state = favoriteStorefrontsInitialState,
  action: FavoriteStorefrontActionType
): FavoriteStorefrontsState => {
  switch (action.type) {
    case RECEIVED_FAVORITES: {
      const { payload } = action;
      const { favoriteStorefronts = [], uuid: boardUuid, vendorTypeCounts = {} } = payload;

      const newState: FavoriteStorefrontsState = {
        ...state,
        loaded: true,
        vendorTypeCounts,
        boards: _uniq(state.boards.concat([boardUuid])),
        byBoard: {
          ...state.byBoard,
          [boardUuid]: favoriteStorefronts.map((f) => f.storefrontUuid),
        },
        favoriteStorefronts,
      };
      return setEntityObjects<FavoriteStorefrontPayload, string, FavoriteStorefrontsState>(
        newState,
        favoriteStorefronts,
        'storefrontUuid'
      );
    }
    case CREATED_FAVORITE_STOREFRONT:
      return setEntityObject(state, action.payload, 'storefrontUuid');
    case DELETED_FAVORITE_STOREFRONT:
      return deleteEntityObject(state, action.payload, 'storefrontUuid');
    default: {
      return state;
    }
  }
};

const favorites = combineReducers({
  'wedding-photos': favoritePhotosReducer,
  'storefront-listings': favoriteStorefrontsReducer,
});

export default favorites;

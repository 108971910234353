import { CatererStoryGalleryView } from '@zola/svc-marketplace-ts-types';

import _omit from 'lodash/omit';
import { Action } from 'redux';

import {
  createdCatererStory,
  deletedCatererStory,
  reorderedCatererStories,
  reorderingCatererStories,
  updatedCatererStory,
  updatedStoryOptions,
} from '~/actions/vendors/types/vendorStorefrontActionTypes';
import { savedCatererStoryPhotos } from '~/actions/vendors/types/vendorStorefrontPhotoActionTypes';
import { CatererStoryView, EntityFacetView } from '~/types/responseTypes';
import {
  deleteEntityObject,
  EntitySlice,
  setEntityObject,
  setEntityObjects,
} from '~/util/reducerUtils';

import {
  receivedCatererStories,
  receivedStoryCovers,
  receivedStoryOptions,
} from '../../actions/types/catererStoryActionTypes';

export interface OrderedCatererStoryView extends CatererStoryView {
  displayOrder: number;
}
export interface CatererStoryState extends EntitySlice<OrderedCatererStoryView, string> {
  byCaterer: { [catererUuid: string]: string[] };
  optionsById: { [storyUuid: string]: EntityFacetView[] };
  coverPhotosById: { [storyUuid: string]: CatererStoryGalleryView[] };
}

export const initialState: CatererStoryState = {
  byId: {},
  allIds: [],
  byCaterer: {},
  optionsById: {},
  coverPhotosById: {},
};

const catererStoriesReducer = (state = initialState, action?: Action): CatererStoryState => {
  if (!action) {
    return state;
  }

  /** Update the display order right away when requested so its immediately reflected */
  if (reorderingCatererStories.match(action)) {
    const byId = { ...state.byId };
    action.payload.forEach(({ uuid, displayOrder }) => {
      const existingStory = byId[uuid];
      if (existingStory) {
        byId[uuid] = { ...existingStory, displayOrder };
      }
    });
    return {
      ...state,
      byId,
    };
  }

  if (createdCatererStory.match(action)) {
    const story = action.payload;

    const existingStories = state.byCaterer[story.catererUuid] || [];
    const maxDisplayOrder = existingStories.reduce((max, storyUuid) => {
      const existingStory = state.byId[storyUuid];
      if (existingStory && existingStory.displayOrder > max) {
        return existingStory.displayOrder;
      }
      return max;
    }, 0);

    const newState = setEntityObject<OrderedCatererStoryView, string, CatererStoryState>(
      state,
      {
        ...story,
        displayOrder: maxDisplayOrder + 1,
      },
      'uuid'
    );

    return {
      ...newState,
      byCaterer: {
        ...newState.byCaterer,
        [story.catererUuid]: [...existingStories, story.uuid],
      },
    };
  }

  if (receivedCatererStories.match(action) || reorderedCatererStories.match(action)) {
    const { stories, catererUuid } = action.payload;
    const orderedStories: OrderedCatererStoryView[] = stories.map((story, index) => ({
      ...story,
      displayOrder: index + 1,
    }));

    const newState = setEntityObjects<OrderedCatererStoryView, string, CatererStoryState>(
      state,
      orderedStories,
      'uuid'
    );

    return {
      ...newState,
      byCaterer: {
        ...state.byCaterer,
        [catererUuid]: orderedStories.map((s) => s.uuid),
      },
    };
  }

  if (updatedCatererStory.match(action)) {
    const existing = state.byId[action.payload.uuid];

    if (existing) {
      return setEntityObject<OrderedCatererStoryView, string, CatererStoryState>(
        state,
        {
          ...action.payload,
          displayOrder: existing.displayOrder,
        },
        'uuid'
      );
    }
    return state;
  }

  if (deletedCatererStory.match(action)) {
    const newState = deleteEntityObject<OrderedCatererStoryView, string, CatererStoryState>(
      state,
      {
        ...action.payload,
        displayOrder: -1, // display order doesn't matter
      },
      'uuid'
    );
    const { catererUuid } = action.payload;
    const current = newState.byCaterer[catererUuid];

    const storyUuid = action.payload.uuid;

    return {
      ...newState,
      byCaterer: {
        ...newState.byCaterer,
        // keep everything except the one that was deleted
        [catererUuid]: current.filter((uuid) => uuid !== storyUuid),
      },
      optionsById: _omit(state.optionsById, [storyUuid]),
      coverPhotosById: _omit(state.coverPhotosById, [storyUuid]),
    };
  }

  if (receivedStoryOptions.match(action) || updatedStoryOptions.match(action)) {
    const { storyUuid, options } = action.payload;

    return {
      ...state,
      optionsById: {
        ...state.optionsById,
        [storyUuid]: options,
      },
    };
  }

  if (receivedStoryCovers.match(action) || savedCatererStoryPhotos.match(action)) {
    const { storyUuid, covers } = action.payload;
    return {
      ...state,
      coverPhotosById: {
        ...state.coverPhotosById,
        [storyUuid]: covers,
      },
    };
  }

  return state;
};

export default catererStoriesReducer;

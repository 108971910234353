import {
  NotificationType,
  NULL_NOTIFICATION,
  MarketplaceNotification,
} from '~/components/common/notifications/constants';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  ShowNotificationAction,
  HideNotificationAction,
  ClearNotificationsAction,
} from './types/notificationActionTypes';

export const show = (
  opts: Partial<MarketplaceNotification> = NULL_NOTIFICATION,
  type: NotificationType = NotificationType.SUCCESS
): ShowNotificationAction => {
  return {
    type: SHOW_NOTIFICATION,
    payload: { ...NULL_NOTIFICATION, ...opts, id: new Date().valueOf(), type },
  };
};

export const success = (opts: Partial<MarketplaceNotification>): ShowNotificationAction => {
  return show(opts, NotificationType.SUCCESS);
};

export const error = (opts: Partial<MarketplaceNotification>): ShowNotificationAction => {
  return show(opts, NotificationType.ERROR);
};

export const warning = (opts: Partial<MarketplaceNotification>): ShowNotificationAction => {
  return show(opts, NotificationType.WARNING);
};

export const info = (opts: Partial<MarketplaceNotification>): ShowNotificationAction => {
  return show(opts, NotificationType.INFO);
};

export const confirmation = (opts: Partial<MarketplaceNotification>): ShowNotificationAction => {
  return show({ ...opts, autoDismiss: 5 }, NotificationType.CONFIRMATION);
};

export const hide = (id: number): HideNotificationAction => {
  return {
    type: HIDE_NOTIFICATION,
    payload: id,
  };
};

export const clear = (): ClearNotificationsAction => {
  return { type: CLEAR_NOTIFICATIONS };
};

import { CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import { Action } from 'redux';

import {
  receivedStorefrontCreditBalance,
  receivedUserCreditBalances,
} from '~/actions/vendors/types/vendorCreditActionTypes';

export interface CreditState {
  byStorefrontUuid: Record<string, CreditBalanceView>;
}

export const initialState: CreditState = {
  byStorefrontUuid: {},
};

const creditsReducer = (state = initialState, action?: Action): CreditState => {
  if (!action) {
    return state;
  }
  if (receivedStorefrontCreditBalance.match(action)) {
    const { payload } = action;
    const { storefrontUuid } = payload;

    return {
      ...state,
      byStorefrontUuid: {
        ...state.byStorefrontUuid,
        [storefrontUuid]: payload,
      },
    };
  }
  if (receivedUserCreditBalances.match(action)) {
    const { payload } = action;

    return {
      ...state,
      byStorefrontUuid: payload.reduce((newByStorefrontUuid, creditBalanceForStorefront) => {
        const { storefrontUuid } = creditBalanceForStorefront;
        return {
          ...newByStorefrontUuid,
          [storefrontUuid]: creditBalanceForStorefront,
        };
      }, {} as Record<string, CreditBalanceView>),
    };
  }

  return state;
};

export default creditsReducer;

import type { BeauticianDetails } from '~/types/responseTypes';

export const REQUESTING_BEAUTICIAN_DETAILS_BY_STOREFRONT =
  'zola/beautician/REQUESTING_BEAUTICIAN_DETAILS_BY_STOREFRONT';
export const RECEIVED_BEAUTICIAN_DETAILS = 'zola/beautician/RECEIVED_BEAUTICIAN_DETAILS';

export const UPDATING_BEAUTICIAN_DETAILS = 'zola/beautician/UPDATING_BEAUTICIAN_DETAILS';
export const UPDATED_BEAUTICIAN_DETAILS = 'zola/beautician/UPDATED_BEAUTICIAN_DETAILS';

export interface RequestingBeauticianDetailsForStorefrontAction {
  type: typeof REQUESTING_BEAUTICIAN_DETAILS_BY_STOREFRONT;
}

export interface ReceivedBeauticianDetailsAction {
  type: typeof RECEIVED_BEAUTICIAN_DETAILS;
  payload: BeauticianDetails;
}

export interface UpdatingBeauticianDetailsAction {
  type: typeof UPDATING_BEAUTICIAN_DETAILS;
}

export interface UpdatedBeauticianDetailsAction {
  type: typeof UPDATED_BEAUTICIAN_DETAILS;
  payload: BeauticianDetails;
}

export type BeauticianActionType =
  | RequestingBeauticianDetailsForStorefrontAction
  | ReceivedBeauticianDetailsAction
  | UpdatingBeauticianDetailsAction
  | UpdatedBeauticianDetailsAction;

import * as toastsActions from '@zola/zola-ui/src/actions/toastsV2Actions';
import { ToastsV2 } from '@zola/zola-ui/src/components/ToastsV2';

import { connect } from 'react-redux';

import { AppDispatch, RootState } from '~/reducers';

const mapStateToProps = (state: RootState) => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onRemoveToast: (id: number) => dispatch(toastsActions.hide(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastsV2);

import {
  addDays,
  formatDateUtc,
  getDateUtc,
  isAfterDate,
  isValidDate,
  parseDateUtc,
} from '~/util/dateUtils';

// example use cases in dateFunctions.spec.js

export const spreadWeddingDate = (
  weddingDate: string | null | undefined | number,
  weddingYearMonthDate: string | null | undefined | number
) => {
  const date = weddingDate || weddingYearMonthDate;
  if (date) {
    return {
      weddingYear: formatDateUtc(date, 'YYYY'),
      weddingMonth: formatDateUtc(date, 'MM'),
      weddingDay: weddingDate ? Number(formatDateUtc(date, 'D')) : undefined, // this is an integer instead of a string because `Dates` array in dates.js includes integers
    };
  }

  return {};
};

export const isWeddingDateInTheFuture = (
  weddingDate?: string | null,
  weddingYearMonthDate?: string | null
) => {
  const format = 'YYYY-MM-DD';
  const today = getDateUtc();
  const yesterday = addDays(today, -1);
  if (weddingDate) {
    // test against yesterday to allow today to be a valid default value
    const date = parseDateUtc(weddingDate, format);
    return isValidDate(date) && isAfterDate(date, yesterday);
  }
  if (weddingYearMonthDate) {
    // ensure only months preceding this one count as invalid
    const date = parseDateUtc(weddingYearMonthDate, format);
    return isValidDate(date) && formatDateUtc(date, 'YYYY-MM') > formatDateUtc(today, 'YYYY-MM');
  }
  return false;
};

export const formatDate = (dateString?: string) => {
  if (!dateString) return null;
  if (dateString.indexOf('undefined') > -1 || dateString.indexOf('null') > -1) return null;
  const date = parseDateUtc(dateString, 'YYYY-M-D');
  return isValidDate(date) ? formatDateUtc(date, 'YYYY-MM-DD[T]HH:mm:ss[Z]') : null;
};

export const formatWeddingYearMonthDate = (
  weddingYear?: string | null,
  weddingMonth?: string | null
) => {
  return weddingYear && weddingMonth ? formatDate(`${weddingYear}-${weddingMonth}-1`) : null;
};

export const formatWeddingDates = (
  weddingYear?: string | null,
  weddingMonth?: string | null,
  weddingDay?: string | number | null
) => {
  const date = formatDate(`${weddingYear}-${weddingMonth}-${weddingDay}`);
  const weddingYearMonthDate = date ? null : formatWeddingYearMonthDate(weddingYear, weddingMonth);

  return [date, weddingYearMonthDate];
};

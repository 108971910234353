import { VideographerDetails } from '~/types/responseTypes';

export const REQUESTING_VIDEOGRAPHER_DETAILS_BY_STOREFRONT =
  'zola/videographer/REQUESTING_VIDEOGRAPHER_DETAILS_BY_STOREFRONT';
export const RECEIVED_VIDEOGRAPHER_DETAILS = 'zola/videographer/RECEIVED_VIDEOGRAPHER_DETAILS';

export const UPDATING_VIDEOGRAPHER_DETAILS = 'zola/videographer/UPDATING_VIDEOGRAPHER_DETAILS';
export const UPDATED_VIDEOGRAPHER_DETAILS = 'zola/videographer/UPDATED_VIDEOGRAPHER_DETAILS';
export interface RequestingVideographerDetailsByStorefrontAction {
  type: typeof REQUESTING_VIDEOGRAPHER_DETAILS_BY_STOREFRONT;
}
export interface ReceivedVideographerDetailsAction {
  type: typeof RECEIVED_VIDEOGRAPHER_DETAILS;
  payload: VideographerDetails;
}
export interface UpdatingVideographerDetailsAction {
  type: typeof UPDATING_VIDEOGRAPHER_DETAILS;
}
export interface UpdatedVideographerDetailsAction {
  type: typeof UPDATED_VIDEOGRAPHER_DETAILS;
  payload: VideographerDetails;
}

export type VideographerActionType =
  | RequestingVideographerDetailsByStorefrontAction
  | ReceivedVideographerDetailsAction
  | UpdatingVideographerDetailsAction
  | UpdatedVideographerDetailsAction;

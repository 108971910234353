/* eslint-disable no-param-reassign */

import {
  RECEIVED_VENDOR_USER_CONTEXT,
  VendorUserContextActionTypes,
} from '~/actions/types/vendorUserContextActionTypes';
import { IUserContext } from '~/types/userContext';

export interface VendorUserContextState {
  vendorUserContext: IUserContext | null;
  loaded: boolean;
}

export const initialState: VendorUserContextState = {
  vendorUserContext: null,
  loaded: false,
};

const vendorUserContextReducer = (
  state: VendorUserContextState = initialState,
  action: VendorUserContextActionTypes
): VendorUserContextState => {
  switch (action.type) {
    case RECEIVED_VENDOR_USER_CONTEXT: {
      return { ...state, vendorUserContext: action.payload, loaded: true };
    }
    default:
      return state;
  }
};

export default vendorUserContextReducer;

import { Action } from 'redux';

import {
  receivedWeddingPlannerDetails,
  receivedWeddingPlannerPackage,
  updatedWeddingPlannerDetails,
} from '~/actions/types/weddingPlannerActionTypes';
import { WeddingPlannerDetails } from '~/types/responseTypes';
import { setStorefrontEntityObject, StorefrontEntityState } from '~/util/reducerUtils';

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: StorefrontEntityState<WeddingPlannerDetails> = {
  // Look up a wedding planners by id
  byId: {},

  // all the IDs of all the wedding planners we've loaded
  allIds: [],

  // Map storefront ids to wedding planners ids
  storefrontToId: {},
};

const weddingPlannerReducer = (
  state = initialState,
  action: Action
): StorefrontEntityState<WeddingPlannerDetails> => {
  if (!action) {
    return state;
  }

  if (receivedWeddingPlannerDetails.match(action) || updatedWeddingPlannerDetails.match(action)) {
    return setStorefrontEntityObject(state, action.payload);
  }

  if (receivedWeddingPlannerPackage.match(action)) {
    const newWeddingPlannerDetails = { ...state.byId[action.payload.weddingPlannerId] };

    const existingPackage = newWeddingPlannerDetails.packages.findIndex(
      ({ id }) => action.payload.id === id
    );

    // For updating packages
    if (existingPackage >= 0) {
      newWeddingPlannerDetails.packages[existingPackage] = action.payload;
    } else {
      // For create packages
      newWeddingPlannerDetails.packages.push(action.payload);
    }

    return setStorefrontEntityObject(state, newWeddingPlannerDetails);
  }

  return state;
};

export default weddingPlannerReducer;

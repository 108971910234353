import { VendorTypeEnum } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import {
  InquiryPreferencesActions,
  RECEIVED_INQUIRY_PREFERENCES,
  RECEIVED_INQUIRY_SERVICES,
} from '~/actions/types/inquiryActionTypes';
import { MappedInquiryPreferencesView, InquiryServicesFacet } from '~/types/responseTypes';

type ServicesData = {
  [key: string]: {
    services: InquiryServicesFacet[];
    loaded: boolean;
  };
};

export interface InquiryPreferencesState {
  preferences: undefined | MappedInquiryPreferencesView;
  services: undefined | ServicesData;
  byVendorType: {
    [vendorType in VendorTypeEnum]?: {
      loaded: boolean;
      preferences: MappedInquiryPreferencesView | undefined;
    };
  };
}

const initialState: InquiryPreferencesState = {
  preferences: undefined,
  services: undefined,
  byVendorType: {},
};

const inquiryReducer = (
  state: InquiryPreferencesState = initialState,
  action?: InquiryPreferencesActions
): InquiryPreferencesState => {
  switch (action?.type) {
    case RECEIVED_INQUIRY_PREFERENCES: {
      const { preferences, vendorType } = action.payload;
      const byVendorType = { ...state.byVendorType, [vendorType]: { preferences, loaded: true } };
      return { ...state, preferences, byVendorType };
    }
    case RECEIVED_INQUIRY_SERVICES: {
      const { services: inquiryServices, uuid } = action.payload;
      return { ...state, services: { [uuid]: { services: inquiryServices, loaded: true } } };
    }
    default:
      return state;
  }
};

export default inquiryReducer;

import { Component } from 'react';

import CSSTransition, { CSSTransitionProps } from 'react-transition-group/CSSTransition';

import { MarketplaceNotification } from './constants';

type NotificationProps<Ref extends undefined | HTMLElement = undefined> = Partial<
  CSSTransitionProps<Ref>
> & {
  notification: MarketplaceNotification;
  onRemove: (id: number, timeoutMs: number) => void;
};

class Notification extends Component<NotificationProps> {
  componentDidMount() {
    const { notification, onRemove } = this.props;
    const { autoDismiss } = notification;
    onRemove(notification.id, autoDismiss * 1000);
  }

  render() {
    const {
      notification: { id, type, message, link, showCheck },
      onRemove: _onRemove,
      ...rest
    } = this.props;

    return (
      <CSSTransition classNames="humane-zola" {...rest} timeout={600}>
        <div id={`notification-${id}`} className={`humane-zola humane-zola-${type}`}>
          <div>
            {showCheck ? <span className="zolaicon zola-ui-icon-checkmark" /> : null}
            {message}
            {link ? <a href={link.path}>{link.label}</a> : null}
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default Notification;

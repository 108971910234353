import _omit from 'lodash/omit';
import { Action } from 'redux';

import { MappedGalleryPhotoView, MappedOptionFacetView } from '~/types/responseTypes';

import { receivedSpaceGallery, receivedSpaceOptions } from '../actions/types/spacesActionTypes';
import {
  deletedSpace,
  updatedSpaceOption,
} from '../actions/vendors/types/vendorStorefrontActionTypes';
import { spacePhotosSaved } from '../actions/vendors/types/vendorStorefrontPhotoActionTypes';

export type SpacesState = {
  optionsByUuid: Record<string, MappedOptionFacetView[]>;
  galleryByUuid: Record<string, MappedGalleryPhotoView[]>;
};

const initialState: SpacesState = {
  // Given a space uuid, get the options for that space
  optionsByUuid: {},
  // Given a space uuid, get the gallery for that space
  galleryByUuid: {},
};

const spacesReducer = (state = initialState, action?: Action): SpacesState => {
  if (!action) {
    return state;
  }
  if (receivedSpaceOptions.match(action)) {
    const { spaceUuid, options } = action.payload;

    return {
      ...state,
      optionsByUuid: { ...state.optionsByUuid, [spaceUuid]: options },
    };
  }
  if (receivedSpaceGallery.match(action)) {
    const { spaceUuid, gallery } = action.payload;

    return {
      ...state,
      galleryByUuid: { ...state.galleryByUuid, [spaceUuid]: gallery },
    };
  }

  if (deletedSpace.match(action)) {
    const spaceUuid = action.payload.uuid;
    return {
      ...state,
      optionsByUuid: _omit(state.optionsByUuid, [spaceUuid]),
      galleryByUuid: _omit(state.galleryByUuid, [spaceUuid]),
    };
  }

  if (updatedSpaceOption.match(action)) {
    const { uuid, options } = action.payload;
    return {
      ...state,
      optionsByUuid: { ...state.optionsByUuid, [uuid]: options },
    };
  }
  if (spacePhotosSaved.match(action)) {
    const { spaceUuid, gallery } = action.payload;
    return { ...state, galleryByUuid: { ...state.galleryByUuid, [spaceUuid]: gallery } };
  }

  return state;
};

export default spacesReducer;

import {
  RECEIVED_QUIZLET_ANSWERS,
  CREATED_QUIZLET_ANSWERS,
  CREATE_PRE_AUTH_QUIZLET_ANSWER,
  CLEAR_PRE_AUTH_ANSWERS,
  QuizletActions,
} from '~/actions/weddings/types/quizletActionTypes';
import { QuizletAnswerRequest } from '~/types/responseTypes';

export interface WeddingQuizletState {
  loaded: boolean;
  answers: Record<string, boolean>;
  preAuthAnswers: QuizletAnswerRequest[];
}
export const initialState: WeddingQuizletState = {
  loaded: false,
  answers: {} as Record<string, boolean>,
  preAuthAnswers: [],
};

export const quizletsReducer = (
  state = initialState,
  action?: QuizletActions
): WeddingQuizletState => {
  switch (action?.type) {
    case RECEIVED_QUIZLET_ANSWERS: {
      const answers = action.payload.reduce((result, answer) => {
        return {
          ...result,
          [answer.key]: answer.liked,
        };
      }, {} as Record<string, boolean>);

      return { ...state, loaded: true, answers };
    }
    case CREATED_QUIZLET_ANSWERS: {
      return {
        ...state,
        loaded: true,
        answers: {
          ...state.answers,
          [action.payload.key]: action.payload.liked,
        },
      };
    }

    case CREATE_PRE_AUTH_QUIZLET_ANSWER: {
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.key]: action.payload.liked,
        },
        preAuthAnswers: [...state.preAuthAnswers, { ...action.payload }],
      };
    }

    case CLEAR_PRE_AUTH_ANSWERS: {
      return {
        ...state,
        preAuthAnswers: [],
      };
    }

    default:
      return state;
  }
};

export default quizletsReducer;

import {
  TEMP_IMAGE_UPLOADING,
  TEMP_IMAGE_UPLOADED,
  TEMP_IMAGE_TOTAL_CHANGED,
  TEMP_IMAGE_COMPLETED_CHANGED,
  TEMP_IMAGE_ERRORED_CHANGED,
  ImageUploadActions,
} from '../actions/types/imageUploadActionTypes';
import { updateState, updateCount, replaceState } from '../util/imageState';
import { ImageReducerState } from './imagesReducer';

export const initialState: ImageReducerState = {};

const tempImagesReducer = (
  state = initialState,
  action?: ImageUploadActions
): ImageReducerState => {
  switch (action?.type) {
    case TEMP_IMAGE_UPLOADING: {
      return updateState(state, action.payload);
    }
    case TEMP_IMAGE_UPLOADED: {
      return updateState(state, action.payload);
    }
    case TEMP_IMAGE_TOTAL_CHANGED: {
      return replaceState(state, action.payload);
    }
    case TEMP_IMAGE_COMPLETED_CHANGED: {
      return updateState(state, updateCount(state, action.payload, 'completedCount'));
    }
    case TEMP_IMAGE_ERRORED_CHANGED: {
      return updateState(state, updateCount(state, action.payload, 'erroredCount'));
    }
    default:
      return state;
  }
};

export default tempImagesReducer;

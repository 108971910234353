import { createReducer } from '@reduxjs/toolkit';

import { receivedMessageStats } from '~/actions/types/inquiryMessagesActionTypes';

export interface InquiryMessagesState {
  unreadMessagesTotal: number | null;
  unreadInvitesTotal: number | null;
  unrespondedMessagesTotal: number | null;
}

export const initialState: InquiryMessagesState = {
  unreadMessagesTotal: 0,
  unreadInvitesTotal: 0,
  unrespondedMessagesTotal: 0,
};

const inquiryMessagesReducer = createReducer(initialState, (builder) => {
  return builder.addCase(receivedMessageStats, (state, { payload }) => {
    const {
      unreadInvitesTotal = 0,
      unreadTotal: unreadMessagesTotal = 0,
      unrespondedTotal: unrespondedMessagesTotal = 0,
    } = payload;
    state.unreadInvitesTotal = unreadInvitesTotal;
    state.unreadMessagesTotal = unreadMessagesTotal;
    state.unrespondedMessagesTotal = unrespondedMessagesTotal;
  });
});

export default inquiryMessagesReducer;

import {
  AutoSendHellosActionTypes,
  RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT,
  RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT_ERROR,
  RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE,
  RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE_ERROR,
  REQUESTING_MESSAGE_TEMPLATE_FOR_STOREFRONT,
  REQUESTING_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE,
} from '~/actions/types/autoSendHellosActionTypes';

export type AutoSendMessageTemplateState = {
  suggestedMessageForStorefront: string | null;
  suggestedMessageForVendorType: string | null;
  busy: boolean;
  failedToRetrieveMessage: boolean;
};
export const initialState: AutoSendMessageTemplateState = {
  suggestedMessageForStorefront: null,
  suggestedMessageForVendorType: null,
  busy: false,
  failedToRetrieveMessage: false,
};

const menuReducer = (
  state: AutoSendMessageTemplateState = initialState,
  action: AutoSendHellosActionTypes
): AutoSendMessageTemplateState => {
  switch (action.type) {
    case REQUESTING_MESSAGE_TEMPLATE_FOR_STOREFRONT:
    case REQUESTING_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE: {
      return { ...state, busy: true, failedToRetrieveMessage: false };
    }
    case RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT: {
      return {
        ...state,
        suggestedMessageForStorefront: action.payload.suggestedMessage,
        busy: false,
        failedToRetrieveMessage: false,
      };
    }
    case RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE: {
      return {
        ...state,
        suggestedMessageForVendorType: action.payload.suggestedMessage,
        busy: false,
        failedToRetrieveMessage: false,
      };
    }
    case RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT_ERROR:
    case RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE_ERROR: {
      return {
        ...state,
        failedToRetrieveMessage: true,
      };
    }
    default:
      return state;
  }
};

export default menuReducer;

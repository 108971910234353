import { Action } from 'redux';

import { activatingSubNav, hidingSubNav } from '~/actions/vendors/types/vendorMenuActionTypes';

export type MenuState = {
  activeMenu: boolean;
};
export const initialState: MenuState = {
  activeMenu: false,
};

/**
 * The menu reducer is used to show an overlay on the page when a menu is expanded or collapse.
 */
const menuReducer = (state: MenuState = initialState, action?: Action): MenuState => {
  if (!action) {
    return state;
  }

  if (activatingSubNav.match(action)) {
    return { ...state, activeMenu: true };
  }
  if (hidingSubNav.match(action)) {
    return { ...state, activeMenu: false };
  }
  return state;
};

export default menuReducer;

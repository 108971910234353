import { PhotoCreditFormValues } from '~/pages/vendors/Storefront/components/PhotoTags/types';

export const SAVE_TEMP_PHOTO_CREDITS = 'zola/ui/tempPhotoCredits/SAVE_TEMP_PHOTO_CREDITS';
export const DELETE_TEMP_PHOTO_CREDITS = 'zola/ui/tempPhotoCredits/DELETE_TEMP_PHOTO_CREDITS';

interface SaveTempPhotoCreditsAction {
  type: typeof SAVE_TEMP_PHOTO_CREDITS;
  payload: PhotoCreditFormValues;
}

interface DeleteTempPhotoCreditsAction {
  type: typeof DELETE_TEMP_PHOTO_CREDITS;
}

export type PhotoCreditsActionTypes = SaveTempPhotoCreditsAction | DeleteTempPhotoCreditsAction;

import { CoupleDetailView, ExactDateView } from '@zola/svc-marketplace-ts-types';

import { Weekdays } from '~/components/common/form/dates';
import {
  WeddingDate,
  WeddingDateFormFields,
} from '~/components/common/form/WeddingDateField/types';
import { MappedInquiryPreferencesView } from '~/types/responseTypes';
import { zeroPad } from '~/util/zeroPad';

import { stringifyYear } from '../FlexibleDateSelection/flexibleDateSelectionHelpers';
import { spreadWeddingDate } from '../WeddingDateField/dateFunctions';

const convertExactDateView = (date?: ExactDateView): WeddingDate | undefined => {
  if (date) {
    return {
      weddingDay: date.day,
      weddingMonth: zeroPad(date.month),
      weddingYear: `${date.year}`,
    };
  }
  return undefined;
};

/**
 * Initializer to turn inquiry preferences or couple preferences into form values for the venue date
 * question.
 */
export const initializeExpandedWeddingDates = (
  vals: Partial<WeddingDateFormFields>,
  preferences: MappedInquiryPreferencesView | undefined | CoupleDetailView
): Partial<WeddingDateFormFields> => {
  // Clear wedding date from the form so it doesn't get validated or submitted
  const filteredValues = {
    ...vals,
    weddingDate: undefined,
    weddingYearMonthDate: undefined,
    weddingYear: undefined,
    weddingMonth: undefined,
    weddingDay: undefined,
    flexibleOnDate: undefined,
  };

  if (!preferences) {
    return filteredValues;
  }

  if (
    preferences.weddingDates &&
    // We either have flex dates (year/month combinations) or we have exact dates (ranked choices)
    [preferences.weddingDates.flexibleDates, preferences.weddingDates.exactDates].some(
      (view) => view.length > 0
    )
  ) {
    const { weddingDates } = preferences;

    const preferredDate = weddingDates.exactDates.find((d) => d.preferred);
    const otherDates = weddingDates.exactDates.filter((d) => !d.preferred);

    return {
      ...filteredValues,
      showFlexibleDateSelection: weddingDates.flexibleDates.length > 0,
      expandedWeddingYears: weddingDates.flexibleDates.map((fd) => stringifyYear(fd.year)),
      expandedWeddingMonths: weddingDates.flexibleDates.reduce((result, fd) => {
        return {
          ...result,
          [stringifyYear(fd.year)]: fd.months.map((m) => zeroPad(m)),
        };
      }, {}),
      expandedWeddingWeekdays: weddingDates.daysOfWeek
        .map((d) => {
          return Weekdays.find((wd) => wd.enum === d)?.label;
        })
        .filter(Boolean) as string[],
      expandedWeddingDateFirstChoice: convertExactDateView(preferredDate),
      expandedWeddingDateSecondChoice: convertExactDateView(otherDates[0]),
      expandedWeddingDateThirdChoice: convertExactDateView(otherDates[1]),
    };
  }

  const { weddingDate, weddingYearMonthDate } = preferences;

  if (weddingDate) {
    const spreadDate = spreadWeddingDate(weddingDate, undefined);

    if (spreadDate && spreadDate.weddingDay && spreadDate.weddingMonth && spreadDate.weddingYear) {
      return {
        ...filteredValues,
        showFlexibleDateSelection: false,
        expandedWeddingDateFirstChoice: {
          weddingDay: spreadDate.weddingDay,
          weddingMonth: zeroPad(Number.parseInt(spreadDate.weddingMonth, 10)),
          weddingYear: spreadDate.weddingYear,
        },
      };
    }
  }
  if (weddingYearMonthDate) {
    const { weddingYear, weddingMonth } = spreadWeddingDate(null, weddingYearMonthDate);

    if (weddingYear && weddingMonth) {
      const year = stringifyYear(weddingYear);

      return {
        ...filteredValues,
        showFlexibleDateSelection: true,
        expandedWeddingYears: [year],
        expandedWeddingMonths: {
          [year]: [zeroPad(Number.parseInt(weddingMonth, 10))],
        },
        expandedWeddingWeekdays: [],
      };
    }
  }

  return filteredValues;
};

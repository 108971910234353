import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import {
  RECEIVED_INQUIRIES,
  DashboardActionTypes,
  InquiryWithActivityData,
  RECEIVED_FIRST_MOVES,
} from '~/actions/couples/types/dashboardActionTypes';

export interface CoupleInquiriesDashboardState {
  inquiries: InquiryWithActivityData[];
  firstMoves: StorefrontFirstMoveView[];
  inquiriesLoaded: boolean;
  firstMovesLoaded: boolean;
  loaded: boolean;
}

export const initialState: CoupleInquiriesDashboardState = {
  // couples/dashboard/
  inquiries: [],
  firstMoves: [],
  inquiriesLoaded: false,
  firstMovesLoaded: false,
  loaded: false,
};

const dashboardReducer = (
  state = initialState,
  action: DashboardActionTypes
): CoupleInquiriesDashboardState => {
  switch (action.type) {
    case RECEIVED_INQUIRIES: {
      return { ...state, inquiries: action.payload, inquiriesLoaded: true, loaded: true };
    }
    case RECEIVED_FIRST_MOVES: {
      return { ...state, firstMoves: action.payload, firstMovesLoaded: true, loaded: true };
    }
    default:
      return state;
  }
};

export default dashboardReducer;

import { ManageWeddingCardView } from '@zola/svc-marketplace-ts-types';

import {
  DeletedStorefrontWeddingView,
  ManageWeddingDetailsView,
  StorefrontWeddingGalleryView,
} from '~/types/responseTypes';

export const REQUESTING_STOREFRONT_WEDDING = 'zola/vendor/weddings/REQUESTING_STOREFRONT_WEDDING';
export const RECEIVED_STOREFRONT_WEDDING = 'zola/vendor/weddings/RECEIVED_STOREFRONT_WEDDING';

export const REQUESTING_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/REQUESTING_STOREFRONT_WEDDING_GALLERY';
export const RECEIVED_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/RECEIVED_STOREFRONT_WEDDING_GALLERY';

export const REQUESTING_STOREFRONT_WEDDINGS = 'zola/vendor/weddings/REQUESTING_STOREFRONT_WEDDINGS';
export const RECEIVED_STOREFRONT_WEDDINGS = 'zola/vendor/weddings/RECEIVED_STOREFRONT_WEDDINGS';
export const RECEIVED_STOREFRONT_WEDDINGS_TAGGED_IN =
  'zola/vendor/weddings/RECEIVED_STOREFRONT_WEDDINGS_TAGGED_IN';

export const CREATING_STOREFRONT_WEDDING = 'zola/vendor/weddings/CREATING_STOREFRONT_WEDDING';
export const CREATED_STOREFRONT_WEDDING = 'zola/vendor/weddings/CREATED_STOREFRONT_WEDDING';

export const CREATING_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/CREATING_STOREFRONT_WEDDING_GALLERY';
export const CREATED_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/CREATED_STOREFRONT_WEDDING_GALLERY';

export const UPDATING_STOREFRONT_WEDDING = 'zola/vendor/weddings/UPDATING_STOREFRONT_WEDDING';
export const UPDATED_STOREFRONT_WEDDING = 'zola/vendor/weddings/UPDATED_STOREFRONT_WEDDING';

export const UPDATING_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/UPDATING_STOREFRONT_WEDDING_GALLERY';
export const UPDATED_STOREFRONT_WEDDING_GALLERY =
  'zola/vendor/weddings/UPDATED_STOREFRONT_WEDDING_GALLERY';

export const DELETING_STOREFRONT_WEDDING = 'zola/vendor/weddings/DELETING_STOREFRONT_WEDDING';
export const DELETED_STOREFRONT_WEDDING = 'zola/vendor/weddings/DELETED_STOREFRONT_WEDDING';

export const ACCEPT_TERMS_BY_WEDDING_UUID = 'zola/vendor/weddings/ACCEPT_TERMS';

interface RequestingStorefrontWeddingAction {
  type: typeof REQUESTING_STOREFRONT_WEDDING;
}

export interface ReceivedStorefrontWeddingAction {
  type: typeof RECEIVED_STOREFRONT_WEDDING;
  payload: ManageWeddingDetailsView;
}

interface RequestingStorefrontWeddingGalleryAction {
  type: typeof REQUESTING_STOREFRONT_WEDDING_GALLERY;
}

export interface ReceivedStorefrontWeddingGalleryAction {
  type: typeof RECEIVED_STOREFRONT_WEDDING_GALLERY;
  payload: StorefrontWeddingGalleryView[];
}

interface RequestingStorefrontWeddingsAction {
  type: typeof REQUESTING_STOREFRONT_WEDDINGS;
}

export interface ReceivedStorefrontWeddingsAction {
  type: typeof RECEIVED_STOREFRONT_WEDDINGS;
  payload: ManageWeddingCardView[];
}

export interface ReceivedStorefrontWeddingsTaggedInAction {
  type: typeof RECEIVED_STOREFRONT_WEDDINGS_TAGGED_IN;
  payload: ManageWeddingCardView[];
}

interface CreatingStorefrontWeddingAction {
  type: typeof CREATING_STOREFRONT_WEDDING;
}

export interface CreatedStorefrontWeddingAction {
  type: typeof CREATED_STOREFRONT_WEDDING;
  payload: ManageWeddingDetailsView;
}

interface CreatingStorefrontWeddingGalleryAction {
  type: typeof CREATING_STOREFRONT_WEDDING_GALLERY;
}

export interface CreatedStorefrontWeddingGalleryAction {
  type: typeof CREATED_STOREFRONT_WEDDING_GALLERY;
  payload: ManageWeddingDetailsView;
}

interface UpdatingStorefrontWeddingAction {
  type: typeof UPDATING_STOREFRONT_WEDDING;
}

export interface UpdatedStorefrontWeddingAction {
  type: typeof UPDATED_STOREFRONT_WEDDING;
  payload: ManageWeddingDetailsView;
}

interface UpdatingStorefrontWeddingGalleryAction {
  type: typeof UPDATING_STOREFRONT_WEDDING_GALLERY;
}

export interface UpdatedStorefrontWeddingGalleryAction {
  type: typeof UPDATED_STOREFRONT_WEDDING_GALLERY;
  payload: ManageWeddingDetailsView;
}

interface DeletingStorefrontWeddingAction {
  type: typeof DELETING_STOREFRONT_WEDDING;
}

export interface DeletedStorefrontWeddingAction {
  type: typeof DELETED_STOREFRONT_WEDDING;
  payload: DeletedStorefrontWeddingView;
}

export interface AcceptTermsForWeddingSubmissionAction {
  type: typeof ACCEPT_TERMS_BY_WEDDING_UUID;
  payload: { weddingUuid: string };
}

export type VendorWeddingActionTypes =
  | RequestingStorefrontWeddingAction
  | ReceivedStorefrontWeddingAction
  | RequestingStorefrontWeddingGalleryAction
  | ReceivedStorefrontWeddingGalleryAction
  | RequestingStorefrontWeddingsAction
  | ReceivedStorefrontWeddingsAction
  | ReceivedStorefrontWeddingsTaggedInAction
  | CreatingStorefrontWeddingAction
  | CreatedStorefrontWeddingAction
  | CreatingStorefrontWeddingGalleryAction
  | CreatedStorefrontWeddingGalleryAction
  | UpdatingStorefrontWeddingAction
  | UpdatedStorefrontWeddingAction
  | UpdatingStorefrontWeddingGalleryAction
  | UpdatedStorefrontWeddingGalleryAction
  | DeletingStorefrontWeddingAction
  | DeletedStorefrontWeddingAction
  | AcceptTermsForWeddingSubmissionAction;

import { AccountVendorDashboardView } from '@zola/svc-marketplace-ts-types';

import {
  RECEIVED_VENDOR_TEAM,
  VendorTeamActions,
} from '~/actions/couples/types/vendorTeamActionTypes';

export interface CoupleVendorTeamState {
  vendorTeamDashboard: AccountVendorDashboardView[];
  loaded: boolean;
}

export const initialState: CoupleVendorTeamState = {
  // couples/vendorTeam/
  vendorTeamDashboard: [],
  loaded: false,
};

const vendorTeamReducer = (
  state = initialState,
  action: VendorTeamActions
): CoupleVendorTeamState => {
  switch (action.type) {
    case RECEIVED_VENDOR_TEAM: {
      return { ...state, vendorTeamDashboard: action.payload, loaded: true };
    }
    default:
      return state;
  }
};

export default vendorTeamReducer;

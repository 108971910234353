import {
  FavoriteStorefrontSearchView,
  FavoriteStorefrontSearchExecutableView,
} from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

const REQUESTING_SAVED_SEARCHES = 'zola/savedSearch/REQUESTING_SAVED_SEARCHES';
const RECEIVED_SAVED_SEARCHES = 'zola/savedSearch/RECEIVED_SAVED_SEARCHES';
const SAVING_SEARCH = 'zola/savedSearch/SAVING_SEARCH';
const SAVED_SEARCH = 'zola/savedSearch/SAVED_SEARCH';
const REQUESTING_SEARCH = 'zola/savedSearch/REQUESTING_SEARCH';
const RECEIVED_SEARCH = 'zola/savedSearch/RECEIVED_SEARCH';

export const requestingSavedSearches = createAction(REQUESTING_SAVED_SEARCHES);

export const receivedSavedSearches =
  createAction<FavoriteStorefrontSearchView[]>(RECEIVED_SAVED_SEARCHES);

export const savingSearch = createAction(SAVING_SEARCH);

export const savedSearch = createAction<FavoriteStorefrontSearchView>(SAVED_SEARCH);

export const requestingExecutableSearch = createAction(REQUESTING_SEARCH);

export const receivedExecutableSearch =
  createAction<FavoriteStorefrontSearchExecutableView>(RECEIVED_SEARCH);

import type { ActionableInquiryView } from '~/types/responseTypes';

export const RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE =
  'zola/couple/RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE';
export const REQUESTING_UNREAD_INQUIRIES = 'zola/couple/REQUESTING_UNREAD_INQUIRIES';
export const RECEIVED_UNREAD_INQUIRIES = 'zola/couple/RECEIVED_UNREAD_INQUIRIES';
export const RECEIVED_UNRESPONDED_INQUIRIES = 'zola/couple/RECEIVED_UNRESPONDED_INQUIRIES';

export interface RequestUnreadInquiriesAction {
  type: typeof REQUESTING_UNREAD_INQUIRIES;
}

export interface ReceivedInquiryPaperCrossSellEligible {
  type: typeof RECEIVED_INQUIRY_PAPER_CROSS_SELL_ELIGIBLE;
  payload: {
    inquiryPaperCrossSellEligible: boolean;
  };
}
export interface ReceivedUnreadInquiriesAction {
  type: typeof RECEIVED_UNREAD_INQUIRIES;
  payload: ActionableInquiryView[];
}

export interface ReceivedUnrespondedInquiriesAction {
  type: typeof RECEIVED_UNRESPONDED_INQUIRIES;
  payload: ActionableInquiryView[];
}

export type InquiryJewelActionTypes =
  | ReceivedInquiryPaperCrossSellEligible
  | RequestUnreadInquiriesAction
  | ReceivedUnreadInquiriesAction
  | ReceivedUnrespondedInquiriesAction;

import { InquiryActivityView, StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import { MappedInquiryView } from '~/types/mappedResponseTypes';

export const RECEIVED_INQUIRIES = 'zola/couples/dashboard/RECEIVED_INQUIRIES';
export const RECEIVED_FIRST_MOVES = 'zola/couples/dashboard/RECEIVED_FIRST_MOVES';

export interface InquiryWithActivityData extends MappedInquiryView {
  activity: InquiryActivityView;
}

export interface InquiryActivityViewByUuid {
  [uuid: string]: InquiryActivityView;
}

export interface ReceivedInquiries {
  type: typeof RECEIVED_INQUIRIES;
  payload: InquiryWithActivityData[];
}

export interface ReceivedFirstMoves {
  type: typeof RECEIVED_FIRST_MOVES;
  payload: StorefrontFirstMoveView[];
}

export type DashboardActionTypes = ReceivedInquiries | ReceivedFirstMoves;

import { AccountPreferenceView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

import type { AccountPreferenceState } from '~/reducers/couples/accountPreferencesReducer';

export type ReceivedAccountPreferences = Record<
  AccountPreferenceView.KeyEnum,
  AccountPreferenceState
>;

export const receivedAccountPreferences = createAction<ReceivedAccountPreferences>(
  'zola/couples/accountPreferences/RECEIVED_ACCOUNT_PREFERENCES'
);

export const setAccountPreference = createAction<{
  key: AccountPreferenceView.KeyEnum;
  enabled: boolean;
}>('zola/couples/accountPreferences/SET_ACCOUNT_PREFERENCES');

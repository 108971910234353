import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace';
import { CoupleDetailView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

export const receivedMiniQuiz = createAction<{
  details: CoupleDetailView;
  taxonomyKey: SearchableVendorTaxonomyKey;
}>('zola/couples/mini-quiz/RECEIVED_MINI_QUIZ');

export const receivedMiniQuizError = createAction<{ taxonomyKey: SearchableVendorTaxonomyKey }>(
  'zola/couples/mini-quiz/RECEIVED_MINI_QUIZ_ERROR'
);

export const receivedMiniQuizGuest = createAction<{ taxonomyKey: SearchableVendorTaxonomyKey }>(
  'zola/couples/mini-quiz/RECEIVED_MINI_QUIZ_GUEST'
);

export const receivedTaxonomyKey = createAction<{
  taxonomyKey: SearchableVendorTaxonomyKey | undefined;
}>('zola/couples/mini-quiz/RECEIVED_TAXONOMY_KEY');

import { CreditOrderStatsView } from '~/types/responseTypes';

export const REQUESTING_STOREFRONT_CREDIT_ORDER_STATS =
  'zola/vendor/credits/REQUESTING_STOREFRONT_CREDIT_ORDER_STATS';
export const RECEIVED_STOREFRONT_CREDIT_ORDER_STATS =
  'zola/vendor/credits/RECEIVED_STOREFRONT_CREDIT_ORDER_STATS';

export interface CreditOrderStatsViewByStorefront extends CreditOrderStatsView {
  storefrontUuid: string;
}
export interface RequestingStorefrontCreditOrderStatsAction {
  type: typeof REQUESTING_STOREFRONT_CREDIT_ORDER_STATS;
  payload: {
    storefrontUuid: string;
  };
}

export interface RequestStorefrontCreditOrderStatsAction {
  type: typeof RECEIVED_STOREFRONT_CREDIT_ORDER_STATS;
  payload: CreditOrderStatsViewByStorefront;
}

export type CreditOrderStatsAction =
  | RequestingStorefrontCreditOrderStatsAction
  | RequestStorefrontCreditOrderStatsAction;

import {
  RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS,
  REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS,
  VendorCreditAccountDetailsActionTypes,
  VendorCreditAccountState,
} from '~/actions/vendors/vendorCreditAccountDetailsActionTypes';

export interface VendorCreditAccountDetailsState {
  byStorefrontUuid: Record<string, VendorCreditAccountState>;
  busy: boolean;
}
export const initialState: VendorCreditAccountDetailsState = {
  byStorefrontUuid: {},
  busy: false,
};

const vendorCreditAccountDetailsReducer = (
  state: VendorCreditAccountDetailsState = initialState,
  action: VendorCreditAccountDetailsActionTypes
): VendorCreditAccountDetailsState => {
  switch (action.type) {
    case REQUESTING_VENDOR_CREDIT_ACCOUNT_DETAILS: {
      return { ...state, busy: true };
    }
    case RECEIVED_VENDOR_CREDIT_ACCOUNT_DETAILS: {
      const { payload } = action;
      const { storefrontUuid } = payload;
      return {
        byStorefrontUuid: {
          ...state.byStorefrontUuid,
          [storefrontUuid]: {
            ...payload,
          },
        },
        busy: false,
      };
    }
    default:
      return state;
  }
};

export default vendorCreditAccountDetailsReducer;

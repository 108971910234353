import { combineReducers } from 'redux';

import accountPreferencesReducer from './accountPreferencesReducer';
import dashboardReducer from './dashboardReducer';
import miniQuizReducer from './miniQuizReducer';
import quizReducer from './quizReducer';
import savedSearchReducer from './savedSearchReducer';
import userSettingsReducer from './userSettingsReducer';
import vendorStarterPacksReducer from './vendorStarterPacksReducer';
import vendorTeamReducer from './vendorTeamReducer';

export default combineReducers({
  accountPreferences: accountPreferencesReducer,
  dashboard: dashboardReducer, // couples.dashboard
  miniQuiz: miniQuizReducer, // couples.miniQuiz
  savedSearches: savedSearchReducer, // couples.savedSearches
  userSettings: userSettingsReducer, // couples.userSettings
  vendorStarterPacks: vendorStarterPacksReducer, // couples.vendorStarterPacks
  vendorTeam: vendorTeamReducer, // couples.vendorTeam
  quizzes: quizReducer, // couples.quizzes
});

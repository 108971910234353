import type { StorefrontActivePromoView, CreditBalanceView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

const REQUESTING_STOREFRONT_CREDIT_BALANCE =
  'zola/vendor/credits/REQUESTING_STOREFRONT_CREDIT_BALANCE';
const RECEIVED_STOREFRONT_CREDIT_BALANCE = 'zola/vendor/credits/RECEIVED_STOREFRONT_CREDIT_BALANCE';

const REQUESTING_USER_CREDIT_BALANCES = 'zola/vendor/credits/REQUESTING_USER_CREDIT_BALANCES';
const RECEIVED_USER_CREDIT_BALANCES = 'zola/vendor/credits/RECEIVED_USER_CREDIT_BALANCES';

const REQUESTING_STOREFRONT_PROMO_CODES = 'zola/vendor/REQUESTING_STOREFRONT_PROMO_CODE';
const RECEIVED_STOREFRONT_PROMO_CODES = 'zola/vendor/RECEIVED_STOREFRONT_PROMO_CODES';

export const requestingStorefrontCreditBalance = createAction<{ storefrontUuid: string }>(
  REQUESTING_STOREFRONT_CREDIT_BALANCE
);

export const receivedStorefrontCreditBalance = createAction<CreditBalanceView>(
  RECEIVED_STOREFRONT_CREDIT_BALANCE
);

export const requestingUserCreditBalances = createAction(REQUESTING_USER_CREDIT_BALANCES);

export const receivedUserCreditBalances = createAction<CreditBalanceView[]>(
  RECEIVED_USER_CREDIT_BALANCES
);

export const requestingStorefrontPromoCodes = createAction<{ storefrontUuid: string }>(
  REQUESTING_STOREFRONT_PROMO_CODES
);
export const receivedStorefrontPromoCodes = createAction<{
  promos: StorefrontActivePromoView[];
  storefrontUuid: string;
}>(RECEIVED_STOREFRONT_PROMO_CODES);

import { createAction } from '@reduxjs/toolkit';

import { MappedGalleryPhotoView, MappedOptionFacetView } from '~/types/responseTypes';

const RECEIVED_SPACE_GALLERY = 'zola/spaces/RECEIVED_SPACE_GALLERY';
const RECEIVED_SPACE_OPTIONS = 'zola/spaces/RECEIVED_SPACE_OPTIONS';

export const receivedSpaceGallery =
  createAction<{ spaceUuid: string; gallery: MappedGalleryPhotoView[] }>(RECEIVED_SPACE_GALLERY);

export const receivedSpaceOptions =
  createAction<{ spaceUuid: string; options: MappedOptionFacetView[] }>(RECEIVED_SPACE_OPTIONS);

import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { OnboardType } from '~/pages/vendors/OnboardV2/meta/types';
import {
  MappedOptionFacetView,
  OnboardingMarketView,
  RawOptionFacetView,
  RawReferenceVendorView,
  RawVendorLeadView,
  RawVendorMarketView,
} from '~/types/responseTypes';

export interface VendorOnboardingQuestionnaire {
  version: string | null;
  businessUnit: string | null;
  component: string | null;
  name: string | null;
  type: OnboardType | null;
  initialKey: string | null;
  steps: [];
}
type DefaultMatchingMarket = OnboardingMarketView;

export type MarketVerificationResponse = DefaultMatchingMarket[] | RawVendorMarketView[];

export type VendorOnboardingRequest = Record<string, unknown>;

export const REQUEST_VENDOR_QUESTIONS = 'zola/vendorOnboard/REQUEST_VENDOR_QUESTIONS';
export const RECEIVE_VENDOR_QUESTIONS = 'zola/vendorOnboard/RECEIVE_VENDOR_QUESTIONS';
export type RequestVendorQuestionsAction = Action<typeof REQUEST_VENDOR_QUESTIONS>;
export type ReceiveVendorQuestionsAction = PayloadAction<
  VendorOnboardingQuestionnaire,
  typeof RECEIVE_VENDOR_QUESTIONS
>;

export const REQUEST_VENDOR_FACETS_FOR_TAXONOMY =
  'zola/vendorOnboard/REQUEST_VENDOR_FACETS_FOR_TAXONOMY';
export const RECEIVE_VENDOR_FACETS_FOR_TAXONOMY =
  'zola/vendorOnboard/RECEIVE_VENDOR_FACETS_FOR_TAXONOMY';
export type RequestVendorFacetsForTaxonomyAction = Action<
  typeof REQUEST_VENDOR_FACETS_FOR_TAXONOMY
>;
export type ReceiveVendorFacetsForTaxonomyAction = PayloadAction<
  Record<string, RawOptionFacetView[]>,
  typeof RECEIVE_VENDOR_FACETS_FOR_TAXONOMY
>;

export const REQUEST_AUTOSUGGEST = 'zola/vendorOnboard/REQUEST_AUTOSUGGEST';
export const RECEIVE_AUTOSUGGEST = 'zola/vendorOnboard/RECEIVE_AUTOSUGGEST';
export type RequestAutosuggestAction = Action<typeof REQUEST_AUTOSUGGEST>;
export type ReceiveAutosuggestAction = PayloadAction<
  RawReferenceVendorView[],
  typeof RECEIVE_AUTOSUGGEST
>;

export const RESET_WORKSPACE = 'zola/vendorOnboard/RESET_WORKSPACE';
export type ResetWorkspaceAction = Action<typeof RESET_WORKSPACE>;

export const SENDING_ONBOARD_ANSWERS = 'zola/vendorOnboard/SENDING_ONBOARD_ANSWERS';
export const SENT_ONBOARD_ANSWERS = 'zola/vendorOnboard/SENT_ONBOARD_ANSWERS';
export type SendingOnboardAnswersAction = Action<typeof SENDING_ONBOARD_ANSWERS>;
export type SentOnboardAnswersAction = PayloadAction<
  VendorOnboardingRequest,
  typeof SENT_ONBOARD_ANSWERS
>;

export const SENDING_CONTACT_INFO = 'zola/vendorOnboard/SENDING_CONTACT_INFO';
export const SENT_CONTACT_INFO = 'zola/vendorOnboard/SENT_CONTACT_INFO';
type SendingContactInfoAction = Action<typeof SENDING_CONTACT_INFO>;
type SentContactInfoAction = PayloadAction<RawVendorLeadView, typeof SENT_CONTACT_INFO>;

export const SENDING_MARKET_VERIFICATION = 'zola/vendorOnboard/SENDING_MARKET_VERIFICATION';
export const SENT_MARKET_VERIFICATION = 'zola/vendorOnboard/SENT_MARKET_VERIFICATION';
export const RESET_MARKET_VERIFICATION = 'zola/vendorOnboard/RESET_MARKET_VERIFICATION';
export type SendingMarketVerificationAction = Action<typeof SENDING_MARKET_VERIFICATION>;
export type SentMarketVerificationAction = PayloadAction<
  MarketVerificationResponse,
  typeof SENT_MARKET_VERIFICATION
>;
export type ResetMarketVerificationAction = Action<typeof RESET_MARKET_VERIFICATION>;

export const UPDATE_STEP_KEY = 'zola/vendorOnboard/UPDATE_STEP_KEY';
export type UpdateStepKeyAction = PayloadAction<string, typeof UPDATE_STEP_KEY>;

export const UPDATE_PROGRESS_COUNTER = 'zola/vendorOnboard/UPDATE_PROGRESS_COUNTER';
export type UpdateProgressCounterAction = PayloadAction<number, typeof UPDATE_PROGRESS_COUNTER>;

export const UPDATE_VENDOR_TYPE = 'zola/vendorOnboard/UPDATE_VENDOR_TYPE';
export type UpdateVendorTypeAction = PayloadAction<OnboardType, typeof UPDATE_VENDOR_TYPE>;

export const CLEAR_VENDOR_ONBOARDING_LOCK = 'zola/vendorOnboard/CLEAR_VENDOR_ONBOARDING_LOCK';
export const SET_VENDOR_ONBOARDING_LOCK = 'zola/vendorOnboard/SET_VENDOR_ONBOARDING_LOCK';
export type ClearVendorOnboardingLockAction = Action<typeof CLEAR_VENDOR_ONBOARDING_LOCK>;
export type SetVendorOnboardingLockAction = Action<typeof SET_VENDOR_ONBOARDING_LOCK>;

/* onboarding V2 actions */
export const RECEIVED_FACETS_FOR_VENDOR_TYPE = 'zola/vendorOnboard/RECEIVED_FACETS_FOR_VENDOR_TYPE';
export type ReceivedFacetForVendorTypeAction = PayloadAction<
  MappedOptionFacetView[],
  typeof RECEIVED_FACETS_FOR_VENDOR_TYPE
>;

export type VendorOnboardingActionType =
  | RequestVendorQuestionsAction
  | ReceiveVendorQuestionsAction
  | RequestVendorFacetsForTaxonomyAction
  | ReceiveVendorFacetsForTaxonomyAction
  | RequestAutosuggestAction
  | ReceiveAutosuggestAction
  | ResetWorkspaceAction
  | SendingOnboardAnswersAction
  | SentOnboardAnswersAction
  | SendingContactInfoAction
  | SentContactInfoAction
  | SendingMarketVerificationAction
  | SentMarketVerificationAction
  | ResetMarketVerificationAction
  | UpdateStepKeyAction
  | UpdateProgressCounterAction
  | UpdateVendorTypeAction
  | ClearVendorOnboardingLockAction
  | SetVendorOnboardingLockAction
  | ReceivedFacetForVendorTypeAction;

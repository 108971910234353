import {
  CreditOrderStatsAction,
  RECEIVED_STOREFRONT_CREDIT_ORDER_STATS,
} from '~/actions/vendors/types/vendorOrderStatsActionTypes';
import { CreditOrderStatsView } from '~/types/responseTypes';

export interface CreditOrderStatsState {
  byStorefrontUuid: Record<string, CreditOrderStatsView>;
}
export const initialState: CreditOrderStatsState = {
  byStorefrontUuid: {},
};

const ordersReducer = (
  state: CreditOrderStatsState = initialState,
  action: CreditOrderStatsAction
): CreditOrderStatsState => {
  switch (action.type) {
    case RECEIVED_STOREFRONT_CREDIT_ORDER_STATS: {
      const { payload } = action;
      const { storefrontUuid, ...rest } = payload;

      return { ...state, byStorefrontUuid: { ...state.byStorefrontUuid, [storefrontUuid]: rest } };
    }
    default:
      return state;
  }
};

export default ordersReducer;

import {
  WeddingSearchFacetsActions,
  REQUEST_WEDDING_SEARCH_FACETS,
  RECEIVED_WEDDING_SEARCH_FACETS,
} from '~/actions/weddings/types/weddingSearchFacetActionTypes';
import { WeddingSearchFacetType } from '~/types/weddings.d';

export interface WeddingSearchFacetState {
  busy: boolean;
  loaded: boolean;
  facets: WeddingSearchFacetType[];
  bySlug: Record<string, WeddingSearchFacetType>;
  byKey: Record<string, WeddingSearchFacetType>;
}
const initialState: WeddingSearchFacetState = {
  busy: false,
  loaded: false,
  facets: [],
  bySlug: {},
  byKey: {},
};

export const weddingSearchFacetReducer = (
  state: WeddingSearchFacetState = initialState,
  action: WeddingSearchFacetsActions
): WeddingSearchFacetState => {
  switch (action.type) {
    case REQUEST_WEDDING_SEARCH_FACETS: {
      return { ...state, busy: true };
    }
    case RECEIVED_WEDDING_SEARCH_FACETS: {
      return { ...action.payload, busy: false, loaded: true };
    }

    default:
      return state;
  }
};

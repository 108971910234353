import _uniq from 'lodash/uniq';

import {
  ModuleGroupView,
  PromoActions,
  RECEIVED_PROMO_MODULE,
} from '../../actions/types/promoModuleActionTypes';

export interface PromoState {
  loaded: boolean;
  allTags: string[];
  byTag: { [tag: string]: ModuleGroupView };
}

// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
const initialState: PromoState = {
  loaded: false,
  byTag: {},
  allTags: [],
};

const promoModuleReducer = (
  state: PromoState = initialState,
  action?: PromoActions
): PromoState => {
  switch (action?.type) {
    case RECEIVED_PROMO_MODULE: {
      const { tag } = action.payload;

      if (tag) {
        return {
          ...state,
          byTag: { ...state.byTag, [tag]: action.payload },
          allTags: _uniq(state.allTags.concat(tag)),
          loaded: true,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default promoModuleReducer;

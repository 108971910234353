import { VendorTypeEnum } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { MappedInquiryPreferencesView, InquiryServicesFacet } from '~/types/responseTypes';

export const REQUESTING_INQUIRY_PREFERENCES = 'zola/inquiry/REQUESTING_INQUIRY_PREFERENCES';
export const RECEIVED_INQUIRY_PREFERENCES = 'zola/inquiry/RECEIVED_INQUIRY_PREFERENCES';
export const RECEIVED_INQUIRY_SERVICES = 'zola/inquiry/RECEIVED_INQUIRY_SERVICES';

type InquiryServicesObject = {
  uuid: string;
  services: InquiryServicesFacet[];
};

export type RequestingInquiryPreferencesAction = Action<typeof REQUESTING_INQUIRY_PREFERENCES>;
export type ReceivedInquiryPreferencesAction = PayloadAction<
  { preferences: MappedInquiryPreferencesView; vendorType: VendorTypeEnum },
  typeof RECEIVED_INQUIRY_PREFERENCES
>;
type ReceivedInquiryServicesActionType = PayloadAction<
  InquiryServicesObject,
  typeof RECEIVED_INQUIRY_SERVICES
>;

export const ReceivedInquiryServicesAction =
  createAction<InquiryServicesObject>(RECEIVED_INQUIRY_SERVICES);

export type InquiryPreferencesActions =
  | RequestingInquiryPreferencesAction
  | ReceivedInquiryPreferencesAction
  | ReceivedInquiryServicesActionType;

export interface EntitySlice<T, K extends string | number = string> {
  byId: Record<K, T>;
  allIds: K[];
}

interface WithStorefrontId {
  id: number | null;
  storefrontId: number | null;
}

export interface StorefrontEntityState<T extends WithStorefrontId> extends EntitySlice<T, number> {
  storefrontToId: { [storefrontId: number]: number };
}

/**
 * Create an entity for storing model objects in a normalized state structure
 *
 * @returns An empty entity object
 */
export const createEntity = <T, K extends string | number = string>(): EntitySlice<T, K> => {
  const entity = {
    byId: {} as Record<K, T>,
    allIds: [],
  };
  return entity;
};

/**
 * Adds or updates the specified model objects in an entity, based on the model's primary key
 *
 * @param stateSlice - The entity within the normalized state structure
 * @param objs - An array of model objects
 * @param idField - The name of the primary key
 *
 * @returns A new entity object
 */
export const setEntityObjects = <
  T, // Type of the object in the slice
  K extends string | number = string, // Type of the primary key
  S extends EntitySlice<T, K> = EntitySlice<T, K> // The full slice type
>(
  stateSlice: S,
  objs: T[],
  idField = 'id'
): S => {
  const newAllIds: K[] = [];
  const newByIds: { [id in K]: T } = {} as Record<K, T>;

  objs.forEach((obj) => {
    // @ts-expect-error A string can't be used to index an unknown type, and this is just getting the id field of the object
    const id = obj[idField] as K;

    if (!stateSlice.byId[id] && !newByIds[id]) {
      newAllIds.push(id);
    }
    newByIds[id] = obj;
  });

  return {
    ...stateSlice,
    byId: { ...stateSlice.byId, ...newByIds },
    allIds: [...stateSlice.allIds, ...newAllIds],
  };
};

/**
 * Add or update the specified model object in an entity, based on the model's primary key
 *
 * @param  stateSlice - The entity within the normalized state structure
 * @param  obj - A model object
 * @param  idField - The name of the primary key
 *
 * @returns A new entity object
 */
export const setEntityObject = <
  T, // Type of the object in the slice
  K extends string | number = string, // Type of the primary key
  S extends EntitySlice<T, K> = EntitySlice<T, K> // The full slice type
>(
  stateSlice: S,
  obj: T,
  idField = 'id'
): S => {
  return setEntityObjects<T, K, S>(stateSlice, [obj], idField);
};

/**
 *
 * @param stateSlice
 * @param objs
 * @param idField
 * @returns
 */
export const setStorefrontEntityObject = <
  T extends WithStorefrontId,
  S extends StorefrontEntityState<T> = StorefrontEntityState<T>
>(
  stateSlice: S,
  obj: T
): S => {
  const { id, storefrontId } = obj;
  return {
    ...setEntityObject<T, number, S>(stateSlice, obj, 'id'),
    storefrontToId: { ...stateSlice.storefrontToId, [storefrontId as number]: id },
  };
};

/**
 * Removes the specified model objects from an entity, based on the model's primary key
 *
 * @param  stateSlice - The entity within the normalized state structure
 * @param objs - An array of model objects
 * @param  idField - The name of the primary key
 *
 * @returns  A new entity object
 */
export const deleteEntityObjects = <
  T, // Type of the object in the slice
  K extends string | number = string, // Type of the primary key
  S extends EntitySlice<T, K> = EntitySlice<T, K> // The full slice type
>(
  stateSlice: S,
  objs: T[],
  idField = 'id'
): S => {
  const newAllIds: K[] = [...stateSlice.allIds];
  const newById = { ...stateSlice.byId };

  objs.forEach((obj) => {
    // @ts-expect-error A string can't be used to index an unknown type, and this is just getting the id field of the object
    const id = obj[idField] as K;

    if (newById[id]) {
      const deleteIndex = newAllIds.indexOf(id);
      newAllIds.splice(deleteIndex, 1);
    }
    delete newById[id];
  });
  return { ...stateSlice, byId: newById, allIds: newAllIds };
};

/**
 * Removes the specified model object from an entity, based on the model's primary key
 *
 * @param sliceSlice - The entity within the normalized state structure
 * @param obj - A model object
 * @param  idField The name of the primary key
 *
 * @returns  A new entity object
 */
export const deleteEntityObject = <
  T, // Type of the object in the slice
  K extends string | number = string, // Type of the primary key
  S extends EntitySlice<T, K> = EntitySlice<T, K> // The full slice type
>(
  sliceSlice: S,
  obj: T,
  idField = 'id'
): S => {
  return deleteEntityObjects<T, K, S>(sliceSlice, [obj], idField);
};

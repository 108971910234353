import { MappedAccountStorefront } from '~/types/mappedResponseTypes';

import {
  REQUESTING_VENDOR_SIGNUP,
  RECEIVED_VENDOR_SIGNUP,
  RECEIVED_VENDOR_SIGNUP_ERROR,
  REQUESTING_VENDOR_SIGNUP_CONFIRMATION,
  RECEIVED_VENDOR_SIGNUP_CONFIRMATION_ERROR,
  REQUESTING_VENDOR_SIGNUP_CODE,
  RECEIVED_VENDOR_SIGNUP_CODE_ERROR,
  REQUESTING_VENDOR_LOGIN,
  RECEIVED_VENDOR_LOGIN,
  RECEIVED_VENDOR_LOGIN_ERROR,
  REQUESTING_VENDOR_PASSWORD_CHANGE,
  RECEIVED_VENDOR_PASSWORD_CHANGE_ERROR,
  REQUESTING_VENDOR_PASSWORD_RESET_CODE,
  RECEIVED_VENDOR_PASSWORD_RESET_CODE_ERROR,
  REQUESTING_VENDOR_PASSWORD_RESET,
  RECEIVED_VENDOR_PASSWORD_RESET_ERROR,
  REQUESTING_VENDOR_CURRENT_USER,
  RECEIVED_VENDOR_CURRENT_USER,
  RECEIVED_VENDOR_CURRENT_USER_ERROR,
  REQUESTING_VENDOR_LOGOUT,
  CLEARING_VENDOR_ACCOUNT_ERROR,
  SETTING_VENDOR_CURRENT_STOREFRONT,
  VendorAccountActions,
} from '../actions/types/vendorAccountActionTypes';

export interface VendorAccountState {
  isUserContextLoaded: boolean;
  idTokenExpiresAt: number | null | string;
  cognitoId: string | null;
  userUuid: string | null;
  userType: 'ADMIN' | 'VENDOR' | 'COUPLE' | null; // Technically, it could be any of these, but in practice, its ADMIN or VENDOR
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  profileOnboardedAt: number | null;
  currentStorefrontUuid: string | null;
  storefronts: MappedAccountStorefront[];
  error: Error | null;
}

const initialState: Readonly<VendorAccountState> = {
  isUserContextLoaded: false,
  idTokenExpiresAt: null,
  cognitoId: null,
  userUuid: null,
  userType: null,
  email: null,
  firstName: null,
  lastName: null,
  fullName: null,
  profileOnboardedAt: null,
  currentStorefrontUuid: null,
  storefronts: [],
  error: null,
};

const vendorAccountReducer = (
  state: VendorAccountState = initialState,
  action?: VendorAccountActions
): VendorAccountState => {
  switch (action?.type) {
    case REQUESTING_VENDOR_SIGNUP:
      return { ...initialState };
    case RECEIVED_VENDOR_SIGNUP:
      return { ...state, ...action.payload };
    case RECEIVED_VENDOR_SIGNUP_ERROR:
      return { ...initialState, ...action.payload };
    case REQUESTING_VENDOR_SIGNUP_CONFIRMATION:
      return { ...state, error: null };
    case RECEIVED_VENDOR_SIGNUP_CONFIRMATION_ERROR:
      return { ...state, ...action.payload };
    case REQUESTING_VENDOR_SIGNUP_CODE:
      return { ...state, error: null };
    case RECEIVED_VENDOR_SIGNUP_CODE_ERROR:
      return { ...state, ...action.payload };
    case REQUESTING_VENDOR_LOGIN:
      return { ...state, error: null };
    case RECEIVED_VENDOR_LOGIN:
      return { ...state, ...action.payload, isUserContextLoaded: true };
    case RECEIVED_VENDOR_LOGIN_ERROR:
      return { ...initialState, ...action.payload };
    case REQUESTING_VENDOR_PASSWORD_CHANGE:
      return { ...state, error: null };
    case RECEIVED_VENDOR_PASSWORD_CHANGE_ERROR:
      return { ...state, ...action.payload };
    case REQUESTING_VENDOR_PASSWORD_RESET_CODE:
      return { ...state, error: null };
    case RECEIVED_VENDOR_PASSWORD_RESET_CODE_ERROR:
      return { ...state, ...action.payload };
    case REQUESTING_VENDOR_PASSWORD_RESET:
      return { ...state, error: null };
    case RECEIVED_VENDOR_PASSWORD_RESET_ERROR:
      return { ...state, ...action.payload };
    case REQUESTING_VENDOR_CURRENT_USER:
      return { ...state, error: null };
    case RECEIVED_VENDOR_CURRENT_USER:
      return { ...state, ...action.payload, isUserContextLoaded: true };
    case RECEIVED_VENDOR_CURRENT_USER_ERROR:
      return { ...initialState, isUserContextLoaded: true };
    case REQUESTING_VENDOR_LOGOUT:
      return { ...initialState, isUserContextLoaded: true, error: null };
    case CLEARING_VENDOR_ACCOUNT_ERROR:
      return { ...state, error: null };
    case SETTING_VENDOR_CURRENT_STOREFRONT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default vendorAccountReducer;

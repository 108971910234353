import { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { QuizletAnswerView, QuizletAnswerRequest } from '~/types/responseTypes';

export const REQUESTING_QUIZLET_ANSWERS = 'zola/weddingsV3/quizlets/REQUESTING_QUIZLET_ANSWERS';
export const RECEIVED_QUIZLET_ANSWERS = 'zola/weddingsV3/quizlets/RECEIVED_QUIZLET_ANSWERS';

export type RequestingQuizletAnswersAction = Action<typeof REQUESTING_QUIZLET_ANSWERS>;
export type ReceivedQuizletAnswersAction = PayloadAction<
  QuizletAnswerView[],
  typeof RECEIVED_QUIZLET_ANSWERS
>;

export const CREATING_QUIZLET_ANSWERS = 'zola/weddingsV3/quizlets/CREATING_QUIZLET_ANSWERS';
export const CREATED_QUIZLET_ANSWERS = 'zola/weddingsV3/quizlets/CREATED_QUIZLET_ANSWERS';

export type CreatingQuizletAnswersAction = Action<typeof CREATING_QUIZLET_ANSWERS>;
export type CreatedQuizletAnswersAction = PayloadAction<
  QuizletAnswerView,
  typeof CREATED_QUIZLET_ANSWERS
>;

export const CREATE_PRE_AUTH_QUIZLET_ANSWER =
  'zola/weddingsV3/quizlets/CREATE_PRE_AUTH_QUIZLET_ANSWER';

export type CreatePreAuthQuizletAnswer = PayloadAction<
  QuizletAnswerRequest,
  typeof CREATE_PRE_AUTH_QUIZLET_ANSWER
>;

export const CLEAR_PRE_AUTH_ANSWERS = 'zola/weddingsv3/quizlets/CLEAR_PRE_AUTH_ANSWERS';
export type ClearAllPreAuthAnswers = Action<typeof CLEAR_PRE_AUTH_ANSWERS>;

export type QuizletActions =
  | RequestingQuizletAnswersAction
  | ReceivedQuizletAnswersAction
  | CreatingQuizletAnswersAction
  | CreatedQuizletAnswersAction
  | CreatePreAuthQuizletAnswer
  | ClearAllPreAuthAnswers;

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';

import { AnyAction } from 'redux';

import { MappedVenueMenuView, VenueSpaceView } from '~/types/responseTypes';
import { VendorStorefrontDetails, VendorStorefrontDetailsVenue } from '~/types/storefrontDetails';
import { getImageUrl } from '~/util/imageUtils';
import Logger from '~/util/logger';

import { updatedMarkets, updatedMarketsV2 } from '../actions/types/marketsActionTypes';
import * as ActionType from '../actions/vendors/types/vendorStorefrontActionTypes';
import {
  PORTFOLIO_PHOTOS_SAVED,
  savedCoverGallery,
  UPDATED_STOREFRONT_GALLERY,
} from '../actions/vendors/types/vendorStorefrontPhotoActionTypes';
import { publicationSuccess } from '../actions/vendors/types/vendorStorefrontPublicationActionTypes';

export interface VendorStorefrontState {
  storefrontDetails: VendorStorefrontDetails | null;
  busy: boolean;
  saving: boolean;
  storefrontLoaded: boolean;
  lastError: string | null;
  noMatch: false;
}

const initialState: VendorStorefrontState = {
  storefrontDetails: null,
  busy: false,
  saving: false,
  storefrontLoaded: false,
  lastError: null,
  noMatch: false,
};

function assertIsVenueDetails(
  storefrontDetails: VendorStorefrontDetails | null
): asserts storefrontDetails is VendorStorefrontDetailsVenue {
  if (storefrontDetails?.taxonomyKey !== VENUES_TAXONOMY_KEY) {
    const message = 'Wrong type for storefront details venue';
    if (getEnvironment() === EnvironmentTypes.DEVELOPMENT) {
      throw new Error(message);
    } else {
      Logger.error(message);
    }
  }
}

function assertStorefrontDetailsExists(
  storefrontDetails: VendorStorefrontDetails | null
): asserts storefrontDetails is VendorStorefrontDetails {
  if (!storefrontDetails) {
    const message = 'Storefront details must be defined before using this action';
    if (getEnvironment() === EnvironmentTypes.DEVELOPMENT) {
      throw new Error(message);
    } else {
      Logger.error(message);
    }
  }
}

const replaceInStateArray = <T>(array: T[], index: number, object: T) => {
  return [...array.slice(0, index), object, ...array.slice(index + 1)];
};

const updateSpaces = (
  state: VendorStorefrontState,
  newSpaces: VenueSpaceView[]
): VendorStorefrontState => {
  assertIsVenueDetails(state.storefrontDetails);

  return {
    ...state,
    storefrontDetails: {
      ...state.storefrontDetails,
      venueDetails: {
        ...state.storefrontDetails.venueDetails,
        spaces: newSpaces,
      },
    },
  };
};

const updateMenus = (
  state: VendorStorefrontState,
  newMenus: MappedVenueMenuView[]
): VendorStorefrontState => {
  assertIsVenueDetails(state.storefrontDetails);

  return {
    ...state,
    storefrontDetails: {
      ...state.storefrontDetails,
      venueDetails: {
        ...state.storefrontDetails.venueDetails,
        menus: newMenus,
      },
    },
  };
};

const vendorStorefrontReducer = (
  state = initialState,
  action: AnyAction
): VendorStorefrontState => {
  if (ActionType.updatedSpace.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    const replaceIndex = state.storefrontDetails.venueDetails.spaces.findIndex(
      (space) => space.uuid === action.payload.uuid
    );
    const newSpaces = replaceInStateArray(
      state.storefrontDetails.venueDetails.spaces,
      replaceIndex,
      action.payload
    );
    return updateSpaces(state, newSpaces);
  }

  if (ActionType.createdSpace.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    return updateSpaces(state, [...state.storefrontDetails.venueDetails.spaces, action.payload]);
  }

  if (ActionType.deletedSpace.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    const newSpaces = state.storefrontDetails.venueDetails.spaces.filter(
      (space) => space.uuid !== action.payload.uuid
    );
    return updateSpaces(state, newSpaces);
  }

  if (ActionType.reorderingSpaces.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    const order = action.payload;
    const lookup = state.storefrontDetails.venueDetails.spaces.reduce<
      Record<string, VenueSpaceView>
    >((result, space) => {
      return { ...result, [space.uuid]: space };
    }, {});

    return updateSpaces(
      state,
      order.map((spaceUuid) => lookup[spaceUuid]).filter((space) => !!space)
    );
  }

  if (ActionType.reorderedSpaces.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    return updateSpaces(state, action.payload);
  }

  /** @deprecated */
  if (updatedMarkets.match(action)) {
    assertStorefrontDetailsExists(state.storefrontDetails);

    const storefrontDetails: VendorStorefrontDetails = {
      ...state.storefrontDetails,
      vendorMarkets: action.payload,
    };
    return { ...state, storefrontDetails };
  }

  if (updatedMarketsV2.match(action)) {
    const storefrontDetails = {
      ...state.storefrontDetails,
      markets: action.payload,
    } as VendorStorefrontDetails;
    return { ...state, storefrontDetails };
  }

  if (ActionType.finishedLoadingStorefront.match(action)) {
    return { ...state, storefrontLoaded: true };
  }

  if (ActionType.updatingStorefront.match(action)) {
    return { ...state, saving: true };
  }

  if (ActionType.updatedStorefront.match(action)) {
    const storefrontDetails = {
      ...state.storefrontDetails,
      ...action.payload,
    } as VendorStorefrontDetails;

    return {
      ...state,
      storefrontDetails,
      saving: false,
      lastError: null,
    };
  }

  if (ActionType.failedToSaveStorefront.match(action)) {
    return {
      ...state,
      saving: false,
      lastError: action.payload,
    };
  }

  if (ActionType.updatedVenue.match(action)) {
    assertIsVenueDetails(state.storefrontDetails);

    const venueDetails = { ...state.storefrontDetails.venueDetails, ...action.payload };
    const storefrontDetails: VendorStorefrontDetailsVenue = {
      ...state.storefrontDetails,
      venueDetails,
    };
    return { ...state, storefrontDetails };
  }

  if (savedCoverGallery.match(action)) {
    assertStorefrontDetailsExists(state.storefrontDetails);

    const newPayload: VendorStorefrontDetails = {
      ...state.storefrontDetails,
      coverGallery: action.payload.map((image) => ({
        ...image,

        // Sometimes we have imageId, sometimes we have uuid.. Ai yi yi
        uuid: image.uuid || image.imageId,

        imageUrl: getImageUrl(image.uuid || image.imageId),
      })),
    };
    return { ...state, storefrontDetails: newPayload };
  }

  if (publicationSuccess.match(action)) {
    // After a vendor requests review, we get the results (even thought thats a publication action)
    // This is because we need to update a field on the storefront
    assertStorefrontDetailsExists(state.storefrontDetails);

    const newStorefront = action.payload.storefront;
    const { publicationStatus } = newStorefront;

    return {
      ...state,
      storefrontDetails: {
        ...state.storefrontDetails,
        publicationStatus,
      },
    };
  }

  switch (action.type) {
    case ActionType.REQUESTING_STOREFRONT: {
      return { ...state, storefrontDetails: null, busy: true, storefrontLoaded: false };
    }
    case ActionType.RECEIVED_STOREFRONT: {
      return { ...state, storefrontDetails: action.payload };
    }
    case ActionType.LOADED_STOREFRONT: {
      return { ...state, storefrontDetails: action.payload, busy: false };
    }

    case ActionType.CREATED_MENU: {
      assertIsVenueDetails(state.storefrontDetails);

      return updateMenus(state, [...state.storefrontDetails.venueDetails.menus, action.payload]);
    }
    case ActionType.UPDATED_MENU: {
      assertIsVenueDetails(state.storefrontDetails);

      const replaceIndex = state.storefrontDetails.venueDetails.menus.findIndex(
        (menu) => menu.id === action.payload.id
      );
      const newMenus = replaceInStateArray(
        state.storefrontDetails.venueDetails.menus,
        replaceIndex,
        action.payload
      );
      return updateMenus(state, newMenus);
    }
    case ActionType.DELETED_MENU: {
      assertIsVenueDetails(state.storefrontDetails);

      const newMenus = state.storefrontDetails.venueDetails.menus.filter(
        (menu) => menu.id !== action.payload.id
      );
      return updateMenus(state, newMenus);
    }
    case ActionType.CREATED_REVIEW_REQUEST: {
      if (!state.storefrontDetails) {
        return state;
      }
      const newPayload = {
        ...state.storefrontDetails,
        reviews: [...state.storefrontDetails.reviews, action.payload],
      };
      return { ...state, storefrontDetails: newPayload };
    }
    case ActionType.RECEIVED_SUBMITTED_REVIEWS: {
      if (!state.storefrontDetails) {
        return state;
      }
      const newPayload = {
        ...state.storefrontDetails,
        reviews: [...state.storefrontDetails.reviews, action.payload],
      };
      return { ...state, storefrontDetails: newPayload };
    }
    case ActionType.CREATED_FAQ: {
      if (!state.storefrontDetails) {
        return state;
      }
      const newPayload = {
        ...state.storefrontDetails,
        faqs: [...state.storefrontDetails.faqs, action.payload],
      };
      return { ...state, storefrontDetails: newPayload };
    }
    case ActionType.UPDATED_FAQ: {
      if (!state.storefrontDetails) {
        return state;
      }
      const replaceIndex = state.storefrontDetails.faqs.findIndex(
        (faq) => faq.id === action.payload.id
      );
      const newFaqs = replaceInStateArray(
        state.storefrontDetails.faqs,
        replaceIndex,
        action.payload
      );
      const newPayload = { ...state.storefrontDetails, faqs: newFaqs };
      return { ...state, storefrontDetails: newPayload };
    }
    case ActionType.DELETED_FAQ: {
      if (!state.storefrontDetails) {
        return state;
      }
      const newFaqs = state.storefrontDetails.faqs.filter((faq) => faq.id !== action.payload.id);
      const newPayload = { ...state.storefrontDetails, faqs: newFaqs };
      return { ...state, storefrontDetails: newPayload };
    }
    case ActionType.UPDATED_OPTIONS: {
      assertStorefrontDetailsExists(state.storefrontDetails);

      const newPayload = {
        ...state.storefrontDetails,
        options: action.payload,
      };
      return { ...state, storefrontDetails: newPayload };
    }

    case PORTFOLIO_PHOTOS_SAVED: {
      assertStorefrontDetailsExists(state.storefrontDetails);

      const newPayload = {
        ...state.storefrontDetails,
        photoGallery: action.payload,
      };
      return { ...state, storefrontDetails: newPayload };
    }
    case UPDATED_STOREFRONT_GALLERY: {
      return state;
    }

    case ActionType.SET_ACTIVE_LISTING_MESSAGE: {
      if (!state.storefrontDetails) {
        return state;
      }

      return {
        ...state,
        storefrontDetails: {
          ...state.storefrontDetails,
          listingMessage: action.payload || null,
        },
      };
    }

    case ActionType.UPDATED_STOREFRONT_SOCIAL_LINK: {
      if (!state.storefrontDetails) {
        return state;
      }
      const { type, value } = action.payload;

      const social = {
        [type.toLowerCase()]: value,
      };
      return {
        ...state,
        storefrontDetails: {
          ...state.storefrontDetails,
          social: { ...state.storefrontDetails.social, ...social },
        },
      };
    }
    case ActionType.UPDATED_STOREFRONT_VIDEO_LINK: {
      if (!state.storefrontDetails) {
        return state;
      }
      /* video title is not stored, but generated in server mapper */
      const { type, value } = action.payload;
      return {
        ...state,
        storefrontDetails: {
          ...state.storefrontDetails,
          video: {
            title: state.storefrontDetails.video?.title || null,
            type,
            videoId: value,
          },
        },
      };
    }
    case ActionType.UPDATED_STOREFRONT_WEDDING_SCANNER_LINK: {
      if (!state.storefrontDetails) {
        return state;
      }
      const { type, value } = action.payload;
      const { storefrontDetails } = state;
      const { weddingScanner } = storefrontDetails as VendorStorefrontDetailsVenue;

      const weddingScannerUpdate = {
        [type.toLowerCase()]: value,
      };
      return {
        ...state,
        storefrontDetails: {
          ...state.storefrontDetails,
          weddingScanner: { ...weddingScanner, ...weddingScannerUpdate },
        } as VendorStorefrontDetailsVenue,
      };
    }
    default:
      return state;
  }
};

export default vendorStorefrontReducer;

import { CatererStoryGalleryView } from '@zola/svc-marketplace-ts-types';

import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import {
  ImageUploadActionPayload,
  ImageUploadedActionPayload,
} from '~/actions/types/imageUploadActionTypes';
import { MappedGalleryPhotoView } from '~/types/responseTypes';
import { StorefrontCoverGalleryPhoto } from '~/types/storefrontDetails';

export const PORTFOLIO_PHOTOS_SAVING = 'zola/vendorStorefrontPhotos/PORTFOLIO_PHOTOS_SAVING';
export const PORTFOLIO_PHOTOS_SAVED = 'zola/vendorStorefrontPhotos/PORTFOLIO_PHOTOS_SAVED';

export const STOREFRONT_PHOTO_CREATING = 'zola/vendorStorefrontPhotos/STOREFRONT_PHOTO_CREATING';
export const STOREFRONT_PHOTO_CREATED = 'zola/vendorStorefrontPhotos/STOREFRONT_PHOTO_CREATED';

export const STOREFRONT_PHOTOS_CREATING = 'zola/vendorStorefrontPhotos/STOREFRONT_PHOTOS_CREATING';
export const STOREFRONT_PHOTOS_CREATED = 'zola/vendorStorefrontPhotos/STOREFRONT_PHOTOS_CREATED';

const SAVING_CATERER_STORY_PHOTOS = 'zola/vendorStorefrontPhotos/SAVING_CATERER_STORY_PHOTOS';
const SAVED_CATERER_STORY_PHOTOS = 'zola/vendorStorefrontPhotos/SAVED_CATERER_STORY_PHOTOS';

const SPACE_PHOTOS_SAVING = 'zola/vendorStorefrontPhotos/SPACE_PHOTOS_SAVING';
const SPACE_PHOTOS_SAVED = 'zola/vendorStorefrontPhotos/SPACE_PHOTOS_SAVED';

export const spacePhotosSaving = createAction(SPACE_PHOTOS_SAVING);
export const spacePhotosSaved =
  createAction<{ gallery: MappedGalleryPhotoView[]; spaceUuid: string }>(SPACE_PHOTOS_SAVED);

export const SAVING_COVER_GALLERY = 'zola/vendorStorefrontPhotos/SAVING_COVER_GALLERY';
const SAVED_COVER_GALLERY = 'zola/vendorStorefrontPhotos/SAVED_COVER_GALLERY';
export const savedCoverGallery = createAction<StorefrontCoverGalleryPhoto[]>(SAVED_COVER_GALLERY);

export const UPDATING_STOREFRONT_GALLERY =
  'zola/vendorStorefrontPhotos/UPDATING_STOREFRONT_GALLERY';
export const UPDATED_STOREFRONT_GALLERY = 'zola/vendorStorefrontPhotos/UPDATED_STOREFRONT_GALLERY';

const REQUESTING_IMAGE_UPLOAD_JOB = 'zola/vendorStorefrontPhotos/REQUESTING_IMAGE_UPLOAD_JOB';
const RECEIVED_IMAGE_UPLOAD_JOB = 'zola/vendorStorefrontPhotos/RECEIVED_IMAGE_UPLOAD_JOB';

export type PortfolioPhotosSavingAction = Action<typeof PORTFOLIO_PHOTOS_SAVING>;
export type PortfolioPhotosSavedAction = PayloadAction<
  MappedGalleryPhotoView[],
  typeof PORTFOLIO_PHOTOS_SAVED
>;

export type StorefrontPhotoCreatingAction = PayloadAction<
  ImageUploadActionPayload,
  typeof STOREFRONT_PHOTO_CREATING
>;
export type StorefrontPhotoCreatedAction = PayloadAction<
  ImageUploadedActionPayload<MappedGalleryPhotoView>,
  typeof STOREFRONT_PHOTO_CREATED
>;

export type StorefrontPhotosCreatingAction = PayloadAction<
  ImageUploadActionPayload,
  typeof STOREFRONT_PHOTOS_CREATING
>;
export type StorefrontPhotosCreatedAction = PayloadAction<
  ImageUploadedActionPayload<MappedGalleryPhotoView>,
  typeof STOREFRONT_PHOTOS_CREATED
>;

export const savingCatererStoryPhotos = createAction(SAVING_CATERER_STORY_PHOTOS);
export const savedCatererStoryPhotos = createAction<{
  storyUuid: string;
  covers: CatererStoryGalleryView[];
}>(SAVED_CATERER_STORY_PHOTOS);

export type SavingCoverGalleryAction = Action<typeof SAVING_COVER_GALLERY>;

export const requestingImageUploadJobAction = createAction(REQUESTING_IMAGE_UPLOAD_JOB);
export const receivedImageUploadJobAction = createAction<string>(RECEIVED_IMAGE_UPLOAD_JOB);

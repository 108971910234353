import { MappedVendorUserView, StorefrontUserSettingsResponse } from '~/types/responseTypes';

export const REQUESTING_ACCOUNT_SETTINGS = 'zola/vendorAccountSettings/REQUESTING_ACCOUNT_SETTINGS';
export const RECEIVED_ACCOUNT_SETTINGS = 'zola/vendorAccountSettings/RECEIVED_ACCOUNT_SETTINGS';
export const UPDATING_ACCOUNT_SETTINGS = 'zola/vendorAccountSettings/UPDATING_ACCOUNT_SETTINGS';
export const UPDATED_ACCOUNT_SETTINGS = 'zola/vendorAccountSettings/UPDATED_ACCOUNT_SETTINGS';

export interface RequestingAccountSettingsAction {
  type: typeof REQUESTING_ACCOUNT_SETTINGS;
}

export interface ReceivedAccountSettingsActionPayload extends MappedVendorUserView {
  settings: StorefrontUserSettingsResponse;
}
interface ReceivedAccountSettingsAction {
  type: typeof RECEIVED_ACCOUNT_SETTINGS;
  payload: ReceivedAccountSettingsActionPayload;
}

export interface UpdatingAccountSettingsAction {
  type: typeof UPDATING_ACCOUNT_SETTINGS;
}

export interface UpdatedAccountSettingsAction {
  type: typeof UPDATED_ACCOUNT_SETTINGS;
  payload: ReceivedAccountSettingsActionPayload;
}

export type VendorAccountSettingsAction =
  | RequestingAccountSettingsAction
  | ReceivedAccountSettingsAction
  | UpdatingAccountSettingsAction
  | UpdatedAccountSettingsAction;

import { StorefrontInquiryStatsView, InquiryView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

import { MappedInquiryView } from '~/types/mappedResponseTypes';
import { AccountVendorView } from '~/types/responseTypes';

export const INQUIRY_NOT_FOUND = 'zola/vendorInquiries/INQUIRY_NOT_FOUND';
export interface InquiryNotFoundAction {
  type: typeof INQUIRY_NOT_FOUND;
  payload: {
    uuid: string;
  };
}

export const RECEIVED_BOOKED_VENDORS = 'zola/vendorInquiries/RECEIVED_BOOKED_VENDORS';
export interface ReceivedBookedVendorsAction {
  type: typeof RECEIVED_BOOKED_VENDORS;
  payload: AccountVendorView[];
}

export const SENDING_INQUIRY_RESPONSE = 'zola/vendorInquiries/SENDING_INQUIRY_RESPONSE';
export interface SendingInquiryResponseAction {
  type: typeof SENDING_INQUIRY_RESPONSE;
}

export const SENT_INQUIRY_RESPONSE = 'zola/vendorInquiries/SENT_INQUIRY_RESPONSE';
export interface SentInquiryResponseAction {
  type: typeof SENT_INQUIRY_RESPONSE;
  payload: InquiryView;
}

export const RECEIVED_STOREFRONT_INQUIRY_STATS = 'zola/inquiry/RECEIVED_STOREFRONT_INQUIRY_STATS';
export interface ReceivedStorefrontInquiryStatsAction {
  type: typeof RECEIVED_STOREFRONT_INQUIRY_STATS;
  payload: StorefrontInquiryStatsView;
}

export const REQUESTING_INQUIRY = 'zola/vendorInquiries/REQUESTING_INQUIRY';
export interface RequestingInquiryAction {
  type: typeof REQUESTING_INQUIRY;
  payload: {
    uuid: string;
  };
}

export const RECEIVED_INQUIRY = 'zola/vendorInquiries/RECEIVED_INQUIRY';
export const receivedInquiry = createAction<MappedInquiryView>(RECEIVED_INQUIRY);
export interface ReceivedInquiryAction {
  type: typeof RECEIVED_INQUIRY;
  payload: MappedInquiryView;
}

export type VendorInquiryActionTypes =
  | InquiryNotFoundAction
  | ReceivedBookedVendorsAction
  | ReceivedInquiryAction
  | ReceivedStorefrontInquiryStatsAction
  | RequestingInquiryAction
  | SendingInquiryResponseAction
  | SentInquiryResponseAction;

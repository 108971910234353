import { WeddingPlannerPackageView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

import { WeddingPlannerDetails } from '~/types/responseTypes';

const REQUESTING_WEDDING_PLANNER_DETAILS_BY_STOREFRONT =
  'zola/weddingPlanner/REQUESTING_WEDDING_PLANNER_DETAILS_BY_STOREFRONT';
const RECEIVED_WEDDING_PLANNER_DETAILS = 'zola/weddingPlanner/RECEIVED_WEDDING_PLANNER_DETAILS';
const UPDATING_WEDDING_PLANNER_DETAILS = 'zola/weddingPlanner/UPDATING_WEDDING_PLANNER_DETAILS';
const UPDATED_WEDDING_PLANNER_DETAILS = 'zola/weddingPlanner/UPDATED_WEDDING_PLANNER_DETAILS';
const RECEIVED_WEDDING_PLANNER_PACKAGE = 'zola/weddingPlanner/RECEIVED_WEDDING_PLANNER_PACKAGE';
const CREATING_WEDDING_PLANNER_PACKAGE = 'zola/weddingPlanner/CREATING_WEDDING_PLANNER_PACKAGE';
const UPDATING_WEDDING_PLANNER_PACKAGE = 'zola/weddingPlanner/UPDATING_WEDDING_PLANNER_PACKAGE';
const REQUESTING_WEDDING_PLANNER_PACKAGES =
  'zola/weddingPlanner/REQUESTING_WEDDING_PLANNER_PACKAGES';
const RECEIVED_WEDDING_PLANNER_PACKAGES = 'zola/weddingPlanner/RECEIVED_WEDDING_PLANNER_PACKAGES';
const UPDATING_WEDDING_PLANNER_SERVICE_LEVEL =
  'zola/weddingPlanner/UPDATING_WEDDING_PLANNER_SERVICE_LEVEL';

export const receivedWeddingPlannerPackage = createAction<WeddingPlannerPackageView>(
  RECEIVED_WEDDING_PLANNER_PACKAGE
);
export const receivedWeddingPlannerDetails = createAction<WeddingPlannerDetails>(
  RECEIVED_WEDDING_PLANNER_DETAILS
);
export const updatedWeddingPlannerDetails = createAction<WeddingPlannerDetails>(
  UPDATED_WEDDING_PLANNER_DETAILS
);
export const receivedWeddingPlannerPackages = createAction<WeddingPlannerPackageView[]>(
  RECEIVED_WEDDING_PLANNER_PACKAGES
);

export const requestingWeddingPlannerPackages = createAction(REQUESTING_WEDDING_PLANNER_PACKAGES);
export const updatingWeddingPlannerPackage = createAction(UPDATING_WEDDING_PLANNER_PACKAGE);
export const updatingWeddingPlannerDetails = createAction(UPDATING_WEDDING_PLANNER_DETAILS);
export const requestingWeddingPlannerDetailsByStorefront = createAction(
  REQUESTING_WEDDING_PLANNER_DETAILS_BY_STOREFRONT
);
export const creatingWeddingPlannerPackage = createAction(CREATING_WEDDING_PLANNER_PACKAGE);
export const updatingWeddingPlannerServiceLevel = createAction(
  UPDATING_WEDDING_PLANNER_SERVICE_LEVEL
);

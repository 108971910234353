import type { Action, PayloadAction } from '@reduxjs/toolkit';

import type { MarketplaceNotification } from '~/components/common/notifications/constants';

export const SHOW_NOTIFICATION = 'zola/notifications/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'zola/notifications/HIDE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'zola/notifications/CLEAR_NOTIFICATIONS';

export type ShowNotificationAction = PayloadAction<
  MarketplaceNotification,
  typeof SHOW_NOTIFICATION
>;
export type ClearNotificationsAction = Action<typeof CLEAR_NOTIFICATIONS>;
export type HideNotificationAction = PayloadAction<number, typeof HIDE_NOTIFICATION>;

export type NotificationActionType =
  | ShowNotificationAction
  | ClearNotificationsAction
  | HideNotificationAction;

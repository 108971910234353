import { PaymentMethodView, RenewalPlanView } from '@zola/svc-marketplace-ts-types';

import { createAction } from '@reduxjs/toolkit';

export const receivedRenewalPlan = createAction<RenewalPlanView>(
  'zola/vendors/renewalPlans/RECEIVED_RENEWAL_PLAN'
);

export const createdRenewalPlan = createAction<RenewalPlanView>(
  'zola/vendors/renewalPlans/CREATED_RENEWAL_PLAN'
);

export const updatedRenewalPlan = createAction<{
  payload: PaymentMethodView;
  storefrontUuid: string;
}>('zola/vendors/renewalPlans/UPDATED_RENEWAL_PLAN');

export const deletedRenewalPlan = createAction<RenewalPlanView>(
  'zola/vendors/renewalPlans/DELETED_RENEWAL_PLAN'
);

import { MailingListSubscriptionView } from '@zola/svc-marketplace-ts-types';

import {
  RECEIVED_MAILING_LIST_SUBSCRIPTIONS,
  UPDATED_MAILING_LIST_SUBSCRIPTIONS,
  UPDATING_MAILING_LIST_SUBSCRIPTIONS,
  VendorMailingListSettingsAction,
} from '~/actions/vendors/types/vendorMailingListSettingsActionTypes';

interface EmptyMailingListSettingsState {
  isSaving: boolean;
  loaded: boolean;
  subscriptions: MailingListSubscriptionView[];
}
interface LoadedMailingListSettingsState {
  isSaving: boolean;
  loaded: boolean;
  subscriptions: MailingListSubscriptionView[] | null;
}

export type VendorMailingListSettingsState =
  | EmptyMailingListSettingsState
  | LoadedMailingListSettingsState;

const initialState: VendorMailingListSettingsState = {
  isSaving: false,
  loaded: false,
  subscriptions: [],
};

const vendorMailingListSettingsReducer = (
  state: VendorMailingListSettingsState = initialState,
  action: VendorMailingListSettingsAction
): VendorMailingListSettingsState => {
  switch (action.type) {
    case RECEIVED_MAILING_LIST_SUBSCRIPTIONS:
      return {
        ...state,
        isSaving: false,
        loaded: true,
        subscriptions: action.payload,
      };
    case UPDATING_MAILING_LIST_SUBSCRIPTIONS:
      return {
        ...state,
        isSaving: true,
      };
    case UPDATED_MAILING_LIST_SUBSCRIPTIONS:
      return {
        ...state,
        isSaving: false,
        loaded: true,
        subscriptions: action.payload,
      };
    default:
      return state;
  }
};

export default vendorMailingListSettingsReducer;

import {
  IMAGE_UPLOADING,
  IMAGE_UPLOADED,
  IMAGE_MOVED,
  IMAGE_TOTAL_CHANGED,
  IMAGE_COMPLETED_CHANGED,
  IMAGE_ERRORED_CHANGED,
  ImageMoved,
  ImageUploaded,
  ImageUploadRequested,
  ImageCategories,
  ImageUploadActions,
} from '../actions/types/imageUploadActionTypes';
import { updateState, updateCount, replaceState } from '../util/imageState';

interface ImageReducerCounts {
  totalPhotos?: number;
  completedCount?: number;
  erroredCount?: number;
}
export interface ImageReducerSlice extends ImageReducerCounts {
  [index: number]: ImageUploadRequested | ImageUploaded | ImageMoved;
}

export type ImageReducerState = {
  [key in ImageCategories]?: ImageReducerSlice; // This doesn't really accurately capture that only one key is ever used at a time.
};

export const initialState: ImageReducerState = {};

const imagesReducer = (state = initialState, action?: ImageUploadActions): ImageReducerState => {
  switch (action?.type) {
    case IMAGE_UPLOADING: {
      return updateState(state, action.payload);
    }
    case IMAGE_UPLOADED: {
      return updateState(state, action.payload);
    }
    case IMAGE_MOVED: {
      return updateState(state, action.payload);
    }
    case IMAGE_TOTAL_CHANGED: {
      return replaceState(state, action.payload);
    }
    case IMAGE_COMPLETED_CHANGED: {
      return updateState(state, updateCount(state, action.payload, 'completedCount'));
    }
    case IMAGE_ERRORED_CHANGED: {
      return updateState(state, updateCount(state, action.payload, 'erroredCount'));
    }
    default:
      return state;
  }
};

export default imagesReducer;

import { StorefrontPrivacySettingView, StorefrontReferralView } from '~/types/responseTypes';

import {
  RECEIVED_STOREFRONT_PRIVACY_SETTINGS,
  RECEIVED_STOREFRONT_REFERRALS,
  REMOVED_STOREFRONT_REFERRALS,
  ADDED_STOREFRONT_REFERRALS,
  UPDATED_STOREFRONT_PRIVACY_SETTINGS,
  VendorCommunityActionType,
} from '../actions/types/vendorCommunityActionTypes';

export interface VendorCommunityState {
  privacySettings: Partial<StorefrontPrivacySettingView>;
  referrals: StorefrontReferralView[];
}
const initialState: VendorCommunityState = {
  privacySettings: {},
  referrals: [],
};

const vendorCommunityReducer = (
  state = initialState,
  action: VendorCommunityActionType
): VendorCommunityState => {
  switch (action.type) {
    case UPDATED_STOREFRONT_PRIVACY_SETTINGS:
    case RECEIVED_STOREFRONT_PRIVACY_SETTINGS: {
      return { ...state, privacySettings: action.payload };
    }

    case RECEIVED_STOREFRONT_REFERRALS: {
      return { ...state, referrals: action.payload };
    }

    case REMOVED_STOREFRONT_REFERRALS: {
      const payloadIds = action.payload.map((x) => x.id);
      return { ...state, referrals: state.referrals.filter((x) => !payloadIds.includes(x.id)) };
    }

    case ADDED_STOREFRONT_REFERRALS: {
      return { ...state, referrals: [...state.referrals, ...action.payload] };
    }

    default:
      return state;
  }
};

export default vendorCommunityReducer;

import { createAction } from '@reduxjs/toolkit';

import {
  CatererStoryView,
  EntityFacetView,
  MappedOptionFacetView,
  VenueDetails,
  VenueSpaceView,
} from '~/types/responseTypes';
import { MappedStorefrontDetails } from '~/types/storefrontDetails';

const FINISHED_LOADING_STOREFRONT = 'zola/vendorStorefront/FINISHED_LOADING_STOREFRONT';
export const finishedLoadingStorefront = createAction(FINISHED_LOADING_STOREFRONT);

export const REQUESTING_STOREFRONT = 'zola/vendorStorefront/REQUESTING_STOREFRONT';
export const RECEIVED_STOREFRONT = 'zola/vendorStorefront/RECEIVED_STOREFRONT';
export const LOADED_STOREFRONT = 'zola/vendorStorefront/LOADED_STOREFRONT';

export const UPDATING_VENDOR_USER = 'zola/vendorStorefront/UPDATING_VENDOR_USER';
export const UPDATED_VENDOR_USER = 'zola/vendorStorefront/UPDATED_VENDOR_USER';

const UPDATING_VENDOR_STOREFRONT = 'zola/vendorStorefront/UPDATING_VENDOR_STOREFRONT';
const UPDATED_VENDOR_STOREFRONT = 'zola/vendorStorefront/UPDATED_VENDOR_STOREFRONT';

export const updatingStorefront = createAction(UPDATING_VENDOR_STOREFRONT);
export const updatedStorefront = createAction<MappedStorefrontDetails>(UPDATED_VENDOR_STOREFRONT);

const FAILED_TO_SAVE_VENDOR_STOREFRONT = 'zola/vendorStorefront/FAILED_TO_SAVE_VENDOR_STOREFRONT';
export const failedToSaveStorefront = createAction<string>(FAILED_TO_SAVE_VENDOR_STOREFRONT);

const UPDATING_VENUE = 'zola/vendorStorefront/UPDATING_VENUE';
const UPDATED_VENUE = 'zola/vendorStorefront/UPDATED_VENUE';

export const updatingVenue = createAction(UPDATING_VENUE);
export const updatedVenue = createAction<VenueDetails>(UPDATED_VENUE);

const UPDATING_SPACE = 'zola/vendorStorefront/UPDATING_SPACE';
const UPDATED_SPACE = 'zola/vendorStorefront/UPDATED_SPACE';

export const updatingSpace = createAction(UPDATING_SPACE);
export const updatedSpace = createAction<VenueSpaceView>(UPDATED_SPACE);

const CREATING_SPACE = 'zola/vendorStorefront/CREATING_SPACE';
const CREATED_SPACE = 'zola/vendorStorefront/CREATED_SPACE';

export const creatingSpace = createAction(CREATING_SPACE);
export const createdSpace = createAction<VenueSpaceView>(CREATED_SPACE);

const DELETING_SPACE = 'zola/vendorStorefront/DELETING_SPACE';
const DELETED_SPACE = 'zola/vendorStorefront/DELETED_SPACE';

export const deletingSpace = createAction(DELETING_SPACE);
export const deletedSpace = createAction<VenueSpaceView>(DELETED_SPACE);

const UPDATING_SPACE_OPTION = 'zola/vendorStorefront/UPDATING_SPACE_OPTION';
const UPDATED_SPACE_OPTION = 'zola/vendorStorefront/UPDATED_SPACE_OPTION';

export const updatingSpaceOption = createAction(UPDATING_SPACE_OPTION);
export const updatedSpaceOption =
  createAction<{ uuid: string; options: MappedOptionFacetView[] }>(UPDATED_SPACE_OPTION);

const REORDERING_SPACES = 'zola/vendorStorefront/REORDERING_SPACES';
const REORDERED_SPACES = 'zola/vendorStorefront/REORDERED_SPACES';

export const reorderingSpaces = createAction<string[]>(REORDERING_SPACES);
export const reorderedSpaces = createAction<VenueSpaceView[]>(REORDERED_SPACES);

const UPDATING_SHOWCASE = 'zola/vendorStorefront/UPDATING_SHOWCASE';
const UPDATED_SHOWCASE = 'zola/vendorStorefront/UPDATED_SHOWCASE';
const CREATING_SHOWCASE = 'zola/vendorStorefront/CREATING_SHOWCASE';
const CREATED_SHOWCASE = 'zola/vendorStorefront/CREATED_SHOWCASE';
const DELETING_SHOWCASE = 'zola/vendorStorefront/DELETING_SHOWCASE';
const DELETED_SHOWCASE = 'zola/vendorStorefront/DELETED_SHOWCASE';

const UPDATING_SHOWCASE_OPTIONS = 'zola/vendorStorefront/UPDATING_SHOWCASE_OPTION';
const UPDATED_SHOWCASE_OPTIONS = 'zola/vendorStorefront/UPDATED_SHOWCASE_OPTION';

const REORDERING_SHOWCASES = 'zola/vendorStorefront/REORDERING_SHOWCASES';
const REORDERED_SHOWCASES = 'zola/vendorStorefront/REORDERED_SHOWCASES';

export const UPDATING_MENU = 'zola/vendorStorefront/UPDATING_MENU';
export const UPDATED_MENU = 'zola/vendorStorefront/UPDATED_MENU';

export const CREATING_MENU = 'zola/vendorStorefront/CREATING_MENU';
export const CREATED_MENU = 'zola/vendorStorefront/CREATED_MENU';

export const DELETING_MENU = 'zola/vendorStorefront/DELETING_MENU';
export const DELETED_MENU = 'zola/vendorStorefront/DELETED_MENU';

export const CREATING_REVIEW_REQUEST = 'zola/vendorStorefront/CREATING_REVIEW_REQUEST';
export const CREATED_REVIEW_REQUEST = 'zola/vendorStorefront/CREATED_REVIEW_REQUEST';

export const RESENDING_REVIEW_REQUEST = 'zola/vendorStorefront/RESENDING_REVIEW_REQUEST';
export const RESENT_REVIEW_REQUEST = 'zola/vendorStorefront/RESENT_REVIEW_REQUEST';

export const RECEIVED_SUBMITTED_REVIEWS = 'zola/vendorStorefront/RECEIVED_SUBMITTED_REVIEWS';

export const UPDATING_FAQ = 'zola/vendorStorefront/UPDATING_FAQ';
export const UPDATED_FAQ = 'zola/vendorStorefront/UPDATED_FAQ';

export const CREATING_FAQ = 'zola/vendorStorefront/CREATING_FAQ';
export const CREATED_FAQ = 'zola/vendorStorefront/CREATED_FAQ';

export const DELETING_FAQ = 'zola/vendorStorefront/DELETING_FAQ';
export const DELETED_FAQ = 'zola/vendorStorefront/DELETED_FAQ';

export const SET_ACTIVE_LISTING_MESSAGE = 'zola/vendorStorefront/SET_ACTIVE_LISTING_MESSAGE';

export const UPDATING_STOREFRONT_SOCIAL_LINK =
  'zola/vendorStorefront/UPDATING_STOREFRONT_SOCIAL_LINK';
export const UPDATED_STOREFRONT_SOCIAL_LINK =
  'zola/vendorStorefront/UPDATED_STOREFRONT_SOCIAL_LINK';

export const UPDATING_STOREFRONT_VIDEO_LINK =
  'zola/vendorStorefront/UPDATING_STOREFRONT_VIDEO_LINK';
export const UPDATED_STOREFRONT_VIDEO_LINK = 'zola/vendorStorefront/UPDATED_STOREFRONT_VIDEO_LINK';

export const UPDATING_STOREFRONT_WEDDING_SCANNER_LINK =
  'zola/vendorStorefront/UPDATING_STOREFRONT_WEDDING_SCANNER_LINK';
export const UPDATED_STOREFRONT_WEDDING_SCANNER_LINK =
  'zola/vendorStorefront/UPDATED_STOREFRONT_WEDDING_SCANNER_LINK';

export const UPDATING_OPTIONS = 'zola/vendorStorefront/UPDATING_OPTIONS';
export const UPDATED_OPTIONS = 'zola/vendorStorefront/UPDATED_OPTIONS';

const UPDATING_STOREFRONT_TARGET_BUDGET = 'zola/vendorStorefront/UPDATING_STOREFRONT_TARGET_BUDGET';

export const updatingStorefrontTargetBudget = createAction(UPDATING_STOREFRONT_TARGET_BUDGET);

export const deletingCatererStory = createAction(DELETING_SHOWCASE);
export const deletedCatererStory = createAction<CatererStoryView>(DELETED_SHOWCASE);
export const creatingCatererStory = createAction(CREATING_SHOWCASE);
export const createdCatererStory = createAction<CatererStoryView>(CREATED_SHOWCASE);
export const updatingCatererStory = createAction(UPDATING_SHOWCASE);
export const updatedCatererStory = createAction<CatererStoryView>(UPDATED_SHOWCASE);

export const updatingStoryOptions = createAction(UPDATING_SHOWCASE_OPTIONS);

export const reorderingCatererStories = createAction<
  {
    uuid: string;
    displayOrder: number;
  }[]
>(REORDERING_SHOWCASES);

export const reorderedCatererStories = createAction<{
  catererUuid: string;
  stories: CatererStoryView[];
}>(REORDERED_SHOWCASES);

export const updatedStoryOptions = createAction<{
  storyUuid: string;
  options: EntityFacetView[];
}>(UPDATED_SHOWCASE_OPTIONS);

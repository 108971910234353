import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from '~/reducers';

interface ReduxDevtoolsExtension {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R;
}

/**
 * Get initial state for reducers that require side effects (API calls, local storage, etc.)
 */
const getPreloadedState = () => {
  return {};
};

export const getStore = () => {
  const preloadedState = getPreloadedState();
  const composeEnhancers =
    typeof window !== 'undefined' && getEnvironment() !== EnvironmentTypes.PRODUCTION
      ? (window as ReduxDevtoolsExtension).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;
  const enhancers = composeEnhancers(applyMiddleware(thunk));
  return createStore(reducers, preloadedState, enhancers);
};

import { StarterPackView } from '@zola/svc-marketplace-ts-types';

import { createReducer } from '@reduxjs/toolkit';

import {
  receivedStarterPack,
  updatedStarterPackCard,
} from '~/actions/couples/types/VendorStarterPacksActionTypes';
import type { RootState } from '~/reducers';

export interface VendorStarterPacksState {
  currentStarterPack: StarterPackView | null;
}
export const initialState: VendorStarterPacksState = {
  currentStarterPack: null,
};

const VendorStarterPacksReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(receivedStarterPack, (state, action) => {
      state.currentStarterPack = action.payload;
    })
    .addCase(updatedStarterPackCard, (state, action) => {
      if (state.currentStarterPack) {
        const currentCards = state.currentStarterPack.cards || [];

        const newCards = currentCards.map((card) => {
          if (card.vendorType === action.payload.vendorType) {
            return action.payload;
          }
          return card;
        });

        state.currentStarterPack.cards = newCards;
      }
    })
);

export const getCurrentStarterPack = (state: RootState) =>
  state.couples?.vendorStarterPacks?.currentStarterPack || null;

export default VendorStarterPacksReducer;

import { createReducer } from '@reduxjs/toolkit';

import { StorefrontBooking } from '~/types/types';

import {
  createdStorefrontBookingAction,
  createdStorefrontBookingRangeAction,
  deletedStorefrontBookingAction,
  receivedStorefrontBookingsAction,
} from '../../actions/types/storefrontBookingActionTypes';

export type BookingState = Record<string, StorefrontBooking | undefined>;

export const storefrontBookings = createReducer<BookingState>({}, (builder) =>
  builder
    .addCase(receivedStorefrontBookingsAction, (state, action) => {
      return action.payload.reduce((result, booking) => {
        return {
          ...result,
          [new Date(booking.bookedDate).toDateString()]: booking,
        };
      }, {});
    })
    .addCase(createdStorefrontBookingAction, (state, action) => {
      return {
        ...state,
        [new Date(action.payload.bookedDate).toDateString()]: action.payload,
      };
    })
    .addCase(createdStorefrontBookingRangeAction, (state, action) => {
      return action.payload.reduce((result, booking) => {
        return {
          ...result,
          [new Date(booking.bookedDate).toDateString()]: booking,
        };
      }, state);
    })
    .addCase(deletedStorefrontBookingAction, (state, action) => {
      return {
        ...state,
        [new Date(action.payload.bookedDate).toDateString()]: undefined,
      };
    })
    .addDefaultCase((state, action) => {
      return state;
    })
);

import { StorefrontInquiryStatsView } from '@zola/svc-marketplace-ts-types';

import _isEqual from 'lodash/isEqual';

import { MappedInquiryView } from '~/types/mappedResponseTypes';
import { AccountVendorView } from '~/types/responseTypes';

import {
  RECEIVED_BOOKED_VENDORS,
  REQUESTING_INQUIRY,
  RECEIVED_INQUIRY,
  INQUIRY_NOT_FOUND,
  RECEIVED_STOREFRONT_INQUIRY_STATS,
  VendorInquiryActionTypes,
} from '../actions/vendors/types/vendorInquiriesActionTypes';

export interface InquiryStatsState extends Partial<StorefrontInquiryStatsView> {
  loaded: boolean;
}
export interface VendorInquiriesState {
  // This would probably be better done as a lookup by uuid
  inquiry: MappedInquiryView | null;

  /** Are we currently loading an inquiry */
  inquiryBusy: boolean;

  /** Did we finish loading an inquiry */
  inquiryLoaded: boolean;
  /**
   * What is the current uuid of the inquiry we are dealing with.  This
   * gets set as soon as we ask for a new inquiry and if it does not match
   * what the component thinks we should have, that is an indication to not
   * show the current inquiry
   */
  inquiryUuid: string | null;
  bookedVendors: AccountVendorView[];
  inquiryStats: InquiryStatsState;
}
export const initialState: VendorInquiriesState = {
  inquiry: null,

  inquiryBusy: false,
  inquiryLoaded: false,
  inquiryUuid: null,
  bookedVendors: [],
  inquiryStats: {
    loaded: false,
  },
};

const vendorInquiriesReducer = (
  state: VendorInquiriesState = initialState,
  action: VendorInquiryActionTypes
): VendorInquiriesState => {
  switch (action.type) {
    case RECEIVED_BOOKED_VENDORS: {
      const updatedState = { ...state, bookedVendors: action.payload };
      return _isEqual(state.bookedVendors, updatedState.bookedVendors) ? state : updatedState;
    }
    case REQUESTING_INQUIRY: {
      const { uuid: inquiryUuid } = action.payload;
      return { ...state, inquiryLoaded: false, inquiryBusy: true, inquiry: null, inquiryUuid };
    }
    case RECEIVED_INQUIRY: {
      const inquiry = action.payload;

      return {
        ...state,
        inquiry,
        inquiryUuid: inquiry.uuid,
        inquiryLoaded: true,
        inquiryBusy: false,
      };
    }
    case INQUIRY_NOT_FOUND: {
      const { uuid } = action.payload;
      return {
        ...state,
        inquiryLoaded: true,
        inquiryBusy: false,
        inquiry: null,
        inquiryUuid: uuid,
      };
    }
    case RECEIVED_STOREFRONT_INQUIRY_STATS: {
      return { ...state, inquiryStats: { ...action.payload, loaded: true } };
    }
    default:
      return state;
  }
};

export default vendorInquiriesReducer;

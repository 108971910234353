import {
  SET_LAST_SEARCH_URL,
  WeddingSearchActions,
} from '~/actions/weddings/types/weddingSearchActionTypes';

export interface WeddingSearchState {
  /**
   * The last URL of the search results page.  Used for drawing
   * breadcrumbs when exploring wedding detail pages, but being able to
   * get back to your last search
   */
  lastSearchUrl: string | null;
}

const initialState: WeddingSearchState = {
  lastSearchUrl: null,
};

export const weddingSearchReducer = (
  state: WeddingSearchState = initialState,
  action: WeddingSearchActions
): WeddingSearchState => {
  switch (action.type) {
    case SET_LAST_SEARCH_URL: {
      return {
        ...state,
        lastSearchUrl: action.payload,
      };
    }

    default:
      return state;
  }
};

import { Action, PayloadAction } from '@reduxjs/toolkit';

import { WeddingSearchFacetReturnType } from '~/types/weddings.d';

export const REQUEST_WEDDING_SEARCH_FACETS = 'zola/weddings/v3/REQUEST_WEDDING_SEARCH_FACETS';
export const RECEIVED_WEDDING_SEARCH_FACETS = 'zola/weddings/v3/RECEIVED_WEDDING_SEARCH_FACETS';

export type RequestWeddingSearchFacetsAction = Action<typeof REQUEST_WEDDING_SEARCH_FACETS>;

export type ReceivedWeddingSearchFacetsAction = PayloadAction<
  WeddingSearchFacetReturnType,
  typeof RECEIVED_WEDDING_SEARCH_FACETS
>;

export type WeddingSearchFacetsActions =
  | RequestWeddingSearchFacetsAction
  | ReceivedWeddingSearchFacetsAction;

import { ExpandedWeddingDatesRequest } from '@zola/svc-marketplace-ts-types';

import _range from 'lodash/range';

type Month = {
  value: string;
  title: string;
  label: string;
};

// below are formatted to be used for `RenderSelectField` components;
export const Months: Month[] = [
  { value: '01', title: 'January', label: 'January' },
  { value: '02', title: 'February', label: 'February' },
  { value: '03', title: 'March', label: 'March' },
  { value: '04', title: 'April', label: 'April' },
  { value: '05', title: 'May', label: 'May' },
  { value: '06', title: 'June', label: 'June' },
  { value: '07', title: 'July', label: 'July' },
  { value: '08', title: 'August', label: 'August' },
  { value: '09', title: 'September', label: 'September' },
  { value: '10', title: 'October', label: 'October' },
  { value: '11', title: 'November', label: 'November' },
  { value: '12', title: 'December', label: 'December' },
];

type MonthLookupByNumber = Record<number, Month>;

/**
 * Given an enum of a day, lookup information about that day for display / transformation
 */
export const MonthsByNumber = Months.reduce((result, month): MonthLookupByNumber => {
  return {
    ...result,
    [Number.parseInt(month.value, 10)]: month,
  };
}, {} as MonthLookupByNumber);

type Weekday = {
  value: string;
  title: string;
  label: string;
  enum: ExpandedWeddingDatesRequest.DaysOfWeekEnum;
};
export const Weekdays: Weekday[] = [
  { value: '01', title: 'Monday', label: 'Monday', enum: 'MONDAY' },
  { value: '02', title: 'Tuesday', label: 'Tuesday', enum: 'TUESDAY' },
  { value: '03', title: 'Wednesday', label: 'Wednesday', enum: 'WEDNESDAY' },
  { value: '04', title: 'Thursday', label: 'Thursday', enum: 'THURSDAY' },
  { value: '05', title: 'Friday', label: 'Friday', enum: 'FRIDAY' },
  { value: '06', title: 'Saturday', label: 'Saturday', enum: 'SATURDAY' },
  { value: '07', title: 'Sunday', label: 'Sunday', enum: 'SUNDAY' },
];

type OrderedWeekday = Weekday & { order: number };
type WeekdayLookup = Record<ExpandedWeddingDatesRequest.DaysOfWeekEnum, OrderedWeekday>;

/**
 * Given an enum of a day, lookup information about that day for display / transformation
 */
export const WeekdayMeta = Weekdays.reduce((result, day) => {
  return {
    ...result,
    [day.enum]: {
      ...day,
      order: Number.parseInt(day.value, 10),
    },
  };
}, {} as WeekdayLookup);

export const Dates = _range(1, 32).map((date) => ({
  value: date,
  title: String(date),
  label: String(date),
}));

export const getYearsForAgeRange = (minAge: number, maxAge: number) => {
  const years = [];
  const currentDate = new Date();
  const yearOfMinAge = currentDate.getFullYear() - minAge;
  const yearOfMaxAge = currentDate.getFullYear() - maxAge;

  for (let i = yearOfMinAge; i >= yearOfMaxAge; i -= 1) {
    years.push({
      value: String(i),
      title: String(i),
      label: String(i),
    });
  }

  return years;
};

export const getYears = (numberOfYears: number, initialYear?: number) => {
  const years = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = initialYear || currentYear;
  const endYear = startYear + numberOfYears;

  for (let i = startYear; i <= endYear; i += 1) {
    years.push({
      value: String(i),
      title: String(i),
      label: String(i),
    });
  }

  return years;
};

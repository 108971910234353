import { StorefrontPrivacySettingView, StorefrontReferralView } from '~/types/responseTypes';

export const REQUESTING_STOREFRONT_PRIVACY_SETTINGS =
  'zola/vendorStorefront/REQUESTING_STOREFRONT_PRIVACY_SETTINGS';
export const RECEIVED_STOREFRONT_PRIVACY_SETTINGS =
  'zola/vendorStorefront/RECEIVED_STOREFRONT_PRIVACY_SETTINGS';
export const UPDATING_STOREFRONT_PRIVACY_SETTINGS =
  'zola/vendorStorefront/UPDATING_STOREFRONT_PRIVACY_SETTINGS';
export const UPDATED_STOREFRONT_PRIVACY_SETTINGS =
  'zola/vendorStorefront/UPDATED_STOREFRONT_PRIVACY_SETTINGS';

export const REQUESTING_STOREFRONT_REFERRALS =
  'zola/vendorCommunitySettings/REQUESTING_STOREFRONT_REFERRALS';
export const RECEIVED_STOREFRONT_REFERRALS =
  'zola/vendorCommunitySettings/RECEIVED_STOREFRONT_REFERRALS';

export const REMOVING_STOREFRONT_REFERRALS =
  'zola/vendorCommunitySettings/REMOVING_STOREFRONT_REFERRALS';
export const REMOVED_STOREFRONT_REFERRALS =
  'zola/vendorCommunitySettings/REMOVED_STOREFRONT_REFERRALS';

export const ADDING_STOREFRONT_REFERRALS =
  'zola/vendorCommunitySettings/ADDING_STOREFRONT_REFERRALS';
export const ADDED_STOREFRONT_REFERRALS = 'zola/vendorCommunitySettings/ADDED_STOREFRONT_REFERRALS';

export interface RequestingStorefrontPrivacySettingsAction {
  type: typeof REQUESTING_STOREFRONT_PRIVACY_SETTINGS;
}
interface ReceivedStorefrontPrivacySettingsAction {
  type: typeof RECEIVED_STOREFRONT_PRIVACY_SETTINGS;
  payload: StorefrontPrivacySettingView;
}
interface UpdatingStorefrontPrivacySettingsAction {
  type: typeof UPDATING_STOREFRONT_PRIVACY_SETTINGS;
}
export interface UpdatedStorefrontPrivacySettingsAction {
  type: typeof UPDATED_STOREFRONT_PRIVACY_SETTINGS;
  payload: StorefrontPrivacySettingView;
}

interface RequestingStorefrontReferralsAction {
  type: typeof REQUESTING_STOREFRONT_REFERRALS;
}
export interface ReceivedStorefrontReferralsAction {
  type: typeof RECEIVED_STOREFRONT_REFERRALS;
  payload: StorefrontReferralView[];
}
interface RemovingStorefrontReferralsAction {
  type: typeof REMOVING_STOREFRONT_REFERRALS;
}
export interface RemovedStorefrontReferralsAction {
  type: typeof REMOVED_STOREFRONT_REFERRALS;
  payload: StorefrontReferralView[];
}
interface AddingStorefrontReferralsAction {
  type: typeof ADDING_STOREFRONT_REFERRALS;
}
export interface AddedStorefrontReferralsAction {
  type: typeof ADDED_STOREFRONT_REFERRALS;
  payload: StorefrontReferralView[];
}

export type VendorCommunityActionType =
  | RequestingStorefrontPrivacySettingsAction
  | ReceivedStorefrontPrivacySettingsAction
  | UpdatingStorefrontPrivacySettingsAction
  | UpdatedStorefrontPrivacySettingsAction
  | RequestingStorefrontReferralsAction
  | ReceivedStorefrontReferralsAction
  | RemovingStorefrontReferralsAction
  | RemovedStorefrontReferralsAction
  | AddingStorefrontReferralsAction
  | AddedStorefrontReferralsAction;

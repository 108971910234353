import {
  WeddingFacetsActions,
  REQUEST_WEDDING_FACETS,
  RECEIVED_WEDDING_FACETS,
} from '~/actions/weddings/types/weddingFacetActionTypes';
import { WeddingFacetType } from '~/types/weddings.d';

export interface WeddingFacetState {
  busy: boolean;
  loaded: boolean;
  bySlug: Record<string, WeddingFacetType>;
  byId: Record<number, WeddingFacetType>;
  byKey: Record<string, WeddingFacetType>;
  allSlugs: string[];
  allIds: number[];
  allKeys: string[];
}
const initialState: WeddingFacetState = {
  busy: false,
  loaded: false,
  bySlug: {},
  byId: {},
  byKey: {},
  allSlugs: [],
  allIds: [],
  allKeys: [],
};

export const weddingFacetReducer = (
  state: WeddingFacetState = initialState,
  action: WeddingFacetsActions
): WeddingFacetState => {
  switch (action.type) {
    case REQUEST_WEDDING_FACETS: {
      return { ...state, busy: true };
    }
    case RECEIVED_WEDDING_FACETS: {
      const newState: WeddingFacetState = {
        busy: false,
        loaded: true,
        bySlug: {},
        byId: {},
        byKey: {},
        allSlugs: [],
        allIds: [],
        allKeys: [],
      };
      const facets = action.payload;

      facets.forEach((facet) => {
        const { slug, id, key } = facet;

        newState.bySlug[slug] = facet;
        newState.byId[id] = facet;
        newState.byKey[key] = facet;
        newState.allSlugs.push(slug);
        newState.allIds.push(id);
        newState.allKeys.push(key);
      });
      return newState;
    }

    default:
      return state;
  }
};

import getConfig from './config';

const LOCAL_RESIZE_ASSETS_BASE_URL = '/web-marketplace-api/resize-image-asset';
const RESIZE_ASSETS_BASE_URL = 'https://zola-web-assets.imgix.net/web-home';

export enum ImageFit {
  /**
   * Resizes the image to fill the width and height boundaries and crops any excess image data. The
   * resulting image will match the width and height constraints without distorting the image.
   */
  CROP = 'crop',
  /**
   * Resizes the image to fit within the width and height boundaries without cropping or distorting
   * the image. The resulting image is assured to match one of the constraining dimensions, while
   * the other dimension is altered to maintain the same aspect ratio of the input image.
   */
  CLIP = 'clip',
  /**
   * Resizes the image to fit within the width and height boundaries without cropping or distorting
   * the image, and the remaining space is filled with a solid color. The resulting image will
   * match the constraining dimensions.
   */
  FILL = 'fill',
}

export enum ImageFormat {
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
}

interface ImageOptions {
  /**
   * The width of the output image
   */
  width?: number;
  /**
   * The height of the output image
   */
  height?: number;
  /**
   * Controls how the output image is fit to its target dimensions
   */
  fit?: ImageFit;
  /**
   * The background fill color of the output image, which should be 'transparent' or a hexadecimal value (without hash prefix)
   */
  background?: string;
  /**
   * The output format of the image
   */
  format?: ImageFormat;
  /**
   * The quality of the output image, between 0 and 100
   */
  quality?: number;
}

export const hasWebpImageSupport = () => {
  // We used to look at what the browser supports for this, but ... next ...
  // doesn't know what the browser supports.  So we end up serving a jpg then
  // switching to a webp.  webp has good support (2024) so we now just return true
  return true;
};

export const getOptimalImageFormat = () => {
  return hasWebpImageSupport() ? ImageFormat.WEBP : undefined;
};

export const getQueryString = (options?: ImageOptions, useImgixParams = false): string => {
  const params: string[] = [];

  if (!options) return '';

  if (options.width) {
    params.push(`w=${options.width}`);
  }

  if (options.height) {
    params.push(`h=${options.height}`);
  }

  if (useImgixParams) {
    if (options.fit === ImageFit.CLIP) {
      params.push('fit=max');
    } else {
      params.push(`fit=${options.fit}`);
    }

    if (options.background) {
      params.push('fill=solid');

      if (options.background !== 'transparent') {
        params.push(`fill-color=${options.background}`);
      }
    }

    if (options.format) {
      params.push(`fm=${options.format}`);
    } else {
      params.push('auto=format');
    }
  } else {
    if (options.fit) {
      params.push(`fit=${options.fit}`);
    }

    if (options.background) {
      params.push(`bg=${options.background}`);
    }

    if (options.format) {
      params.push(`fm=${options.format}`);
    }
  }

  if (options.quality) {
    params.push(`q=${options.quality}`);
  }

  return params.length > 0 ? `?${params.join('&')}` : '';
};

export function getImageUrl(uuid: string, options?: ImageOptions): string;
export function getImageUrl<T extends null | undefined>(uuid: T, options?: ImageOptions): null;
export function getImageUrl<T extends string | undefined | null>(
  uuid: T,
  options?: ImageOptions
): string | null;
export function getImageUrl(uuid: string | undefined | null, options?: ImageOptions) {
  if (!uuid) {
    return null;
  }

  return `${getConfig().imageServiceBaseUrl}/${uuid}${getQueryString(options)}`;
}

export const getAssetImageUrl = (src: string, options?: ImageOptions): string => {
  let baseUrl;

  if (src.includes('cloudfront')) {
    baseUrl = src.replace(/^.+\.cloudfront\.net\/(v[a-z]assets)/, `${RESIZE_ASSETS_BASE_URL}/$1`);
  } else {
    baseUrl = src.replace(/\/static-.+\/media/, LOCAL_RESIZE_ASSETS_BASE_URL);
  }

  return `${baseUrl}${getQueryString(options, true)}`;
};

/**
 * Get the image UUID from an image server URL
 */
export const getImageUuid = (url?: string | null): string | null => {
  if (!url) {
    return null;
  }

  const imageUrl = new URL(url);

  if (
    imageUrl.host !== 'images.zola.com' &&
    imageUrl.host !== 'stage-images.zola.com' &&
    imageUrl.host !== 'localhost:9100'
  ) {
    return null;
  }

  return imageUrl.pathname.split('/').pop() || null;
};

export default getImageUrl;

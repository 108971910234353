import { Component } from 'react';

import { connect } from 'react-redux';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import { AppDispatch, RootState } from '~/reducers';

import { hide } from '../../../actions/notificationActions';
import { MarketplaceNotification } from './constants';
import Notification from './Notification';

interface NotificationsProps {
  notifications: MarketplaceNotification[];
  onRemoveNotification: (id: number) => void;
}

export class Notifications extends Component<NotificationsProps> {
  constructor(props: NotificationsProps) {
    super(props);

    this.remove = this.remove.bind(this);
  }

  remove(id: number, timeoutMs: number) {
    const { onRemoveNotification } = this.props;
    setTimeout(() => onRemoveNotification(id), timeoutMs);
  }

  render() {
    const { notifications } = this.props;

    return (
      <TransitionGroup className="notifications-container">
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} onRemove={this.remove} />
        ))}
      </TransitionGroup>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onRemoveNotification: (id: number) => dispatch(hide(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

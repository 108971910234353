import { createAction } from '@reduxjs/toolkit';

import type { CouplesStorefrontDetails } from '~/types/storefrontDetails';

const REQUEST_STOREFRONT = 'zola/storefront/REQUEST_STOREFRONT';
const RECEIVE_STOREFRONT = 'zola/storefront/RECEIVE_STOREFRONT';
const MISSING_STOREFRONT = 'zola/storefront/MISSING_STOREFRONT';
const WORK_IN_PROGRESS_STOREFRONT = 'zola/storefront/WORK_IN_PROGRESS_STOREFRONT';

export const requestStorefront = createAction(REQUEST_STOREFRONT);
export const receiveStorefront = createAction<CouplesStorefrontDetails>(RECEIVE_STOREFRONT);
export const missingStorefront = createAction(MISSING_STOREFRONT);
export const workInProgressStorefront = createAction(WORK_IN_PROGRESS_STOREFRONT);

import { AnyAction } from '@reduxjs/toolkit';

import * as ActionTypes from '../actions/vendors/types/vendorStorefrontPublicationActionTypes';

export type VendorStorefrontPublicationState = {
  publishClickedByUuid: Record<string, boolean>;
};

const initialState: VendorStorefrontPublicationState = {
  /* Has the vendor attempted to publish the storefront.  StorefrontUuid => bool */
  publishClickedByUuid: {},
};

const vendorStorefrontPublicationReducer = (
  state = initialState,
  action: AnyAction
): VendorStorefrontPublicationState => {
  if (ActionTypes.setPublishedClicked.match(action)) {
    const { storefrontUuid, clicked } = action.payload;
    return {
      ...state,
      publishClickedByUuid: { ...state.publishClickedByUuid, [storefrontUuid]: clicked ?? true },
    };
  }

  return state;
};

export default vendorStorefrontPublicationReducer;

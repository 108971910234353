import { StorefrontFirstMoveMessageSuggestionView } from '@zola/svc-marketplace-ts-types';

export const REQUESTING_MESSAGE_TEMPLATE_FOR_STOREFRONT =
  'zola/vendorStorefront/REQUESTING_MESSAGE_TEMPLATE_FOR_STOREFRONT';
export const RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT =
  'zola/vendorStorefront/RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT';
export const RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT_ERROR =
  'zola/vendorStorefront/RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT_ERROR';

export const REQUESTING_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE =
  'zola/vendorStorefront/REQUESTING_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE';
export const RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE =
  'zola/vendorStorefront/RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE';
export const RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE_ERROR =
  'zola/vendorStorefront/RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE_ERROR';

export interface RequestingMessageTemplateForStorefrontAction {
  type: typeof REQUESTING_MESSAGE_TEMPLATE_FOR_STOREFRONT;
}

export interface ReceivedMessageTemplateForStorefrontAction {
  type: typeof RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT;
  payload: StorefrontFirstMoveMessageSuggestionView;
}

export interface ReceivedMessageTemplateForStorefrontErrorAction {
  type: typeof RECEIVED_MESSAGE_TEMPLATE_FOR_STOREFRONT_ERROR;
}

export interface RequestingMessageTemplateForVendorTypeAction {
  type: typeof REQUESTING_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE;
}

export interface ReceivedMessageTemplateForVendorTypeAction {
  type: typeof RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE;
  payload: StorefrontFirstMoveMessageSuggestionView;
}
export interface ReceivedMessageTemplateForVendorTypeErrorAction {
  type: typeof RECEIVED_MESSAGE_TEMPLATE_FOR_VENDOR_TYPE_ERROR;
}

export type AutoSendHellosActionTypes =
  | ReceivedMessageTemplateForStorefrontAction
  | RequestingMessageTemplateForStorefrontAction
  | RequestingMessageTemplateForVendorTypeAction
  | ReceivedMessageTemplateForVendorTypeAction
  | ReceivedMessageTemplateForStorefrontErrorAction
  | ReceivedMessageTemplateForVendorTypeErrorAction;
